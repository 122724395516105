import { SecondaryButtonComponent } from './SecondaryButtonComponent';
import { IconType } from 'react-icons/lib';
import styled from 'styled-components';
import { PRIMARY_COLOR } from '../../constants/colors.constants';
import { MouseEventHandler } from 'react';
import { FaPencilAlt, FaFileExcel, FaTimes  } from 'react-icons/fa';


interface SecondaryButtonWithIconProps {
    icon: IconType;
    text: string;
    onClick?: MouseEventHandler<HTMLButtonElement>;
    borderRadius?: number;
    color?: string;
    bold?: number;
    margin?: boolean;
    height?: string;
    padding?: string;
    fa?: string;
}

const ContentButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const TextButton = styled.span<{ textColor?: string }>`
    color: ${(props) => props.color || PRIMARY_COLOR};
    margin-right: 11px;
`;

export const SecondaryButtonWithIconComponent = ({
    icon,
    text,
    onClick,
    borderRadius = 12,
    color = PRIMARY_COLOR,
    bold = 800,
    margin = false,
    height = '40px',
    padding = '10px 20px',
    fa = ''
}: SecondaryButtonWithIconProps) => {    
    const iconMap: { [key: string]: React.ElementType } = {
        FaPencilAlt: FaPencilAlt,
        FaFileExcel: FaFileExcel ,
        FaTimes : FaTimes 
    };
    const IconComponent = icon ? iconMap[icon.name] : undefined;
    return (
        <div style={{ marginLeft: margin ? 10 : 0, marginTop: margin ? 10 : 0 }}>
            <SecondaryButtonComponent 
                onClick={onClick}
                borderRadius={borderRadius}
                color={color}
                bold={bold}
                height={height}
                padding={padding}
                fa={fa}
            >
                <ContentButtonContainer>
                    <TextButton color={color}>{text}</TextButton>
                    {IconComponent && <IconComponent color={color} />}
                </ContentButtonContainer>
            </SecondaryButtonComponent>
        </div>
    );
};
