import { API_URL } from "../constants/config.constants";
import { PrefixType } from "../model/PrefixType";
import { fetchWithAuth } from "../pages/Auth/AuthUtils";

export const fetchFileForUpload = async (fileType: string | undefined, accessToken: String | null, prefix: PrefixType) => {
    const fileExtension = fileType?.split('/')[1]
    const response = await fetchWithAuth(`${API_URL}/utils/upload-file/?file_extension=${fileExtension}`, {
        method: 'GET',
        headers: {
            'Content-Type': `${prefix}/${fileType}`,
            'Authorization': `Bearer ${accessToken}`
        }
    });

    if (!response.ok) {
        throw new Error(`Error fetching upload URL: ${response.statusText}`);
    }

    const data = await response.json();
    return data;
}

export const uploadFileToS3 = async (uploadUrl: string, file: File, fileType: string, prefix: PrefixType) => {
    try {
        const response = await fetchWithAuth(uploadUrl, {
            method: 'PUT',
            body: file,
            headers: {
                'Content-Type': `${prefix}/${fileType}`
            }
        });

        if (!response.ok) {
            throw new Error(`Error uploading file: ${response.statusText}`);
        }

        return await response.text();
    } catch (error) {
        throw new Error(`Error uploading file: ${error}`);
    }
};
