import React, { useEffect, useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { useSpring, animated } from 'react-spring';
import { BatchOrderItem } from './BatchOrderItem';
import { fetchLocalityName, fetchProvinceName } from './BatchUtils';

const ItemType = 'BATCH_ITEM';

const DraggableBatchOrderItem = ({ item, auction, index, moveItem, onEdit, onDelete }: any) => {
    const [provincia, setProvincia] = useState("");
    const [localidad, setLocalidad] = useState("");

    const ref = useRef(null);
    const [, drop] = useDrop({
        accept: ItemType,
        hover(draggedItem: any) {
            if (draggedItem.index !== index) {
                moveItem(draggedItem.index, index);
                draggedItem.index = index;
            }
        }
    });

    const [{ isDragging }, drag] = useDrag({
        type: ItemType,
        item: { index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging()
        })
    });

    drag(drop(ref));

    const springStyle = useSpring({
        opacity: isDragging ? 0.5 : 1,
        transform: isDragging ? 'scale(1.02)' : 'scale(1)',
        boxShadow: isDragging ? '0px 4px 10px rgba(0, 0, 0, 0.2)' : '0px 1px 3px rgba(0, 0, 0, 0.1)'
    });

    const getProvinceName = async () => {
        const data = await fetchProvinceName(auction.provincia);
        setProvincia(data.nombre)
    }

    const getLocalityName = async () => {
        const data = await fetchLocalityName(auction.localidad);
        setLocalidad(data.nombre)
    }

    useEffect( () => {
        getProvinceName();
        getLocalityName();
    }, [auction])

    return (
        <animated.div ref={ref} style={springStyle}>
            <BatchOrderItem content={item} provincia={provincia} localidad={localidad} onEdit={onEdit} onDelete={onDelete} />
        </animated.div>
    );
};

export default DraggableBatchOrderItem;
