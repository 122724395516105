import styled from "styled-components";
import { BLACK_FONT, GRAY_COLOR, PLACEHOLDER_FONT_COLOR, RED_COLOR } from "../../../constants/colors.constants";
import { BorderInputComponent } from "../../../components/form/inputs/BorderInputComponent";
import FileSelectorButtonComponent from "../../../components/buttons/FileSelectorButtonComponent";
import { PrimaryButtonComponent } from "../../../components/buttons/PrimaryButtonComponent";
import { faenaCondicion, faenaDestination, faenaDetention, faenaRaza, faenaSubcategory, faenaTermination, faenaWeighing } from "../../../constants/dropdowns/batchs/batchDataDropdown";
import MultiSelectComponent from "../../../components/form/dropdowns/MultiSelectComponent";
import { PrimaryButtonWithIconComponent } from "../../../components/buttons/PrimaryButtonWithIconComponent";
import { FaWhatsapp } from "react-icons/fa";
import { useEffect, useState } from "react";
import { API_URL } from "../../../constants/config.constants";
import { formatUrl, uploadImage, uploadVideo } from "./BatchFormUtils";
import { isMobile } from "../../../utils/utils";
import { fetchWithAuth } from "../../Auth/AuthUtils";

const CenteredFormContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 30px;
`;

const ConditionContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 583px;
    margin-bottom: 15px;
`;

const TitleCondition = styled.p`
    font-weight: bold;
    color: ${BLACK_FONT};
    font-size: 16px;
    text-align: left;
`;

const RadioInputsContainer = styled.div`
    display: flex;
    flex-direction: row;
`;

const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 600px;
  justify-content: space-between;
  align-items: center;
`;

const FirstRowFooter = styled.div`
    display: flex;
    flex-direction: row;
    width: 600px;
    align-items: center;
`;

const SecondRowFooter = styled.div`
    display: flex;
    flex-direction: row;
    width: 600px;
    align-items: center;
    @media (max-width: 769px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
`;

export const FaenaBatchForm = ({ formData, auctionId }: any) => {
  const [faenaFormData, setFaenaFormData] = useState(formData);
  const [selectedImage, setSelectedImage] = useState<any>();
  const [selectedVideo, setSelectedVideo] = useState<any>();
  const accessToken = localStorage.getItem("accessToken");
  const [showPhoneField, setShowPhoneField] = useState(false);



  useEffect(() => {
    setFaenaFormData((prevState: any) => ({
      ...prevState,
      remate: auctionId
    }));
  }, [auctionId]);

  const saveFaenaBatch = async () => {
    try {

      const videoUrl = await uploadVideo(selectedVideo, accessToken);
      const imageUrl = await uploadImage(selectedImage, accessToken);

      const updatedFormData = {
        ...faenaFormData,
        remate: auctionId,
        foto: formatUrl(imageUrl),
        video: formatUrl(videoUrl)
      };

      setFaenaFormData(updatedFormData);

      console.log(faenaFormData)

      const response = await fetchWithAuth(`${API_URL}/lotes/faena/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify(faenaFormData)
      });

      if (!response.ok) {
        throw new Error('Network response was not ok ' + response.statusText);
      }
      const data = await response.json();
      window.location.reload();
    } catch (error) {
      console.error('Failed to fetch:', error);
    }
  }

  const handleImageSelect = (file: any) => {
    setSelectedImage(file);
  };

  const handleVideoSelect = (file: any) => {
    setSelectedVideo(file);
  };

  return (
    <CenteredFormContainer>
      <InputContainer>
        <MultiSelectComponent
          width={isMobile() ? 250 : 583}
          color={PLACEHOLDER_FONT_COLOR}
          placeholder={"Sub-Categoría"}
          options={faenaSubcategory}
          onChange={(e) => setFaenaFormData((prevState: any) => ({
            ...prevState,
            subcategoria: e
          }))}
          marginBottom={5}
        />
      </InputContainer>
      <InputContainer>
        <MultiSelectComponent
          width={isMobile() ? 250 : 583}
          color={PLACEHOLDER_FONT_COLOR}
          placeholder={"Dentición"}
          options={faenaDetention}
          onChange={(e) => setFaenaFormData((prevState: any) => ({
            ...prevState,
            denticion: e
          }))}
          marginBottom={5}
        />
      </InputContainer>
      <InputContainer>
        <MultiSelectComponent
          width={isMobile() ? 250 : 583}
          color={PLACEHOLDER_FONT_COLOR}
          placeholder={"Raza"}
          options={faenaRaza}
          onChange={(e) => setFaenaFormData((prevState: any) => ({
            ...prevState,
            raza: e
          }))}
          marginBottom={5}
        />
      </InputContainer>
      <BorderInputComponent
        type="number"
        value={faenaFormData.cantidad}
        onChange={(e) => setFaenaFormData((prevState: any) => ({
          ...prevState,
          cantidad: e.target.value
        }))}
        placeholder="Cantidad"
        width={isMobile() ? 217 : 583}
      />
      <BorderInputComponent
        type="number"
        value={faenaFormData.cantidad_masculino}
        onChange={(e) => setFaenaFormData((prevState: any) => ({
          ...prevState,
          cantidad_masculino: e.target.value
        }))}
        placeholder="Cant. Machos"
        width={isMobile() ? 217 : 583}
      />
      <BorderInputComponent
        type="number"
        value={faenaFormData.cantidad_hembra}
        onChange={(e) => setFaenaFormData((prevState: any) => ({
          ...prevState,
          cantidad_hembra: e.target.value
        }))}
        placeholder="Cant. Hembras"
        width={isMobile() ? 217 : 583}
      />
      <BorderInputComponent
        type="number"
        value={faenaFormData.peso_promedio}
        onChange={(e) => setFaenaFormData((prevState: any) => ({
          ...prevState,
          peso_promedio: e.target.value
        }))}
        placeholder="Peso Prom."
        width={isMobile() ? 217 : 583}
      />
      <BorderInputComponent
        type="number"
        value={faenaFormData.peso_minimo}
        onChange={(e) => setFaenaFormData((prevState: any) => ({
          ...prevState,
          peso_minimo: e.target.value
        }))}
        placeholder="Peso. Mínimo"
        width={isMobile() ? 217 : 583}
      />
      <BorderInputComponent
        type="number"
        value={faenaFormData.peso_maximo}
        onChange={(e) => setFaenaFormData((prevState: any) => ({
          ...prevState,
          peso_maximo: e.target.value
        }))}
        placeholder="Peso. Max"
        width={isMobile() ? 217 : 583}
      />
      <InputContainer>
        <MultiSelectComponent
          width={isMobile() ? 250 : 583}
          color={PLACEHOLDER_FONT_COLOR}
          placeholder={"Terminación"}
          options={faenaTermination}
          onChange={(e) => setFaenaFormData((prevState: any) => ({
            ...prevState,
            terminacion: e
          }))}
          marginBottom={5}
        />
      </InputContainer>
      <InputContainer>
        <MultiSelectComponent
          width={isMobile() ? 250 : 583}
          color={PLACEHOLDER_FONT_COLOR}
          placeholder={"Destino"}
          options={faenaDestination}
          onChange={(e) => setFaenaFormData((prevState: any) => ({
            ...prevState,
            destino: e
          }))}
          marginBottom={5}
        />
      </InputContainer>
      <InputContainer>
        <MultiSelectComponent
          width={isMobile() ? 250 : 583}
          color={PLACEHOLDER_FONT_COLOR}
          placeholder={"Condición"}
          options={faenaCondicion}
          onChange={(e) => setFaenaFormData((prevState: any) => ({
            ...prevState,
            condicion: e
          }))}
          marginBottom={5}
        />
      </InputContainer>
      <BorderInputComponent
        value={faenaFormData.desbaste}
        onChange={(e) => setFaenaFormData((prevState: any) => ({
          ...prevState,
          desbaste: e.target.value
        }))}
        placeholder="Desbaste"
        width={isMobile() ? 217 : 583}
      />
      <MultiSelectComponent
        width={isMobile() ? 250 : 583}
        color={PLACEHOLDER_FONT_COLOR}
        placeholder={"Pesaje"}
        options={faenaWeighing}
        onChange={(e) => setFaenaFormData((prevState: any) => ({
          ...prevState,
          pesaje: e
        }))}
        marginBottom={5}
      />
      <BorderInputComponent
        value={faenaFormData.rend_estimado}
        onChange={(e) => setFaenaFormData((prevState: any) => ({
          ...prevState,
          rend_estimado: e.target.value
        }))}
        placeholder="Rend. estimado"
        width={isMobile() ? 217 : 583}
      />

      <FooterContainer>
        <FirstRowFooter>
          <BorderInputComponent
            value={faenaFormData.observaciones}
            onChange={(e) => setFaenaFormData((prevState: any) => ({
              ...prevState,
              observaciones: e.target.value
            }))}
            placeholder="Observaciones"
            width={isMobile() ? 100 : 352}
            marginBottom={10}
          />
          <div style={{ marginLeft: 40 }}></div>
          {
                    !showPhoneField
                        ? <PrimaryButtonWithIconComponent
                            onClick={() => setShowPhoneField(true)}
                            backgroundColor={GRAY_COLOR} width={isMobile() ? '43%' : undefined} iconColor={PLACEHOLDER_FONT_COLOR} textColor={PLACEHOLDER_FONT_COLOR} icon={FaWhatsapp} text={"Agregar Celular"}  />
                        :<BorderInputComponent
                            value={faenaFormData.celular}
                            onChange={(e) => setFaenaFormData((prevState: any) => ({
                                ...prevState,
                                celular: e.target.value
                            }))}
                            placeholder="Celular"
                            width={isMobile() ? 220 : 170}
                            marginBottom={10}
                            type="number"
                        />
                }
        </FirstRowFooter>
        <SecondRowFooter>
          <BorderInputComponent
            value={faenaFormData.plazo}
            onChange={(e) => setFaenaFormData((prevState: any) => ({
              ...prevState,
              plazo: e.target.value
            }))}
            placeholder="Plazo"
            width={210}
            marginBottom={10}
          />
          <div style={isMobile() ? { marginLeft: 0, marginRight: 50, marginTop: 15 }: { marginLeft: 15, marginRight: 10 }}>
          <FileSelectorButtonComponent label={'Cargar Video'} width={isMobile() ? 200 : 100}onFileSelect={handleVideoSelect} inputId="fileInputVideo" />
        </div>
        <div style={isMobile() ? { marginLeft: 0, marginRight: 50, marginTop: 15, paddingBottom: 15 } : {marginLeft: 45}}>
            <FileSelectorButtonComponent label={'Cargar Foto'} width={isMobile() ? 200 : 100} onFileSelect={handleImageSelect} inputId="fileInputImage" />
          </div>
        </SecondRowFooter>
        <PrimaryButtonComponent onClick={() => saveFaenaBatch()} height="48px" width={isMobile() ? '50%' : undefined}>Guardar</PrimaryButtonComponent>
      </FooterContainer>

    </CenteredFormContainer>
  )
}