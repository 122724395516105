import styled from "styled-components";
import { BLACK_FONT } from "../../constants/colors.constants";

interface HeaderProps {
    title: string;
    subtitle: string;
}

const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const StyledTitle = styled.h2`
    color: ${BLACK_FONT};
    margin: unset;
    font-size: 42px;

    @media (max-width: 768px) {
        font-size: 20px;
    }
`;

const StyledSubtitle = styled.span`
    color: ${BLACK_FONT};
    font-weight: 500;
    font-size: 20px;

    @media (max-width: 768px) {
        margin-top: 10px;
        font-size: 14px;
    }
`;

export const HeaderComponent = ({
    title,
    subtitle
}: HeaderProps) => {
  return (
    <StyledContainer>
        <StyledTitle>{title}</StyledTitle>
        <StyledSubtitle>{subtitle}</StyledSubtitle>
    </StyledContainer>
  )
}
