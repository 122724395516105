import styled from "styled-components";
import BorderDropdownComponent from "../../../components/form/dropdowns/BorderDropdownComponent";
import { GRAY_COLOR, PLACEHOLDER_FONT_COLOR } from "../../../constants/colors.constants";
import { BorderInputComponent } from "../../../components/form/inputs/BorderInputComponent";
import FileSelectorButtonComponent from "../../../components/buttons/FileSelectorButtonComponent";
import { PrimaryButtonComponent } from "../../../components/buttons/PrimaryButtonComponent";
import { clase, invernadaDescBatch, invernadaDestination, invernadaDetention, invernadaEstRepr, invernadaGarrapata, invernadaMioMio, invernadaPastoreo, invernadaRaza, invernadaSanity, invernadaState, invernadaTratNutric, invernadaWeighing } from "../../../constants/dropdowns/batchs/batchDataDropdown";
import MultiSelectComponent from "../../../components/form/dropdowns/MultiSelectComponent";
import { FaWhatsapp } from "react-icons/fa";
import { PrimaryButtonWithIconComponent } from "../../../components/buttons/PrimaryButtonWithIconComponent";
import { useEffect, useState } from "react";
import { API_URL } from "../../../constants/config.constants";
import { formatUrl, uploadImage, uploadVideo } from "./BatchFormUtils";
import { isMobile } from "../../../utils/utils";
import { fetchWithAuth } from "../../Auth/AuthUtils";

const CenteredFormContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 30px;
`;

const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 600px;
  justify-content: space-between;
  align-items: center;
`;

const FirstRowFooter = styled.div`
    display: flex;
    flex-direction: row;
    width: 600px;
    align-items: center;
    @media (max-width: 769px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
`;

const SecondRowFooter = styled.div`
    display: flex;
    flex-direction: row;
    width: 600px;
    align-items: center;
    @media (max-width: 769px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
`;

export const InvernadaBatchForm = ({formData, auctionId}: any) => {
    const [invernadaFormData, setInvernadaFormData] = useState(formData);
    const [selectedImage, setSelectedImage] = useState<any>();
    const [selectedVideo, setSelectedVideo] = useState<any>();
    const accessToken = localStorage.getItem("accessToken");

    useEffect(() => {
        setInvernadaFormData((prevState: any) => ({
          ...prevState,
          remate: auctionId
        }));
      }, [auctionId]);
      
    const saveInvernadaBatch = async () => {
      try {

        const videoUrl = await uploadVideo(selectedVideo, accessToken);
        const imageUrl = await uploadImage(selectedImage, accessToken);
          const updatedFormData = {
            ...invernadaFormData,
            remate: auctionId,
            foto: formatUrl(imageUrl),
            video: formatUrl(videoUrl)
          };
    
          setInvernadaFormData(updatedFormData);

        const response = await fetchWithAuth(`${API_URL}/lotes/invernada/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            },

            body: JSON.stringify(invernadaFormData)
        });

        if (!response.ok) {
            throw new Error('Network response was not ok ' + response.statusText);
        }
        const data = await response.json();
        window.location.reload();
    } catch (error) {
        console.error('Failed to fetch:', error);
    }
    }

    const handleImageSelect = (file: any) => {
        setSelectedImage(file);
      };
    
      const handleVideoSelect = (file: any) => {
        setSelectedVideo(file);
      };
    
    const [showPhoneField, setShowPhoneField] = useState(false);

  return (
    <CenteredFormContainer>
        <InputContainer>
            <MultiSelectComponent
                width={isMobile() ? 250 : 610}
                color={PLACEHOLDER_FONT_COLOR}
                placeholder={"Raza"}
                options={invernadaRaza}
                onChange={(e) => setInvernadaFormData((prevState: any) => ({
                    ...prevState,
                    raza: e
                  }))}
                marginBottom={5}
            />
        </InputContainer>
        <BorderInputComponent
            type="number"
            value={invernadaFormData.cantidad}
            onChange={(e) => setInvernadaFormData((prevState: any) => ({
                ...prevState,
                cantidad: e.target.value
              }))}
            placeholder="Cantidad"
            width={isMobile() ? 250 : 583}
        />
        <BorderInputComponent
            type="number"
            value={invernadaFormData.cantidad_masculino}
            onChange={(e) => setInvernadaFormData((prevState: any) => ({
                ...prevState,
                cantidad_masculino: e.target.value
              }))}
            placeholder="Cant. Machos"
            width={isMobile() ? 250 : 583}
        />
        <BorderInputComponent
            type="number"
            value={invernadaFormData.cantidad_hembra}
            onChange={(e) => setInvernadaFormData((prevState: any) => ({
                ...prevState,
                cantidad_hembra: e.target.value
              }))}
            placeholder="Cant. Hembras"
            width={isMobile() ? 250 : 583}
        />

        <BorderInputComponent
            type="number"
            value={invernadaFormData.peso_promedio}
            onChange={(e) => setInvernadaFormData((prevState: any) => ({
                ...prevState,
                peso_promedio: e.target.value
              }))}
            placeholder="Peso Prom."
            width={isMobile() ? 250 : 583}
        />

        <BorderInputComponent
            type="number"
            value={invernadaFormData.peso_minimo}
            onChange={(e) => setInvernadaFormData((prevState: any) => ({
                ...prevState,
                peso_minimo: e.target.value
              }))}
            placeholder="Pes. Mínimo"
            width={isMobile() ? 250 : 583}
        />
        <BorderInputComponent
            type="number"
            value={invernadaFormData.peso_maximo}
            onChange={(e) => setInvernadaFormData((prevState: any) => ({
                ...prevState,
                peso_maximo: e.target.value
              }))}
            placeholder="Pes. Max"
            width={isMobile() ? 250 : 583}
        />
        <BorderInputComponent
            type="number"
            value={invernadaFormData.porcentaje_pesado}
            onChange={(e) => setInvernadaFormData((prevState: any) => ({
                ...prevState,
                porcentaje_pesado: e.target.value
              }))}
            placeholder="% Pesada"
            width={isMobile() ? 250 : 583}
        />
        <InputContainer>
            <MultiSelectComponent
                width={isMobile() ? 250 : 610}
                color={PLACEHOLDER_FONT_COLOR}
                placeholder={"Desc. Lote"}
                options={invernadaDescBatch}
                onChange={(e) => setInvernadaFormData((prevState: any) => ({
                    ...prevState,
                    descripcion_lote: e
                  }))}
                marginBottom={5}
            />
        </InputContainer>
        <BorderInputComponent
            type="number"
            value={invernadaFormData.edad}
            onChange={(e) => setInvernadaFormData((prevState: any) => ({
                ...prevState,
                edad: e.target.value
              }))}
            placeholder="Edad"
            width={isMobile() ? 250 : 583}
        />
        <InputContainer>
            <MultiSelectComponent
                width={isMobile() ? 250 : 610}
                color={PLACEHOLDER_FONT_COLOR}
                placeholder={"Dentición"}
                options={invernadaDetention}
                onChange={(e) => setInvernadaFormData((prevState: any) => ({
                    ...prevState,
                    denticion: e
                  }))}
                marginBottom={5}
            />
        </InputContainer>
        <InputContainer>
            <MultiSelectComponent
                width={isMobile() ? 250 : 610}
                color={PLACEHOLDER_FONT_COLOR}
                placeholder={"Est. Repr."}
                options={invernadaEstRepr}
                onChange={(e) => setInvernadaFormData((prevState: any) => ({
                    ...prevState,
                    estado_reproductivo: e
                  }))}
                marginBottom={5}
            />
        </InputContainer>
        <InputContainer>
            <MultiSelectComponent
                width={isMobile() ? 250 : 610}
                color={PLACEHOLDER_FONT_COLOR}
                placeholder={"Estado"}
                options={invernadaState}
                onChange={(e) => setInvernadaFormData((prevState: any) => ({
                    ...prevState,
                    estado: e
                  }))}
                marginBottom={5}
            />
        </InputContainer>
        <InputContainer>
            <MultiSelectComponent
                width={isMobile() ? 250 : 610}
                color={PLACEHOLDER_FONT_COLOR}
                placeholder={"Clase"}
                options={clase}
                onChange={(e) => setInvernadaFormData((prevState: any) => ({
                    ...prevState,
                    clase: e
                  }))}
                marginBottom={5}
            />
        </InputContainer>
        <InputContainer>
            <BorderDropdownComponent
                width={isMobile() ? 250 : 610}
                color={PLACEHOLDER_FONT_COLOR}
                placeholder={"Mio Mio"}
                options={invernadaMioMio}
                onChange={(e) => setInvernadaFormData((prevState: any) => ({
                    ...prevState,
                    miomio: e
                  }))}
                marginBottom={5}
            />
        </InputContainer>
        <InputContainer>
            <MultiSelectComponent
                width={isMobile() ? 250 : 610}
                color={PLACEHOLDER_FONT_COLOR}
                placeholder={"Garrapata"}
                options={invernadaGarrapata}
                onChange={(e) => setInvernadaFormData((prevState: any) => ({
                    ...prevState,
                    garrapata: e
                  }))}
                marginBottom={5}
            />
        </InputContainer>
        <InputContainer>
            <MultiSelectComponent
                width={isMobile() ? 250 : 610}
                color={PLACEHOLDER_FONT_COLOR}
                placeholder={"Sanidad"}
                options={invernadaSanity}
                onChange={(e) => setInvernadaFormData((prevState: any) => ({
                    ...prevState,
                    sanidad: e
                  }))}
                marginBottom={5}
            />
        </InputContainer>
        <InputContainer>
            <MultiSelectComponent
                width={isMobile() ? 250 : 610}
                color={PLACEHOLDER_FONT_COLOR}
                placeholder={"Trat. Nutric."}
                options={invernadaTratNutric}
                onChange={(e) => setInvernadaFormData((prevState: any) => ({
                    ...prevState,
                    tratamiento_nutricional: e
                  }))}
                marginBottom={5}
            />
        </InputContainer>
        <InputContainer>
            <MultiSelectComponent
                width={isMobile() ? 250 : 610}
                color={PLACEHOLDER_FONT_COLOR}
                placeholder={"Pesaje"}
                options={invernadaWeighing}
                onChange={(e) => setInvernadaFormData((prevState: any) => ({
                    ...prevState,
                    pesaje: e
                  }))}
                marginBottom={5}
            />
        </InputContainer>
        <BorderInputComponent
            type="number"
            value={invernadaFormData.desbaste}
            onChange={(e) => setInvernadaFormData((prevState: any) => ({
                ...prevState,
                desbaste: e.target.value
              }))}
            placeholder="Desbaste"
            width={isMobile() ? 250 : 583}
        />
        <InputContainer>
            <MultiSelectComponent
                width={isMobile() ? 250 : 610}
                color={PLACEHOLDER_FONT_COLOR}
                placeholder={"Destino"}
                options={invernadaDestination}
                onChange={(e) => setInvernadaFormData((prevState: any) => ({
                    ...prevState,
                    destino: e
                  }))}
                marginBottom={5}
            />
        </InputContainer>
        <InputContainer>
            <BorderDropdownComponent
                width={isMobile() ? 250 : 610}
                color={PLACEHOLDER_FONT_COLOR}
                placeholder={"Pastoreo"}
                options={invernadaPastoreo}
                onChange={(e) => setInvernadaFormData((prevState: any) => ({
                    ...prevState,
                    pastoreo: e
                  }))}
                marginBottom={5}
            />
        </InputContainer>
        <FooterContainer>
            <FirstRowFooter>
                <BorderInputComponent
                    value={invernadaFormData.observaciones}
                    onChange={(e) => setInvernadaFormData((prevState: any) => ({
                        ...prevState,
                        observaciones: e.target.value
                      }))}
                    placeholder="Observaciones"
                    width={isMobile() ? 220 : 352}
                    marginBottom={10}
                />
                <div style={{marginLeft: 20}}></div>
                {
                    !showPhoneField
                        ? <PrimaryButtonWithIconComponent
                            onClick={() => setShowPhoneField(true)}
                            backgroundColor={GRAY_COLOR} width={isMobile() ? '43%' : undefined} iconColor={PLACEHOLDER_FONT_COLOR} textColor={PLACEHOLDER_FONT_COLOR} icon={FaWhatsapp} text={"Agregar Celular"}  />
                        :<BorderInputComponent
                            value={invernadaFormData.celular}
                            onChange={(e) => setInvernadaFormData((prevState: any) => ({
                                ...prevState,
                                celular: e.target.value
                            }))}
                            placeholder="Celular"
                            width={isMobile() ? 220 : 170}
                            marginBottom={10}
                            type="number"
                        />
                }
            </FirstRowFooter>
            <SecondRowFooter>
                <BorderInputComponent
                    value={invernadaFormData.plazo}
                    onChange={(e) => setInvernadaFormData((prevState: any) => ({
                        ...prevState,
                        plazo: e.target.value
                      }))}
                    placeholder="Plazo"
                    width={210}
                    marginBottom={10}
                />
            <div style={isMobile() ? { marginLeft: 0, marginRight: 50, marginTop: 15 }: { marginLeft: 15, marginRight: 10 }}>
            <FileSelectorButtonComponent label={'Cargar Video'} width={isMobile() ? 200 : 100}onFileSelect={handleVideoSelect} inputId="fileInputVideo" />
            </div>
            <div style={isMobile() ? { marginLeft: 0, marginRight: 50, marginTop: 15, paddingBottom: 15 } : {marginLeft: 45}}>
                <FileSelectorButtonComponent label={'Cargar Foto'} width={isMobile() ? 200 : 100} onFileSelect={handleImageSelect} inputId="fileInputImage" />
            </div>
            </SecondRowFooter>
            <PrimaryButtonComponent onClick={() => saveInvernadaBatch()} height="48px" width={isMobile() ? '50%' : undefined} >Guardar</PrimaryButtonComponent>
        </FooterContainer>
    </CenteredFormContainer>
  )
}
