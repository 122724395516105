import React, { useState, useEffect, useCallback } from 'react';
import {
    Dialog,
    DialogContent,
    IconButton,
    Box,
    Select,
    MenuItem,
    SelectChangeEvent
} from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';
import DownloadIcon from '@mui/icons-material/Download';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import styled from 'styled-components';
import { HeaderComponent } from '../../components/common/HeaderComponent';
import { BorderInputComponent } from '../../components/form/inputs/BorderInputComponent';
import { PRIMARY_COLOR, WHITE } from '../../constants/colors.constants';
import { LabelComponent } from '../../components/form/basics/LabelComponent';
import { API_URL } from '../../constants/config.constants';
import { Province } from '../../model/Province';
import { Location } from "../../model/Location";
import { isMobile } from '../../utils/utils';
import EditIcon from '@mui/icons-material/Edit';
import { fetchWithAuth } from '../Auth/AuthUtils';

export interface Comitente {
    id: string;
    nombre: string;
    provincia: string;
    localidad: string;
    establecimientos: number;
}

interface ComitentesPageProps {
  open: boolean;
  onClose: () => void;
  onAddPrincipal: () => void;
  onEditPrincipal: (userId: string | undefined) => void;
}

const Comitentes:  React.FC<ComitentesPageProps> = ({ open, onClose, onAddPrincipal, onEditPrincipal }) => {
    const [search, setSearch] = useState('');
    const [comitentes, setComitentes] = useState<Comitente[]>([]);
    //const [open, setOpen] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(4);
    //const [isModalOpen, setIsModalOpen] = useState(false);  
    const [locations, setLocations] = useState<Location[]>([]);
    const [provinces, setProvinces] = useState<Province[]>([]);
  
    useEffect(() => {
      const fetchLocations = async () => {
        let url = `${API_URL}/localidades/?limit=10000`;
        let allLocations: any[] = [];
        try {
            while (url) {
                const response = await fetchWithAuth(url);
                const data = await response.json();
                const locationsBatch = data.results;
                allLocations = [...allLocations, ...locationsBatch];
                url = data.next;
            }

            setLocations(allLocations);
        } catch (error) {
          console.error('Error fetching locations:', error);
        }
      };


      const fetchProvinces = async () => {
        try {
          const response = await fetchWithAuth(`${API_URL}/provincias/`);
          const data = await response.json();
          setProvinces(data.results);
        } catch (error) {
          console.error('Error fetching provinces:', error);
        }
      };
  
      fetchLocations();
      fetchProvinces();
    }, []);

    useEffect(() => {
      const fetchUsers = async () => {
          try {
              const response = await fetchWithAuth(`${API_URL}/comitentes/`);

              if (!response.ok) {
                  console.error('Error fetching principals:', response.statusText);
                  return;
              }
              const data = await response.json();
              console.log(data)
              if (data && Array.isArray(data.results)) {
                setComitentes(data.results.map((principal: any) => {
                    const provincia = provinces.find((prov: Province) => prov.id == principal.provincia);
                    const localidad = locations.find((loc: Location) => loc.id == principal.localidad);
                    console.log(principal)
                    let establecimientosLabel = "";
                    principal.establecimientos.forEach((establecimiento: any) => {
                      establecimientosLabel = establecimiento.nombre + ", " + establecimientosLabel;
                    })
                    return {
                        id: principal.id,
                        nombre: principal.nombre,
                        provincia: provincia ? provincia.nombre : 'Desconocido',
                        localidad: localidad ? localidad.nombre : 'Desconocido',
                        establecimientos: establecimientosLabel
                    };
                }));
              } else {
                  console.error('API did not return expected results:', data);
              }
          } catch (error) {
              console.error('Error fetching users:', error);
          }
      };

      fetchUsers();
  }, [provinces, locations]);


    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
    };


    const handlePageChange = (page: number) => {
        setCurrentPage(page);
      };

    const filteredComitentes = comitentes.filter(comitente =>
        comitente.nombre.toLowerCase().includes(search.toLowerCase())
    );

    const paginatedComitentes = filteredComitentes.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage);

    const handleRowsPerPageChange = (event: SelectChangeEvent<unknown>) => {
        setRowsPerPage(event.target.value as number);
        setCurrentPage(1); // Reset to first page when rows per page changes
    };

    return (
      <>
        <StyledDialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <StyledDialogContent>
                 <Header>
                    <HeaderComponent title={"Comitentes"} subtitle="" />
                    <IconButton onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </Header>

                <ActionsContainer>
                    <BorderInputComponent
                        placeholder='Buscar'
                        value={search}
                        onChange={handleSearch}
                        width={isMobile() ? 180 : 250}
                        hasError={false}
                    />
                    <Actions>
                        <IconButton onClick={onAddPrincipal}>
                            <AddIcon style={{color: WHITE, backgroundColor: PRIMARY_COLOR, borderRadius: 100, padding: 5}} />
                        </IconButton>
                    </Actions>
                </ActionsContainer>
                <TableContainer>
                    <StyledTable>
                        <StyledTableHead>
                            <TableHeader> Nombre </TableHeader>
                            <TableHeader> Provincia </TableHeader>
                            <TableHeader> Localidad </TableHeader>
                            <TableHeader> Establecimientos </TableHeader>

                        </StyledTableHead>
                        <TableBody>
                            {paginatedComitentes.map((comitente, index) => (
                                <StyledTableRow key={index}>
                                    <EditIcon onClick={() => onEditPrincipal(comitente.id)}/>
                                    <TableRow>{comitente.nombre}</TableRow>
                                    <TableRow>{comitente.provincia}</TableRow>
                                    <TableRow>{comitente.localidad}</TableRow>
                                    <TableRow>{comitente.establecimientos ||'No hay establecimiento(s)'}</TableRow>
                                </StyledTableRow>
                            ))}
                        </TableBody>

                    </StyledTable>
                </TableContainer>
                <PaginationContainer>
                    <LabelComponent text={'Filas por Página'} color='#697077' />
                    <StyledSelect
                        value={rowsPerPage}
                        onChange={handleRowsPerPageChange}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Rows per page' }}
                    >
                        <MenuItem value={4}>4</MenuItem>
                        <MenuItem value={6}>6</MenuItem>
                        <MenuItem value={8}>8</MenuItem>
                    </StyledSelect>
                    
                    <Pagination>
                        <PaginationButton onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>❮</PaginationButton>
                        {[...Array(Math.ceil(filteredComitentes.length / rowsPerPage)).keys()].map(page => (
                            <PaginationButton key={page + 1} onClick={() => handlePageChange(page + 1)} active={page + 1 === currentPage}>{page + 1}</PaginationButton>
                        ))}
                        <PaginationButton onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === Math.ceil(filteredComitentes.length / rowsPerPage)}>❯</PaginationButton>
                    </Pagination>
                </PaginationContainer>
            </StyledDialogContent>
        </StyledDialog>
        </>

    );
};

export default Comitentes;

const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    border-radius: 19px;
  }
`;

const StyledDialogContent = styled(DialogContent)`
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  overflow-x: hidden;
`;

const Header = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  .MuiTypography-h5 {
    font-size: 24px;
    font-weight: bold;
  }
`;

const Actions = styled(Box)`
  display: flex;
  flex: 2;
  justify-content: flex-end;
  align-items: center;


  .MuiIconButton-root {
    color: #0f4c81;
  }

  @media (max-width: 768px) {
   margin-bottom: unset; 
  }
`;


const StyledTable = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;

`
const TableContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    
  @media (max-width: 768px) {
    overflow-x: scroll;
  }
    
`
const TableBody = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 10px;
`

const StyledTableHead = styled.div`
  background-color: ${PRIMARY_COLOR};
  border-radius: 18px;
  color: white;
  font-weight: bold;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    padding-left: 50px;
    padding-right: 10px;
  }
`;

const StyledTableRow = styled.div`
  border-radius: 18px;
  background-color: white;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5px;
  border: 1px solid rgba(33, 39, 42, 0.1);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.1);
  &:hover {
    border: 1px solid rgba(33, 39, 42, 0.2);
  }

  @media (max-width: 768px) {
    overflow-x: scroll;
    padding-left: 20px;
    padding-right: 40px;
  }
`;


const PaginationContainer = styled(Box)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
  gap: 5px;
`;

const Pagination = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const PaginationButton = styled.button<{ active?: boolean }>`
  background: none;
  border: none;
  color: ${({ active }) => (active ? PRIMARY_COLOR : '#697077')};
  font-weight: ${({ active }) => (active ? 'bold' : 'normal')};
  cursor: pointer;
  &:disabled {
    color: #ccc;
    cursor: not-allowed;
  }
`;
const ActionsContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    @media (max-width: 768px) {
      justify-content: center;
    }
`;

const TableHeader = styled.p`
    padding-left: 10px;
    color: white;
    flex: 1;
`

const TableRow = styled.p`
    padding-left: 10px;
    color: #21272A;
    flex: 1;
`

const StyledSelect = styled(Select)`
  & .MuiSelect-select {
    padding: 8px;
    border: none;
    box-shadow: none;
  }
  & .MuiOutlinedInput-notchedOutline {
    border: none;
  }
  &.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: none;
  }
`;
