import styled from "styled-components";
import { PRIMARY_COLOR, WHITE } from "../../constants/colors.constants";
import SingleImageCarousel from "../../components/carousel/SingleImageCarousel";
import BackgroundImage from '../../assets/background/background-about-us.png';
import { FaHome, FaEnvelope, FaPhoneAlt, FaWhatsapp } from "react-icons/fa";

const StyledTitle = styled.h2`
    color: ${PRIMARY_COLOR};
    font-size: 58px;
    font-style: italic;
    width: 60%;
    text-align: left;

    @media (max-width: 768px) {
      font-size: 36px;
      font-weight: 800;
    }
`;

const StyledInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (max-width: 768px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 50px;
    }
`;

const StyledParagraph = styled.p`
  color: ${PRIMARY_COLOR};
  font-size: 24px;
  line-height: 60px;
  text-align: justify;
  width: 40%;

  @media (max-width: 768px) {
    font-size: 20px;
    width: 80%;
    text-align: left;
  }

  @media (min-width: 800px) and (max-width: 1800px) {
    line-height: 40px;
  }
`;

const MainWrapper = styled.div`
    min-height: 100vh;
    box-sizing: border-box;
    padding: 80px;
    background-image: url(${BackgroundImage});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    gap: 150px;
    align-items: center;
    justify-content: center;
`;

const StyledCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${PRIMARY_COLOR};
  box-shadow: 0 40px 25px rgba(0, 0, 0, 0.4);
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 20px;
  width: 632px;

  @media (max-width: 768px) {
    width: 320px;
    height: 270px;
    padding-bottom: 20px;
  }

  @media (min-width: 769px) and (max-width: 1280px) {
    width: 500px;
  }
`;

const StyledCardTitle = styled.p`
  text-align: left;
  color: ${WHITE};
  font-size: 32px;
  font-weight: 600;
`;

const StyledCardInfo = styled.p`
  text-decoration: none;
  color: ${WHITE};
  font-size: 20px;
  text-align: left;
  padding-left: 25px;
  

  @media (max-width: 768px) {
    font-size: 14px;
}
`;

const StyledCardsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  gap: 150px;

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    gap: 50px;
}

@media (min-width: 1282px) and (max-width: 1335px) {
  gap: 100px;
}
`;

const IconDataContainer = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${PRIMARY_COLOR};
  border-radius: 20px;
  cursor: pointer;
  text-decoration: none;

  @media (max-width: 768px) {
    
}
`;

const StyledContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 632px;
  gap: 20px;

  @media (max-width: 768px) {
    width: 360px;
  }

  @media (min-width: 769px) and (max-width: 1220px) {
    width: 480px;
  }

  @media (min-width: 1220px) and (max-width: 1280px) {
    width: 520px;
  }

  @media (min-width: 1281px) and (max-width: 1500px) {
    width: 490px;
    margin-right: 30px;
  }

  @media (min-width: 1282px) and (max-width: 1335px) {
    width: 475px;
  }
`;

const StyledInfoAndCarousel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 40px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const AboutAndContactPage = () => {
  return (
    <MainWrapper id="about">
        <StyledInfoContainer>
            <StyledTitle>Una empresa con más de 50 años de trayectoria.</StyledTitle>
            <StyledInfoAndCarousel>
                <StyledParagraph>
                    Empresa familiar especializada en remates de ganado y tierras de haciendas. Trabajamos con consignaciones de pequeños y grandes productores, ofreciendo una amplia variedad de ganado y tierras. Además de remates, organizamos eventos de venta de carne de alta calidad, incluyendo reses y vientres.
                </StyledParagraph>
                <SingleImageCarousel />
            </StyledInfoAndCarousel>
        </StyledInfoContainer>

        <StyledCardsContainer id="contact">
            <StyledCardContainer>
                <StyledCardTitle>Casa Central</StyledCardTitle>
                <IconDataContainer>
                <FaHome size={25} color={WHITE} style={{paddingLeft: 15}}/>
                <StyledCardInfo><a style={{textDecoration: 'none', color: 'unset'}} href="https://www.google.com.ar/maps/place/Pueyrred%C3%B3n+743,+W3460+Curuz%C3%BA+Cuati%C3%A1,+Corrientes/@-29.7897028,-58.06023,17z/data=!3m1!4b1!4m6!3m5!1s0x9452925968002b57:0x5943f5bf06a0d75b!8m2!3d-29.7897075!4d-58.0576551!16s%2Fg%2F11h51b5fw0?entry=ttu" target="_blank">Pueyrredón 743 , Curuzú Cuatiá, Argentina</a></StyledCardInfo>
                </IconDataContainer>
                <IconDataContainer>
                <FaEnvelope size={25} color={WHITE} style={{paddingLeft: 15}}/>
                <StyledCardInfo><a style={{textDecoration: 'none', color: 'unset'}} href='https://mail.google.com/mail/u/0/?tab=rm&ogbl#inbox?compose=CllgCJvlqQzhxmNTDqpMlzbjCXKbkSnGKKBDQNCsTPCvsqxpffWFLFFbmGGZkGHBWlLlRxBBfcL' target='_blank'>info@reggiycia-srl.com.ar</a></StyledCardInfo>
                </IconDataContainer>
                <IconDataContainer>
                <FaPhoneAlt size={25} color={WHITE} style={{paddingLeft: 15}}/>
                <StyledCardInfo>(3774) 424010 / 424011</StyledCardInfo>
                </IconDataContainer>
            </StyledCardContainer>
            <StyledContactContainer>
                <IconDataContainer href="https://wa.me/3774413071" target="_blank">
                <FaWhatsapp size={25} color={WHITE} style={{paddingLeft: 15}}/>
                <StyledCardInfo>Casimiro Reggi | 3774 - 413071</StyledCardInfo>
                </IconDataContainer>
                <IconDataContainer href="https://wa.me/3774635588" target="_blank">
                <FaWhatsapp size={25} color={WHITE} style={{paddingLeft: 15}}/>
                <StyledCardInfo>Federico Galdames | 3774 - 635588</StyledCardInfo>
                </IconDataContainer>
                <IconDataContainer href="https://wa.me/3774444762" target="_blank">
                <FaWhatsapp size={25} color={WHITE} style={{paddingLeft: 15}}/>
                <StyledCardInfo>Sebastian Vallejo | 3774 - 444762</StyledCardInfo>
                </IconDataContainer>
                <IconDataContainer href="https://wa.me/3774449128" target="_blank">
                <FaWhatsapp size={25} color={WHITE} style={{paddingLeft: 15}}/>
                <StyledCardInfo>Casimiro Reggi Acheriteguy  | 3774 - 449128</StyledCardInfo>
                </IconDataContainer>
            </StyledContactContainer>
        </StyledCardsContainer>
    </MainWrapper>
  )
}
