import { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import SimpleNavbar from "../../components/shared/SimpleNavBar";
import CowsBackgroundImage from '../../assets/login-background.png';
import { BLACK_FONT, GOLDEN_COLOR, PRIMARY_COLOR, WHITE } from "../../constants/colors.constants";
import { IconButtonComponent } from "../../components/buttons/IconButtonComponent";
import { AuctionItemComponent } from "../../components/auction/AuctionItemComponent";
import { SecondaryButtonWithIconComponent } from "../../components/buttons/SecondaryButtonComponentWithIcon";
import { FaFileExcel } from "react-icons/fa";
import { filterFinishedAuctions, isMobile } from "../../utils/utils";
import { PrimaryButtonComponent } from "../../components/buttons/PrimaryButtonComponent";
import { API_URL } from "../../constants/config.constants";
import { auctionDatesFilterByYear, auctionTypes } from "../../constants/dropdowns/auctionsTypes";
import BorderDropdownComponent from "../../components/form/dropdowns/BorderDropdownComponent";
import { SpinnerForTables } from "../../components/loadings/SpinnerForTables";
import { useNavigate } from "react-router-dom";
import { MobileNavBar } from "../../components/shared/MobileNavBar";
import { fetchWithAuth } from "../Auth/AuthUtils";

const BackgroundImage = styled.div`
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url(${CowsBackgroundImage});
    background-size: cover;
    background-position: center;
    z-index: -1;
`;

const AuctionsContainer = styled.div`
    position: relative;
    height: 611px;
    width: 1069px;
    background-color: ${WHITE};
    border-radius: 19px;
    padding: 40px;
    overflow-y: scroll;
    overflow-x: hidden;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Sutil box-shadow */
    z-index: 1;
`;

const HeaderContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 768px) {
        justify-content: center;
    }
`;

const Title = styled.h2`
    color: ${BLACK_FONT};
    font-size: 42px;

    @media (max-width: 768px) {
        font-size: 24px;
    }
`;

const ButtonsContainer = styled.div`
    margin-left: 160px;
    display: flex;
    flex-direction: row;
    margin-bottom: 40px;

    @media (max-width: 768px) {
        margin-left: 100px;
    }
`;

const FiltersContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: 20px;
`;

const ModalOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
`;

const ModalContent = styled.div`
    background: ${WHITE};
    border-radius: 10px;
    padding: 40px;
    width: 600px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

    @media (max-width: 768px) {
        width: 100%;
    }
`;

const NoItemsContainer = styled.div`
    margin-top: 40px;
    height: 300px;
    border-radius: 20px;
    box-shadow: 0 2px 10px ${PRIMARY_COLOR};
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;

const NoItemsPlaceholder = styled.p`
    color: ${BLACK_FONT};
    font-size: 30px;
    font-weight: 600;
`;

export const FinishedAuctionsPage = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [finishedAuctions, setFinishedAuctions] = useState([]);
    const [filteredFinishedAuctions, setFilteredFinishedAuctions] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedYear, setSelectedYear] = useState<string>("");
    const [selectedAuctionType, setSelectedAuctionType] = useState<string>("");
    const modalRef = useRef<any>(null);

    const fetchAuctions = async () => {
        setIsLoading(true);
        try {
            const response = await fetchWithAuth(`${API_URL}/remates/`);
            const data = await response.json();
            setFinishedAuctions(filterFinishedAuctions(data.results));
            setIsLoading(false);
            console.log(data)
        } catch (error) {
            console.error("Error fetching remates:", error);
            setIsLoading(false);
        }
    };
    
    const toggleModal = () => {
        setShowModal(!showModal);
    }

    const handleClickOutside = (event: any) => {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
            setShowModal(false);
        }
    }

    useEffect(() => {
        fetchAuctions();
    }, []);

    useEffect(() => {
        if (showModal) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [showModal]);

    const displayAuctions = finishedAuctions.filter( (auction: any) => {
        const matchesYear = selectedYear && selectedYear !== "" ? auction.fecha.split('-')[0] === selectedYear.split('-')[0] : true
        const matchesAuctionType = selectedAuctionType && selectedAuctionType !== "" ? auction.tipo === selectedAuctionType : true
        return matchesYear && matchesAuctionType;
    })

    const getDate = (date: any) => {
        const parsedDate = date.split('T');
        return parsedDate[0];
    }

    const getHour = (date: any) => {
        const partDate = date.split('T');
        const hour = partDate.toString().split(',');
        return hour[1].split('Z')[0].substring(0, 5);
    }

    const getAuctionColor = (tipo: any) => {
        const auctionColor:any = {
            "Cabañas": PRIMARY_COLOR,
            "Habitual": PRIMARY_COLOR,
            "Especial": PRIMARY_COLOR,
            "Exposicion": GOLDEN_COLOR,
            "Libre": PRIMARY_COLOR,
            "Especial Faena": PRIMARY_COLOR,
            "Holando": PRIMARY_COLOR
        };
        return auctionColor[tipo];
    }

    const navigate = useNavigate();

    return (
        <div style={{ height: '100vh' }}>
            {isMobile() ? <MobileNavBar/> : <SimpleNavbar showUserMenu={true} />}
            <BackgroundImage>
                <AuctionsContainer>
                    <HeaderContainer>
                        <div></div>
                        <Title>Remates Realizados</Title>
                        <IconButtonComponent onClick={() => navigate(-1)} />
                    </HeaderContainer>
                    {isLoading && <SpinnerForTables/>}
                    {
                        !isLoading && (
                            <>
                    <FiltersContainer>
                        <PrimaryButtonComponent width="94px" borderRadius={20} onClick={toggleModal}>Filtros</PrimaryButtonComponent>
                    </FiltersContainer>
                    {!displayAuctions.length && (
                        <NoItemsContainer>
                            <NoItemsPlaceholder>
                                No se encontraron Remates
                            </NoItemsPlaceholder>
                        </NoItemsContainer>
                    )}
                    {
                        displayAuctions.map((auction: any) =>
                            <div key={auction.id}>
                                <AuctionItemComponent
                                    type={auction.tipo}
                                    marginTop="0"
                                    backgroundColor={getAuctionColor(auction.tipo)}
                                    whenDate={getDate(auction.fecha)}
                                    whenHour={`${getHour(auction.fecha)} hs`}
                                    location={auction.lugar}
                                    inPerson={!!auction.fisico}
                                    auctionId={auction.id}
                                    videoUrl={auction.url_streaming}
                                />
                                <ButtonsContainer>
                                    <SecondaryButtonWithIconComponent padding={isMobile() ? '0 5px 0 5px' : '10px 20px'} height={isMobile() ? '25px' : '40px'} fa={getAuctionColor(auction.tipo)} color={getAuctionColor(auction.tipo)} margin icon={FaFileExcel} text={`${auction.cabezas} Cabezas`} />
                                </ButtonsContainer>
                            </div>
                        )
                    }
                            </>
                        )
                    }
                </AuctionsContainer>
            </BackgroundImage>

            {showModal &&
                <ModalOverlay>
                    <ModalContent ref={modalRef}>
                        <BorderDropdownComponent width={isMobile() ? 300 : 500} placeholder={"Año de Búsqueda *"} options={auctionDatesFilterByYear} onChange={(e) => setSelectedYear(e)}  />
                        <BorderDropdownComponent width={isMobile() ? 300 : 500} placeholder={"Tipo de Remate *"} options={auctionTypes} onChange={(e) => setSelectedAuctionType(e)}  />
                        <PrimaryButtonComponent onClick={() => {setSelectedAuctionType(""); setSelectedYear(''); toggleModal()}} borderRadius={20}>Cancelar</PrimaryButtonComponent>
                    </ModalContent>
                </ModalOverlay>
            }
        </div>
    );
};
