import React, { useState } from 'react';
import styled from 'styled-components';
import imageSrc from '../../assets/foto-vacas.png';
import { PRIMARY_COLOR } from '../../constants/colors.constants';
import { FaLock } from 'react-icons/fa';
import { AuctionItemData } from '../../pages/Batchs/BatchListPage';
import { batch } from 'react-redux';
import { DetailBatchModal } from '../../pages/Batchs/modal/DetailBatchModal';


  
interface BatchItemProps {
    item: AuctionItemData;

  }

const Container = styled.div`
  width: 387px;
  border-radius: 12px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;

  gap: 10px;
`;

const HeaderContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 0px; /* Adjust this value to move the header up if needed */
`;

const Header = styled.div`
  background-color: transparent;
  padding: 10px 20px;
  text-align: center;
  font-weight: bold;
  border-radius: 12px;
  border: 1px solid ${PRIMARY_COLOR};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Triangle = styled.div`
  position: absolute;
  bottom: -10px; /* Adjust this value to position the triangle */
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid ${PRIMARY_COLOR};
`;


const ImageContainer = styled.div`
  width: 95%;
  height: 150px;
  overflow: hidden;
  border: 6px solid ${PRIMARY_COLOR};
  border-radius: 12px;
`;

const Image = styled.img`
  width: 100%;
  height: auto;
`;

const InfoContainer = styled.div`
  position: relative;
  border: 1px solid #003C5C;
  border-radius: 10px;
  padding: 15px;
  width: 90%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &:before {
    content: '';
    position: absolute;
    top: -10px; /* Ajusta este valor para posicionar el triángulo */
    left: 10px; /* Mueve el triángulo hacia la izquierda */
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #003C5C; /* Mismo color que el borde del contenedor */
  }
`;


const Certificate = styled.div`
  color: #666666;
  font-size: 14px;
  
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
  color: ${PRIMARY_COLOR};
`;

const Location = styled.div`
  font-size: 16px;
  color: #666666;
`;

const LocationRow = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
`

const WeightContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  color: ${PRIMARY_COLOR};
  margin-right: 0%;
`;

const LockIcon = styled(FaLock)`
  margin-right: 5px;
`;

const BatchItem: React.FC<BatchItemProps> = ({ item }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleButtonClick = () => {
      setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    console.log("han clo")
      setIsModalOpen(false);
  };


  return (
    <>
    <Container onClick={handleButtonClick}>
      <HeaderContainer>
        <Header>{item.lote} | {item.nombre}</Header>
        <Triangle />
      </HeaderContainer>
      <ImageContainer>
        <Image src={item.foto} alt="Lote de ganado" />
      </ImageContainer>
      <InfoContainer>
        <Certificate>Cert: {item.cert}</Certificate>
        <Title>{item.cantidad} | {item.tipo}</Title>
        <LocationRow>
          <Location>{item.location}</Location>
          <WeightContainer>
            <LockIcon />
            <span>{item.weight}</span>
          </WeightContainer>
        </LocationRow>
      </InfoContainer>
    </Container>
    {isModalOpen && <DetailBatchModal onClose={handleCloseModal} id={item.id} category={item.loteType.toLowerCase()} />}
    </>
  );
};

export default BatchItem;