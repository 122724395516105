import { useState } from "react";
import styled from "styled-components";
import { FaAngleDown } from "react-icons/fa";
import { BLACK_FONT, GRAY_COLOR, PRIMARY_COLOR, RED_COLOR, WHITE } from "../../../constants/colors.constants";

interface MultiSelectComponentProps {
    width?: number;
    placeholder: string;
    color?: string;
    backgroundColor?: string;
    options: Option[];
    onChange: (value: any) => void;
    hasError?: boolean;
    marginBottom?: number;
    disabled?: boolean;
    useLabel?: boolean;
};

interface Option {
  label: string;
  value: string | number;
}

const DropdownWrapper = styled.div<{marginBottom?: number}>`
  position: relative;
  display: inline-block;
  margin-bottom: ${(props) => props.marginBottom}px;
`;

const DropdownButton = styled.button<{backgroundColor: string, width: number, color: string, hasError: boolean}>`
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.color};
  text-align: left;
  padding: 10px;
  padding-left: 16px;
  font-size: 16px;
  width: ${(props) => props.width}px;
  border-radius: 12px;
  border: ${(props) => props.hasError ? `2px solid ${RED_COLOR}` : 'none'};
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.4);
  height: 53px;
  position: relative; /* Para posicionar el icono */
  pointer-events: ${(props) => props.disabled ? 'none' : 'unset'};
  cursor: ${(props) => props.disabled ? 'not-allowed' : 'pointer'};
`;

const DropdownIcon = styled.span`
  position: absolute;
  top: 50%;
  right: 12px; /* Ajusta el espacio del icono */
  transform: translateY(-50%);
`;

const DropdownContent = styled.div<{isOpen: boolean, width: number, backgroundColor: string}>`
  display: ${(props) => (props.isOpen ? "block" : "none")};
  position: absolute;
  background-color: #f9f9f9;
  border-radius: 12px;
  min-width: ${(props) => props.width * 0.80}px;
  border: 1px solid ${GRAY_COLOR};
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.4);
  margin-top: 2px;
  z-index: 1;
  max-height: 200px;
  overflow-y: auto;
`;

const DropdownItem = styled.label<{isSelected: boolean}>`
  display: flex;
  align-items: center;
  padding: 14px 18px;
  cursor: pointer;
  text-align: left;
  background-color: ${(props) => (props.isSelected ? PRIMARY_COLOR : 'transparent')};
  color: ${(props) => (props.isSelected ? WHITE : 'unset')};

  &:hover {
    background-color: #f1f1f1;
    color: unset;
  }

  input {
    margin-right: 8px;
  }
`;

const MultiSelectComponent = ({
    placeholder,
    backgroundColor = GRAY_COLOR,
    width = 300,
    color = BLACK_FONT,
    options,
    onChange,
    hasError = false,
    marginBottom = 20,
    disabled = false,
    useLabel = false
}: MultiSelectComponentProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<Option[]>([]);

  const handleItemClick = (option: Option, useLabel: boolean) => {
    setSelectedOptions(prevSelected => {
      const isSelected = prevSelected.find(selected => selected.value === option.value);
      let newSelectedOptions;
      if (isSelected) {
        newSelectedOptions = prevSelected.filter(selected => selected.value !== option.value);
      } else {
        newSelectedOptions = [...prevSelected, option];
      }
      onChange(newSelectedOptions.map(opt => useLabel ? opt.label : opt.value));
      return newSelectedOptions;
    });
  };

  const selectedLabels = selectedOptions.map(option => option.label).join(', ');

  return (
    <DropdownWrapper marginBottom={marginBottom}>
      <DropdownButton
        backgroundColor={backgroundColor}
        width={width}
        onClick={() => setIsOpen(!isOpen)}
        color={color}
        hasError={hasError}
        disabled={disabled}
      >
        {selectedLabels || placeholder}
        <DropdownIcon>
          <FaAngleDown />
        </DropdownIcon>
      </DropdownButton>
      <DropdownContent
        isOpen={isOpen}
        width={width}
        backgroundColor={backgroundColor}
      >
        {options.map((option, index) => (
          <DropdownItem
            key={index}
            isSelected={!!selectedOptions.find(selected => selected.value === option.value)}
          >
            <input
              type="checkbox"
              checked={!!selectedOptions.find(selected => selected.value === option.value)}
              onChange={() => handleItemClick(option, useLabel)}
            />
            {option.label}
          </DropdownItem>
        ))}
      </DropdownContent>
    </DropdownWrapper>
  );
};

export default MultiSelectComponent;
