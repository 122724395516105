import React, { useState } from "react";
import styled from "styled-components";
import { PRIMARY_COLOR, RED_COLOR, WHITE } from "../../constants/colors.constants";
import { PrimaryButtonWithIconComponent } from "../../components/buttons/PrimaryButtonWithIconComponent";
import { FaLock, FaPencilAlt, FaTimes } from "react-icons/fa";
import { isMobile } from "../../utils/utils";
import { EditBatchModal } from "./modal/EditBatchModal";
import { useDrag } from 'react-dnd';
import { fetchProvinceName } from "./BatchUtils";

const StyledContainer = styled.div`
    background-color: ${PRIMARY_COLOR};
    width: 100%;
    height: 86px;
    border-radius: 10px;
    cursor: pointer;
`;

const StyledDataContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 100%;
    align-items: center;
    padding: 0 20px 0 20px;
`;

const FirstRowContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
`;

const SecondRowContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
`;

const ThirdRowContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 20px;
`;

const StyledItemPrimaryLabel = styled.span`
    color: ${WHITE};
    font-weight: bold;
    font-size: 24px;

    @media (max-width: 769px) {
        font-size: 11px;
    }
`;

const StyledItemSecondaryLabel = styled.span`
    color: ${WHITE};
    font-weight: 300;
    font-size: 14px;
    text-align: left;

    @media (max-width: 769px) {
        font-size: 8px;
    }
`;

const StyledIconAndDataContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 14px;
`;

export const BatchOrderItem = ({ content, provincia, localidad, onEdit, onDelete }: any) => {

    console.log(provincia)
    
    const [{ isDragging }, drag] = useDrag({
        type: 'BATCH_ITEM',
        item: { id: content.id },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
        options: {
            dropEffect: 'copy',
        },
    });

    return (
        <StyledContainer ref={drag}>
            <StyledDataContainer>
                <FirstRowContainer>
                    <StyledItemPrimaryLabel>
                        Lote {content.id} | Reggi y CIA S.R.L 
                    </StyledItemPrimaryLabel>
                    <StyledItemSecondaryLabel>
                        {localidad} {provincia} | {content.plazo}
                    </StyledItemSecondaryLabel>
                </FirstRowContainer>
                <SecondRowContainer>
                    <StyledItemPrimaryLabel>
                        {content.cantidad} | {content.categoria} | Comitentes
                    </StyledItemPrimaryLabel>
                    <StyledIconAndDataContainer>
                        <FaLock
                            size={isMobile() ? 10 : 20}
                            color={WHITE}
                        />
                        <StyledItemSecondaryLabel>250 Kg.</StyledItemSecondaryLabel>
                    </StyledIconAndDataContainer>
                </SecondRowContainer>
                <ThirdRowContainer>
                    <PrimaryButtonWithIconComponent
                        padding={isMobile() ? "3px" : "10px 20px"}
                        fontSize="10px"
                        height={isMobile() ? "21px" : "48px"}
                        icon={FaPencilAlt}
                        text={"Editar"}
                        backgroundColor={WHITE}
                        iconColor={PRIMARY_COLOR}
                        textColor={PRIMARY_COLOR}
                        onClick={onEdit}
                    />
                    <PrimaryButtonWithIconComponent
                        padding={isMobile() ? "3px" : "10px 20px"}
                        fontSize="10px"
                        height={isMobile() ? "21px" : "48px"}
                        icon={FaTimes}
                        text={"Borrar"}
                        backgroundColor={RED_COLOR}
                        textColor={WHITE}
                        onClick={onDelete}
                    />

                </ThirdRowContainer>
            </StyledDataContainer>
        </StyledContainer>
    );
};
