import { useAppDispatch } from '../../app/Hooks';
import { API_URL } from '../../constants/config.constants';
import { authUser, logUserOut, refreshTokens } from '../../pages/Auth/AuthSlice';
import { useNavigate } from 'react-router-dom';

export function useLogOut() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    return function logOut() {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        dispatch(logUserOut());
        navigate('/auth/login');
    };
}

export function useLogIn() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    return function logIn(data: any, username: string) {
        localStorage.setItem('accessToken', data.access);
        localStorage.setItem('refreshToken', data.refresh);
        localStorage.setItem('username', username);
        localStorage.setItem('firstName', data.user.first_name)
        dispatch(authUser(data.access, data.refresh));
        navigate('/home');
    };
}
export function useRefreshToken() {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    

    return async function refreshToken() {
        const refreshToken = localStorage.getItem('refreshToken');
        try {
            const response = await fetchWithAuth(`${API_URL}/auth/token/refresh/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ refresh: refreshToken }),
            });

            if (!response.ok) {
                throw new Error('Failed to refresh token');
            }

            const result = await response.json();

            localStorage.setItem('accessToken', result.access);
            localStorage.setItem('refreshToken', result.refresh);
            dispatch(refreshTokens({ accessToken: result.access, refreshToken: result.refresh }));
        } catch (error) {
            console.error('Error refreshing token:', error);
            localStorage.removeItem('accessToken');
            localStorage.removeItem('refreshToken');
            dispatch(logUserOut());
            navigate('/auth/login');
        }
    };
}



export const fetchWithAuth = async (input: RequestInfo, init?: RequestInit): Promise<Response> => {
  const accessToken = localStorage.getItem('accessToken');
  const refreshToken = localStorage.getItem('refreshToken');

  const fetchWithToken = async (token: string): Promise<Response> => {
    // Si la URL contiene un mecanismo de autenticación, no agregamos la cabecera Authorization
    const url = typeof input === 'string' ? input : input.url;
    const hasAuthMechanism = url.includes('X-Amz-Algorithm') || url.includes('Signature');

    const headers = {
      ...init?.headers,
      'Content-Type': 'application/json',
      ...(hasAuthMechanism ? {} : { 'Authorization': `Bearer ${token}` }), // Solo agregamos la cabecera si no hay otro mecanismo de autenticación
    };

    return fetch(input, { ...init, headers });
  };
  let response = await fetchWithToken(accessToken!);

  if (response.status === 401 && refreshToken) {
    console.log('Access token expired, attempting to refresh...');
    const refreshResponse = await fetch(`${API_URL}/auth/token/refresh/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ refresh: refreshToken }),
    });

    if (refreshResponse.ok) {
      const data = await refreshResponse.json();
      const newAccessToken = data.access;

      localStorage.setItem('accessToken', newAccessToken);

      response = await fetchWithToken(newAccessToken);
    } else {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      window.location.href = '/login';
      throw new Error('Failed to refresh token');
    }
  }

  return response;
};
