import CarouselComponent from "../../components/carousel/CarouselComponent"
import { HeaderComponent } from "../../components/common/HeaderComponent"
import styled from "styled-components";
import BackgrounImage from '../../assets/background/bg-next-auctions.png';
import { API_URL } from "../../constants/config.constants";
import { useEffect, useState } from "react";
import { Auction } from "../../model/Auction";
import { Province } from "../../model/Province";
import { Location } from "../../model/Location";
import { SeleccionableButtonComponent } from "../../components/buttons/SeleccionableButtonComponent";
import { filterFinishedAuctions, filterNextAuctions, isMobile } from "../../utils/utils";
import { VideoComponent } from "./VideoComponent";

const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    gap: 20px;
    
    margin-bottom: 50px;

    @media (max-width: 768px) {
        flex-direction: row;
        gap: 10px;
    }
`;

const CarouselWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;

  @media (max-width: 768px) {
    padding: 10px;
  }

  @media (min-width: 800px) and (max-width: 1800px) {
    gap: 70px;
  }
  
`;

const MainWrapper = styled.div`
    background-image: url(${BackgrounImage});
    background-size: cover;        
    background-position: center;   
    background-repeat: no-repeat;  
    padding-top: 5rem;

    @media (max-width: 1818px) {
        padding-top: 4rem;
    }

    @media (max-width: 768px) {
        padding-top: 0.1rem;
        padding-left: 10px;
        padding-right: 10px;
    }
`;

const NextAuctions = () => {
  const [carouselItems, setCarouselItems] = useState([]);
  const [locations, setLocations] = useState<Location[]>([]);
  const [provinces, setProvinces] = useState<Province[]>([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [filter, setFilter] = useState<'tv' | 'fisico' | 'streaming' | null>(null);

  useEffect(() => {
    const fetchLocations = async () => {
      let url = `${API_URL}/localidades/?limit=10000`;
      let allLocations: any[] = [];
      try {
          while (url) {
              const response = await fetch(url);
              const data = await response.json();
              const locationsBatch = data.results;
              allLocations = [...allLocations, ...locationsBatch];
              url = data.next;
          }
          setLocations(allLocations);
      } catch (error) {
        console.error('Error fetching locations:', error);
      }
    }
    
    const fetchProvinces = async () => {
      try {
        const response = await fetch(`${API_URL}/provincias/`);
        const data = await response.json();
        setProvinces(data.results);
      } catch (error) {
        console.error('Error fetching provinces:', error);
      }
    };

    fetchLocations();
    fetchProvinces();
  }, []);

  useEffect(() => {
    if (filter) {
      const filtered = carouselItems.filter(item => item[filter]);
      setFilteredItems(filtered);
    } else {
      setFilteredItems(carouselItems);
    }
  }, [filter, carouselItems]);

  useEffect(() => {
    const fetchAuctions = async () => {
      try {
        const response = await fetch(`${API_URL}/remates/`);
        const data = await response.json();

        const formattedData = data.results.map((item: Auction) => {
          const location = locations.find((loc: Location) => loc.id === item.localidad);
          const province = provinces.find((prov: Province) => prov.id === item.provincia);
          return {
            id: item.id,
            title: province ? province.nombre : "Provincia desconocida",
            infoOne: location ? location.nombre : "Localidad desconocida",
            infoTwo: item.lugar,
            button: 'primary',
            img: item.url_foto,
            tv: item.tv,
            streaming: item.streaming,
            fisico: item.fisico,
            fecha: item.fecha,
            type: item.tipo,
            onClick: () => console.log(`${item.nombre} clicked`)
          };
        });
        setCarouselItems(formattedData);
        setFilteredItems(formattedData);

      } catch (error) {
        console.error('Error fetching auctions:', error);
      }
    };

    if (locations.length > 0 && provinces.length > 0) {
      fetchAuctions();
    }
  }, [locations, provinces]);
  
  return (
    <MainWrapper>
        {isMobile() && <VideoComponent/>}
        <HeaderComponent title='Próximos Remates | Remates Realizados' subtitle='NOTICIAS. INFORMACIÓN.' />
        <ButtonWrapper>
          <SeleccionableButtonComponent borderRadius={12} onClick={() => setFilter(filter === 'tv' ? null : 'tv')} backgroundColor={"rgba(0,0,0,0)"}>TV</SeleccionableButtonComponent>
          <SeleccionableButtonComponent borderRadius={12} onClick={() => setFilter(filter === 'fisico' ? null : 'fisico')} backgroundColor={"rgba(0,0,0,0)"}>Físico</SeleccionableButtonComponent>
          <SeleccionableButtonComponent borderRadius={12} onClick={() => setFilter(filter === 'streaming' ? null : 'streaming')} backgroundColor={"rgba(0,0,0,0)"}>Streaming</SeleccionableButtonComponent>
        </ButtonWrapper>

        <CarouselWrapper id="test">     
          <CarouselComponent items={filterNextAuctions(filteredItems)} id="next" />
          <CarouselComponent items={filterFinishedAuctions(filteredItems)} id="finished" />
        </CarouselWrapper>
    </MainWrapper>
  )
}

export default NextAuctions;
