import styled from 'styled-components';
import SimpleNavbar from '../../components/shared/SimpleNavBar';
import CowsBackgroundImage from '../../assets/login-background.png';

import { PrimaryButtonComponent } from '../../components/buttons/PrimaryButtonComponent';
import { IconButtonComponent } from "../../components/buttons/IconButtonComponent";
import { AuctionItemComponent } from '../../components/auction/AuctionItemComponent';
import { SecondaryButtonComponent } from '../../components/buttons/SecondaryButtonComponent';
import TertiaryButtonComponent from '../../components/buttons/TertiaryButtonComponent';
import BatchItem from '../../components/auction/BatchItemComponents';
import { useEffect, useState } from 'react';
import BatchFilters from '../../components/Filters/BatchFilters';
import { API_URL, S3_URL } from '../../constants/config.constants';
import { useSelector } from 'react-redux';
import { RootState } from '../../app/Store';
import { getAuctionColor, getDate, getHour } from '../../utils/AuctionUtils';
import { useNavigate, useParams } from 'react-router-dom';
import { Auction } from '../../model/Auction';
import Navbar from '../../components/shared/NavBar';
import { isMobile } from '../../utils/utils';
import { MobileNavBar } from '../../components/shared/MobileNavBar';
import BorderDropdownComponent from '../../components/form/dropdowns/BorderDropdownComponent';
import { exportPdf } from '../../api/ControlPanelAPI';
import { fetchWithAuth } from '../Auth/AuthUtils';

export interface AuctionItemData {
    id: number;
    lote: string;
    nombre: string;
    cert: string;
    cantidad: number;
    tipo: string;
    location: string;
    weight: string;
    loteType: string;
    miomio: boolean | undefined;
    garrapata: string[];
    categoria: string[];
    foto: string;
}

const BackgroundImage = styled.div`
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    //background-image: url(${CowsBackgroundImage});
    background-size: cover;
`;

const MainWrapper = styled.div`
    height: 90vh;
    width: 70vw;
    border-radius: 19px;
    padding: 20px;

    @media (max-width: 768px) {
        width: 90vw;
    }
`;

const ActionsContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 15px;
`;

const FilterButtonContainer = styled.div`
    position: relative;
`;

const AuctionHeaderContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    padding-top: 45px;
`;

const TitleContainer = styled.div`
    display: flex;
    align-items: flex-start;
`

const BatchsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 60px;
    align-items: center;
    justify-content: space-between;
`;

const FilterDropdownContainer = styled.div<{ show: boolean }>`
    position: absolute;
    top: 100%;  /* Coloca el filtro justo debajo del botón */
    left: 0;
    width: max-content;
    margin-top: 5px;
    z-index: 10;
    display: ${({ show }) => (show ? 'block' : 'none')};
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
`

const BatchListPage = () => {
    const { id } = useParams<{ id: string }>();
    const [showFilters, setShowFilters] = useState(false);
    const [data, setData] = useState<AuctionItemData[]>([]);
    const [auction, setAuction] = useState<Auction | null>(null);
    const [provinceName, setProvinceName] = useState<string>('');
    const [localityName, setLocalityName] = useState<string>('');
    const [isLoading, setIsLoading] = useState(true);
    const [filters, setFilters] = useState<any>({});
    const [selectedCategoriaFilter, setSelectedCategoriaFilter] = useState<string | undefined>("");
    const [selectedGarrapataFilter, setSelectedGarrapataFilter] = useState<string>("");
    const [isMioMioFilter, setIsMioMioFilter] = useState<string>(""); // Usar cadena vacía
    const [isGarrapataFilter, setIsGarrapataFilter] = useState<string>(""); // Usar cadena vacía
    const accessToken = localStorage.getItem('accessToken');

    const fetchProvinceName = async (provinceId: number) => {
        try {
            const response = await fetch(`${API_URL}/provincias/${provinceId}`, {
                method: 'GET',
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const responseData = await response.json();
            setProvinceName(responseData.nombre);
        } catch (e) {
            console.error("Error fetching province name:", e);
        }
    }

    const fetchLocalityName = async (localityId: number) => {
        try {
            const response = await fetch(`${API_URL}/localidades/${localityId}`, {
                method: 'GET',
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const responseData = await response.json();
            setLocalityName(responseData.nombre);
        } catch (e) {
            console.error("Error fetching locality name:", e);
        }
    }

    const fetchAuction = async () => {
        try {
            const response = await fetch(`${API_URL}/remates/${id}`, {
                method: 'GET',
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const responseData = await response.json();
            const auction: Auction = {
                id: responseData.id,
                nombre: responseData.nombre,
                cabezas: responseData.cabezas,
                fecha: responseData.fecha,
                localidad: responseData.localidad,
                lugar: responseData.lugar,
                provincia: responseData.provincia,
                fisico: responseData.fisico,
                streaming: responseData.streaming,
                tv: responseData.tv,
                tipo: responseData.tipo,
                url_foto: responseData.url_foto,
                url_streaming: responseData.url_streaming,
                catalogo_path: responseData.catalogo_path
            };

            setAuction(auction);
            await fetchProvinceName(responseData.provincia);
            await fetchLocalityName(responseData.localidad);
        } catch (e) {
            console.error("Error fetching auction:", e);
        }
    }

    const fetchBatchs = async () => {
        try {
            const response = await fetch(`${API_URL}/lotes/all/${id}`, {
                method: 'GET',
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const responseData = await response.json();
            console.log(responseData)

            const mappedData = [
                ...responseData.ordered_lotes.map((item: any) => ({
                    id: item.id,
                    lote: `Lote ${item.id}`,
                    nombre: item.nombre,
                    cert: item.id,
                    cantidad: item.cantidad,
                    tipo: item.lote_type,
                    location: item.location,
                    loteType: item.lote_type,
                    weight: `${item.peso_promedio} Kg`,
                    miomio: item.miomio,
                    garrapata: item.garrapata || [],
                    categoria: item.categoria || [],
                    foto: item.foto
                })),
                ...responseData.unordered_lotes.map((item: any) => ({
                    id: item.id,
                    lote: `Lote ${item.id}`,
                    nombre: item.nombre,
                    cert: item.id,
                    cantidad: item.cantidad,
                    tipo: item.lote_type,
                    location: item.location,
                    loteType: item.lote_type,
                    weight: `${item.peso_promedio} Kg`,
                    miomio: item.miomio,
                    garrapata: item.garrapata || [],
                    categoria: item.categoria || [],
                    foto: item.foto
                }))
            ];

            setData(mappedData);
        } catch (e) {
            console.error("Error fetching batchs:", e);
        }
    }

    const fetchData = async () => {
        setIsLoading(true);
        await fetchBatchs();
        await fetchAuction();
        setIsLoading(false);
    }

    useEffect(() => {
        fetchData();
    }, [accessToken, id]);

    const handleOnClick = () => {
        setShowFilters(!showFilters);
    };

    const navigation = useNavigate();

    // Opciones para los filtros
    const categorias = [
        { label: 'Todos', value: "" },
        { label: "Búfalas preñadas", value: "Búfalas preñadas" },
        { label: "Vacas c/cría al pie", value: "Vacas c/cría al pie" },
        { label: "Vacas c/gtía. preñez", value: "Vacas c/gtía. preñez" },
        { label: "Vaq. c/gtía. preñez", value: "Vaq. c/gtía. preñez" },
        { label: "Vaq. para Servicio", value: "Vaq. para Servicio" },
        { label: "Vientres Holando", value: "Vientres Holando" },
        { label: "Bubillas", value: "Bubillas" },
        { label: "Bubillos", value: "Bubillos" },
        { label: "Búfalos", value: "Búfalos" },
        { label: "Novillitos", value: "Novillitos" },
        { label: "Novillitos Holando", value: "Novillitos Holando" },
        { label: "Novillos", value: "Novillos" },
        { label: "Novillos Holando", value: "Novillos Holando" },
        { label: "Novillos y Vaquillonas", value: "Novillos y Vaquillonas" },
        { label: "Terneras", value: "Terneras" },
        { label: "Terneras Holando", value: "Terneras Holando" },
        { label: "Terneras a término", value: "Terneras a término" },
        { label: "Terneros", value: "Terneros" },
        { label: "Terneros Holando", value: "Terneros Holando" },
        { label: "Terneros a Término", value: "Terneros a Término" },
        { label: "Vacas de invernada", value: "Vacas de invernada" }  // Añadir más categorías según sea necesario
    ];

    const mioMioOptions = [
        { label: 'Todos', value: "" },
        { label: 'Si', value: "1" },
        { label: 'No', value: "0" }
    ];

    const garrapataOptions = [
        { label: 'Todos', value: "" },
        { label: "NO", value: "NO" },
        { label: "SI (SALEN SUCIA)", value: "SI (SALEN SUCIA)" },
        { label: "SI (SALEN LIMPIA)", value: "SI (SALEN LIMPIA)" }
    ];

    // Aplicar filtros
    const filteredData = data.filter(item => {
        const matchesMioMio = isMioMioFilter !== "" ? (isMioMioFilter === "1" ? item.miomio === true : item.miomio === false || item.miomio === undefined) : true;
        const matchesGarrapata = selectedGarrapataFilter !== "" ? item.garrapata.includes(selectedGarrapataFilter) : true;
        const matchesCategoria = selectedCategoriaFilter !== "" ? item.categoria.includes(selectedCategoriaFilter!) : true;
        return matchesMioMio && matchesGarrapata && matchesCategoria;
    });

    useEffect(() => {
        console.log(filteredData)
    }, [filteredData])

    const handleCatalogo = async () => {
        window.open(`${S3_URL}/${auction?.catalogo_path}`)
    }

    return (
        <>
            {accessToken ? (isMobile() ? <MobileNavBar /> : <SimpleNavbar showUserMenu={true} />) : <Navbar />}
            <BackgroundImage>
                <MainWrapper>
                    <ActionsContainer>
                        <FilterButtonContainer>
                            <PrimaryButtonComponent width='50' borderRadius={11} onClick={handleOnClick}> Filtros </PrimaryButtonComponent>
                            <FilterDropdownContainer show={showFilters}>
                                <Container>
                                    <BorderDropdownComponent marginBottom={10} width={359} color={'#697077'} hasError={false} placeholder={"Categoria"} options={categorias} onChange={(value) => setSelectedCategoriaFilter(value)}></BorderDropdownComponent>
                                    <BorderDropdownComponent marginBottom={10} width={359} color={'#697077'} hasError={false} placeholder={"Mio"} options={mioMioOptions} onChange={(value) => setIsMioMioFilter(value)}></BorderDropdownComponent>
                                    <BorderDropdownComponent marginBottom={10} width={359} color={'#697077'} hasError={false} placeholder={"Garrapata"} options={garrapataOptions} onChange={(value) => setSelectedGarrapataFilter(value)}></BorderDropdownComponent>
                                </Container>
                            </FilterDropdownContainer>
                        </FilterButtonContainer>
                        <PrimaryButtonComponent width='50' borderRadius={11} onClick={handleCatalogo}> Catalogo </PrimaryButtonComponent>
                        <IconButtonComponent onClick={() => navigation(-1)} />
                    </ActionsContainer>
                    <AuctionHeaderContainer>
                        {!isLoading && auction && (
                            <AuctionItemComponent
                                shouldClick={false}
                                type={auction.tipo}
                                backgroundColor={getAuctionColor(auction.tipo ?? '')}
                                whenDate={getDate(auction.fecha ?? '')}
                                whenHour={auction.fecha ? `${getHour(auction.fecha)} hs` : ''}
                                location={localityName + ', ' + provinceName}
                                inPerson={!!auction.fisico}
                                width='1100px'
                                marginTop='0'
                                videoUrl={auction.url_streaming}
                            />
                        )}
                    </AuctionHeaderContainer>
                    <TitleContainer><h2>{auction?.cabezas} cabezas</h2></TitleContainer>
                    <BatchsContainer>
                        {filteredData.map(item => (
                            <BatchItem key={item.id} item={item} />
                        ))}
                    </BatchsContainer>
                </MainWrapper>
            </BackgroundImage>
        </>
    );
}

export default BatchListPage;
