import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import companyLogo from '../../assets/Logo_Apaisado_Azul-removebg-preview 2.png';
import styled, { keyframes } from 'styled-components';
import { PRIMARY_COLOR, WHITE } from '../../constants/colors.constants';
import logoResponsive from '../../assets/Logo.png';
import { FaAngleRight } from 'react-icons/fa';

interface SimpleNavBarProps {
    showUserMenu: boolean;
  }
  
  const NavbarContainer = styled.nav`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: white;
    padding: 0.5rem 1rem;
    height: 5rem;
  
    @media (max-width: 768px) {
      flex-direction: row;
      height: auto;
      padding: 1rem;
    }
  `;

  const Logo = styled.img`
  height: 70px;
  width: auto;
  margin-right: 1rem;

  @media (max-width: 768px) {
    content: url(${logoResponsive});
    height: 40px;
  }`;

  const LogoContainer = styled.div`
    flex: 4;
    display: flex;
    justify-content: center;
  
    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
    }
  `;
  
  const ButtonContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 1;
  
    @media (max-width: 768px) {
      margin-top: 1rem;
    }
  `;
  
  const MenuButton = styled.button`
    background: ${WHITE};
    border-radius: 20px;
    padding: 8px;
    border: none;
    color: ${PRIMARY_COLOR};
    cursor: pointer;
    font-size: 1.5rem;

    @media (min-width: 769px) {
      display: none;
    }
  `;
  
  const slideDown = keyframes`
    from {
      transform: translateY(-100%);
    }
    to {
      transform: translateY(0);
    }
  `;
  
  const DropdownMenu = styled.div<{ isVisible: boolean }>`
    display: ${(props) => (props.isVisible ? 'block' : 'none')};
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #01354A;
    color: white;
    animation: ${slideDown} 0.3s ease-in-out;
    z-index: 999;
  
    @media (min-width: 769px) {
      display: none;
    }
  `;
  
  const MenuHeader = styled.div`
    display: flex;
    justify-content: flex-end;
    padding: 1rem;
  `;
  
  const CloseButton = styled.button`
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    font-size: 1.5rem;
  `;
  
  const MenuItem = styled.div<{showBorder?: boolean}>`
    padding: 2rem;
    text-align: left;
    cursor: pointer;
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: ${(props) => props.showBorder && `1px solid ${WHITE}`};
    margin-bottom: 30px;
    &:hover {
      background: #024c66;
    }
`;

    const StyledSpan = styled.a`
        font-size: 24px;
        font-weight: bold;
        text-decoration: none;
        color: unset;
    `;

    

export const LandingMobileNavBar = () => {
  const navigate = useNavigate();
  const [menuVisible, setMenuVisible] = useState(false);

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  const closeMenu = () => {
    setMenuVisible(false);
  };

  const [showAuctionOptions, setShowAuctionOptions] = useState(false);

  return (
    <>
      <NavbarContainer>
        <ButtonContainer>
            <>
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', padding: '0 10px 0 10px'}}>
                <Logo src={companyLogo} alt='company-logo' />
                <MenuButton onClick={() => {
                    setShowAuctionOptions(false);
                    toggleMenu();
                }}>☰</MenuButton>
              </div>
              <DropdownMenu isVisible={menuVisible}>
                <MenuHeader>
                  <CloseButton onClick={closeMenu}>✖</CloseButton>
                </MenuHeader>
                {
                    !showAuctionOptions ? (
                        <>
                            <MenuItem showBorder onClick={() => { closeMenu(); navigate('/auth/login'); }}>
                                <StyledSpan href={showAuctionOptions ? '#next' : ''}>Ingresar</StyledSpan>
                            </MenuItem>
                            <MenuItem showBorder onClick={() => { closeMenu(); }}>
                                <StyledSpan href={!showAuctionOptions ? '#about' : '#finished'}>Sobre Nosotros</StyledSpan>
                                <FaAngleRight size={30} />
                            </MenuItem>
                            <MenuItem showBorder onClick={() => { setShowAuctionOptions(true)}}>
                                <StyledSpan>Hacienda</StyledSpan>
                                <FaAngleRight size={30} />
                            </MenuItem>
                            <MenuItem onClick={() => { closeMenu();}}>
                                <StyledSpan href='#contact'>Contacto</StyledSpan>
                                <FaAngleRight size={30} />
                            </MenuItem>
                        </>
                    ) : (
                        <>
                            <MenuItem showBorder onClick={() => { closeMenu();}}>
                                <StyledSpan href='#next'>Próximos Remates</StyledSpan>
                                <FaAngleRight size={30} />
                            </MenuItem>
                            <MenuItem onClick={() => {closeMenu();}}>
                                <StyledSpan href="#finished">Remates Realizados</StyledSpan>
                                <FaAngleRight size={30} />
                            </MenuItem>
                        </>
                    )
                }
              </DropdownMenu>
            </>
        </ButtonContainer>
      </NavbarContainer>
    </>
  );
};
