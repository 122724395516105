import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import { PRIMARY_COLOR, WHITE } from '../../constants/colors.constants';
import Logo from '../../assets/Logo_Apaisado_Azul-removebg-preview 2.png';

interface SimpleNavBarProps {
    showUserMenu: boolean;
  }
  
  const NavbarContainer = styled.nav`
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-items: center;
    background: #01354A;
    color: white;
    height: 5rem;
  
    @media (max-width: 768px) {
      flex-direction: row;
      height: auto;
      padding: 1rem;
    }
  `;
  
  const ButtonContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 1;
  
    @media (max-width: 768px) {
    }
  `;
  
  const MenuButton = styled.button`
    background: ${WHITE};
    border-radius: 20px;
    border: none;
    color: ${PRIMARY_COLOR};
    cursor: pointer;
    font-size: 1.5rem;
  
    @media (min-width: 769px) {
      display: none;
    }
  `;
  
  const slideDown = keyframes`
    from {
      transform: translateY(-100%);
    }
    to {
      transform: translateY(0);
    }
  `;
  
  const DropdownMenu = styled.div<{ isVisible: boolean }>`
    display: ${(props) => (props.isVisible ? 'block' : 'none')};
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #01354A;
    color: white;
    animation: ${slideDown} 0.3s ease-in-out;
    z-index: 999;
  
    @media (min-width: 769px) {
      display: none;
    }
  `;
  
  const MenuHeader = styled.div`
    display: flex;
    justify-content: flex-end;
    padding: 1rem;
  `;
  
  const CloseButton = styled.button`
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    font-size: 1.5rem;
  `;
  
  const MenuItem = styled.div`
    padding: 1rem;
    text-align: center;
    cursor: pointer;
    border-bottom: 1px solid ${WHITE};
    margin-bottom: 30px;
    &:hover {
      background: #024c66;
    }
`;

    const StyledSpan = styled.span`
        font-size: 24px;
        font-weight: bold;
    `;

  const StyledLogo = styled.img`
    width: 200px;
    height: 52.5px;
  `;

export const MobileNavBar = () => {
  const navigate = useNavigate();
  const [menuVisible, setMenuVisible] = useState(false);

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  const closeMenu = () => {
    setMenuVisible(false);
  };

  return (
    <>
      <NavbarContainer>
        <StyledLogo src={Logo} onClick={() => navigate('/landing')} />
        <ButtonContainer>
            <>
              <MenuButton onClick={toggleMenu}>☰</MenuButton>
              <DropdownMenu isVisible={menuVisible}>
                <MenuHeader>
                  <CloseButton onClick={closeMenu}>✖</CloseButton>
                </MenuHeader>
                <MenuItem onClick={() => { closeMenu(); navigate('/next-auctions'); }}>
                    <StyledSpan>Próximos Remates</StyledSpan>
                </MenuItem>
                <MenuItem onClick={() => { closeMenu(); navigate('/finished-auctions'); }}>
                    <StyledSpan>Remates Realizados</StyledSpan>
                </MenuItem>
                <MenuItem onClick={() => { closeMenu(); navigate('/control-panel'); }}>
                    <StyledSpan>Panel de Control</StyledSpan>
                </MenuItem>
                <MenuItem onClick={() => { closeMenu(); navigate('/auctions/upload'); }}>
                    <StyledSpan>Cargar Remate</StyledSpan>
                </MenuItem>
                <MenuItem onClick={() => { closeMenu(); navigate('/auth/change-password'); }}>
                    <StyledSpan>Cambiar Contraseña</StyledSpan>
                </MenuItem>
                <MenuItem onClick={() => { closeMenu(); navigate('/logout'); }}>
                    <StyledSpan>Cerrar Sesión</StyledSpan>
                </MenuItem>
              </DropdownMenu>
            </>
        </ButtonContainer>
      </NavbarContainer>
    </>
  );
};
