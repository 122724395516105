import HeroPage from './HeroPage'
import NextAuctions from './NextAuctions'
import Footer from '../../components/shared/Footer'
import styled from 'styled-components'
import { AboutAndContactPage } from './AboutAndContactPage'

const Wrapper = styled.div`
`

const LandingPage = () => {
  return (
    <Wrapper>
        <HeroPage></HeroPage>
        <NextAuctions></NextAuctions>
        <AboutAndContactPage/>
        <Footer/>
    </Wrapper>

  )
}

export default LandingPage