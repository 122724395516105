export const darkenHexColor = (hex: string, percent: number) => {
    // Convert HEX to RGB
    let num = parseInt(hex.slice(1), 16);
    let r = (num >> 16) & 255;
    let g = (num >> 8) & 255;
    let b = num & 255;
  
    // Convert RGB to HSL
    r /= 255;
    g /= 255;
    b /= 255;
    let cmin = Math.min(r, g, b),
      cmax = Math.max(r, g, b),
      delta = cmax - cmin,
      h = 0,
      s = 0,
      l = 0;
  
    if (delta === 0) h = 0;
    else if (cmax === r) h = ((g - b) / delta) % 6;
    else if (cmax === g) h = (b - r) / delta + 2;
    else h = (r - g) / delta + 4;
  
    h = Math.round(h * 60);
  
    if (h < 0) h += 360;
  
    l = (cmax + cmin) / 2;
  
    s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
  
    // Darken the luminosity by the specified percentage
    l *= 1 - percent / 100;
  
    // Convert HSL back to RGB
    s *= 1 - percent / 100;
    delta = s * (1 - Math.abs(2 * l - 1));
  
    let hslToRgb = function (h:any, s:any, l:any) {
      let r, g, b;
  
      if (s === 0) {
        r = g = b = l; // achromatic
      } else {
        let hue2rgb = function hue2rgb(p:any, q:any, t:any) {
          if (t < 0) t += 1;
          if (t > 1) t -= 1;
          if (t < 1 / 6) return p + (q - p) * 6 * t;
          if (t < 1 / 2) return q;
          if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
          return p;
        };
  
        let q = l < 0.5 ? l * (1 + s) : l + s - l * s;
        let p = 2 * l - q;
        r = hue2rgb(p, q, h + 1 / 3);
        g = hue2rgb(p, q, h);
        b = hue2rgb(p, q, h - 1 / 3);
      }
  
      return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
    };
  
    let rgb = hslToRgb(h / 360, s, l);
  
    // Convert RGB to HEX
    let hexColor = "#" + ((1 << 24) + (rgb[0] << 16) + (rgb[1] << 8) + rgb[2]).toString(16).slice(1);
  
    return hexColor;
};

export const filterNextAuctions = (auctions: any) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0); // Establece la hora de hoy a 00:00:00
  
  return auctions.filter((remate: any) => {
    const remateDate = new Date(remate.fecha);
    remateDate.setHours(0, 0, 0, 0); // Establece la hora del remate a 00:00:00
    return remateDate >= today;
  });
}

export const filterFinishedAuctions = (auctions: any) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0); // Establece la hora de hoy a 00:00:00
  
  return auctions.filter((remate: any) => {
    const remateDate = new Date(remate.fecha);
    remateDate.setHours(0, 0, 0, 0); // Establece la hora del remate a 00:00:00
    return remateDate < today;
  });
}


export const isMobile = () => {
  return window.innerWidth < 768
}