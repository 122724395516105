import { PrimaryButtonComponent } from '../components/buttons/PrimaryButtonComponent'
import { FaTimes } from 'react-icons/fa';
import { PrimaryButtonWithIconComponent } from '../components/buttons/PrimaryButtonWithIconComponent';
import { SecondaryButtonComponent } from '../components/buttons/SecondaryButtonComponent';
import { SecondaryButtonWithIconComponent } from '../components/buttons/SecondaryButtonComponentWithIcon';
import { RectangularInputComponent } from '../components/form/inputs/RectangularInputComponent';
import { ChangeEvent, useState } from 'react';
import { RectangularPasswordInputComponent } from '../components/form/inputs/RectangularPasswordInputComponent';
import { SearchInputComponent } from '../components/form/inputs/SearchInputComponent';
import TertiaryButtonComponent from '../components/buttons/TertiaryButtonComponent';
import Navbar from '../components/shared/NavBar';
import Card from '../components/cards/Card';
import { IconButtonComponent } from '../components/buttons/IconButtonComponent';
import { LabelComponent } from '../components/form/basics/LabelComponent';
import { ForgetMyPasswordFooterComponent } from '../components/form/ForgetMyPasswordFooterComponent';
import CarouselComponent from '../components/carousel/CarouselComponent';
import { HeaderComponent } from '../components/common/HeaderComponent';
import { GOLDEN_COLOR, PLACEHOLDER_FONT_COLOR } from '../constants/colors.constants';
import { AuctionItemComponent } from '../components/auction/AuctionItemComponent';
import DropdownComponent from '../components/form/dropdowns/DropdownComponent';
import SingleImageCarousel from '../components/carousel/SingleImageCarousel';
import { SeleccionableButtonComponent } from '../components/buttons/SeleccionableButtonComponent';
import BorderDropdownComponent from '../components/form/dropdowns/BorderDropdownComponent';
import FileSelectorButtonComponent from '../components/buttons/FileSelectorButtonComponent';
import { DetailBatchModal } from './Batchs/modal/DetailBatchModal';


// Totalmente para el modo desarollo, es una pantalla para probar los componentes que estamos haciendo
export const PlaygroundPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleButtonClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '90vh', width:'100%', border:'1px solid green' }}>
      {/* <PrimaryButtonComponent onClick={() => console.log('click!')}>
            Primary button
        </PrimaryButtonComponent>

        <PrimaryButtonWithIconComponent icon={FaTimes} text='Primary button with icon'/> */}

      {/* <SecondaryButtonComponent onClick={() => console.log('click!')}>
          Secondary button
        </SecondaryButtonComponent>
        
        <SecondaryButtonWithIconComponent icon={FaTimes} text='Secondary button with icon'/> */}

      {/* <RectangularInputComponent
          label='Input label'
          placeholder='placeholder'
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />

        <RectangularPasswordInputComponent
          label='Input label'
          placeholder='placeholder'
          value={value}
          onChange={(e) => setValue(e.target.value)}
        /> */}

      {/* <SearchInputComponent 
        value={value}
        onChange={(e) => setValue(e.target.value)}
        placeholder="Buscar productos..."
      /> */}

      {/*<TertiaryButtonComponent onClick={() => console.log('click!')}> Ingresar </TertiaryButtonComponent>*/}

      {/* <Card
        onClick={() => console.log("")}
        title='Curuzú Cuatiá, Corrientes' 
        infoOne='Invernada, Faena y Cría' 
        infoTwo='Sociedad Rural de Curuzú Cuatiá' 
        button="primary" 
        img="ima" /> */}

        {/* <div style={{ width: 500 }}>

{/*         <div style={{ width: 500 }}>

          <ForgetMyPasswordFooterComponent
            labelCheckbox="Recordarme"
            isChecked={isChecked}
            toggleCheckbox={toggleCheckbox}
            redirectLabel='Olvidaste tu contraseña?'
            onRedirectClick={() => console.log('click!')}
          />
        </div> */}

{/*         <HeaderComponent title='Próximos Remates | Remates Realizados' subtitle='NOTICIAS. INFORMACIÓN.' />
 */}{/*       
        <div style={{width: 800}}>
      
        <div style={{width: 800}}>
          <AuctionItemComponent whenDate={'9 de Mayo'} whenHour={'14 hs'} location={'Curuzú Cuatiá, Corrientes'}  />
          <AuctionItemComponent 
            whenDate={'9 de Mayo'}
            whenHour={'14 hs'}
            location={'Curuzú Cuatiá, Corrientes'}
            backgroundColor={GOLDEN_COLOR}
          />

          <AuctionItemComponent 
            whenDate={'9 de Mayo'}
            whenHour={'14 hs'}
            location={'Curuzú Cuatiá, Corrientes'}
            inPerson
          />
        </div>

{/*         <DropdownComponent
          placeholder='Año de Búsqueda *'
          width={583}
          options={[
            '2010',
            '2011',
            '2012',
            '2013'
          ]}
        /> */}

        {/* <SingleImageCarousel/> */}

        {/* <SeleccionableButtonComponent
          borderRadius={40}
        >
          Físico
        </SeleccionableButtonComponent> */}
      
      {/* <BorderDropdownComponent width={359} color={PLACEHOLDER_FONT_COLOR} placeholder={"Provincia"} options={[
        'Buenos Aires',
        'Capital Federal'
      ]}  /> */}

      <div>
        <SecondaryButtonWithIconComponent onClick={handleButtonClick} icon={FaTimes} text='modal'/>
        {isModalOpen && <DetailBatchModal onClose={handleCloseModal} id={123} category='faena' />}
      </div>
    </div>
  )
}
