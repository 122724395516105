import styled from "styled-components";
import { BLACK_FONT, PRIMARY_COLOR, RED_COLOR, WHITE } from "../../constants/colors.constants";
import { IconButtonComponent } from "../../components/buttons/IconButtonComponent";
import { useNavigate } from "react-router-dom";
import LogoImg from '../../assets/logo-pag-principal.png';
import SeparatorComponent from "../../components/basics/SeparatorComponent";
import { RectangularPasswordInputComponent } from "../../components/form/inputs/RectangularPasswordInputComponent";
import { useState } from "react";
import { PrimaryButtonComponent } from "../../components/buttons/PrimaryButtonComponent";
import { API_URL } from "../../constants/config.constants";
import { isMobile } from "../../utils/utils";
import BackgroundImage from '../../assets/login-background.png';
import SimpleNavbar from "../../components/shared/SimpleNavBar";
import { MobileNavBar } from "../../components/shared/MobileNavBar";
import { fetchWithAuth } from "./AuthUtils";

const StyledContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 30px;
    padding: 40px;
    min-height: 100vh;
    box-sizing: border-box;

    background-image: url(${BackgroundImage});
    background-size: cover;        
    background-position: center;

    @media (max-width: 768px) {
        background-image: none;
    }
`;

const StyledForm = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${WHITE};
    height: 600px;
    width: 50%;
    border-radius: 19px;
    padding: 40px;

    @media (max-width: 768px) {
        padding: 5px;
        width: unset;
    }
`;

const StyledHeaderContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 768px) {
        justify-content: center;
    }
`;

const StyledHeaderTitle = styled.h2`
    color: ${BLACK_FONT};
    font-size: 42px;
    font-weight: 700;

    @media (max-width: 768px) {
        font-size: 24px;
    }
`;

const StyledContentContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 20px;
    flex: 1; /* Ensure the content container expands to fill the remaining space */
    justify-content: center;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

const StyledLogoAndDataContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 25px;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

const Logo = styled.img`
    width: 109px;
    height: 109px;
`;

const StyledDataContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 25px;
`;

const UsernameLabel = styled.span`
    color: ${PRIMARY_COLOR};
    font-size: 16px;
    font-weight: 600;
`;

const MailLabel = styled.span`
    color: ${PRIMARY_COLOR};
    font-size: 16px;
    font-weight: 300;
`;

const StyledFormContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const StyledErrorLabel = styled.span`
    color: ${RED_COLOR};
    font-size: 16px;
    font-weight: 300;
    width: 100%;
    text-align: left;
    margin-bottom: 20px;
`;

export const ChangePassword = () => {
    const navigate = useNavigate();

    const [password, setPassword] = useState('');
    const [repeatedPassword, setRepeatedPassword] = useState('');

    const [showPasswordError, setShowPasswordError] = useState(false);
    const accessToken = localStorage.getItem("accessToken");

    const handleChangePassword = async () => {
        setShowPasswordError(false);
        if(password !== repeatedPassword) {
            setShowPasswordError(true);
            return;
        }
        try {
            const response = await fetchWithAuth(`${API_URL}/users/change-password/`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    password
                })
            });
    
            if (!response.ok) {
                throw new Error('Network response was not ok ' + response.statusText);
            } else {
                navigate('/home');
            }
            const data = await response.json();
        } catch (error) {
            console.error('Failed to fetch:', error);
        }
    }

  return (
    <div style={{height: '100%'}}>
       {isMobile() ? <MobileNavBar/> : <SimpleNavbar showUserMenu={true} />}
    <StyledContainer>
        <StyledForm>
            <StyledHeaderContainer>
                <div></div>
                <StyledHeaderTitle>Cambiar Contraseña</StyledHeaderTitle>
                <IconButtonComponent size={40} onClick={() => navigate(-1)} />
            </StyledHeaderContainer>
            <StyledContentContainer>
                <StyledLogoAndDataContainer>
                    <Logo src={LogoImg} />
                    <StyledDataContainer>
                        <UsernameLabel>Nombre de Usuario</UsernameLabel>
                        <MailLabel>Mail</MailLabel>
                    </StyledDataContainer>
                </StyledLogoAndDataContainer>
                
                {!isMobile() && <SeparatorComponent />}

                <StyledFormContainer>
                    <RectangularPasswordInputComponent
                        label="Contraseña"
                        placeholder="Contraseña"
                        value={password}
                        onChange={(e) => setPassword(e.target.value) }
                        width={isMobile() ? 300 : 365}
                    />

                    <RectangularPasswordInputComponent
                        label="Repetir Contraseña"
                        placeholder="Contraseña"
                        value={repeatedPassword}
                        onChange={(e) => setRepeatedPassword(e.target.value) }
                        width={isMobile() ? 300 : 365}
                    />
                    
                    <StyledErrorLabel>{showPasswordError ? 'Las contraseñas no coinciden' : '‎'}</StyledErrorLabel>

                    <PrimaryButtonComponent onClick={() => handleChangePassword()} width="123px" borderRadius={8}>Guardar</PrimaryButtonComponent>
                </StyledFormContainer>

            </StyledContentContainer>
        </StyledForm>
    </StyledContainer>
    </div>
  )
}
