export const categories = [{ label: "Búfalas preñadas", value: "Búfalas preñadas" },
{ label: "Vacas c/cría al pie", value: "Vacas c/cría al pie" },
{ label: "Vacas c/gtía. preñez", value: "Vacas c/gtía. preñez" },
{ label: "Vaq. c/gtía. preñez", value: "Vaq. c/gtía. preñez" },
{ label: "Vaq. para Servicio", value: "Vaq. para Servicio" },
{ label: "Vientres Holando", value: "Vientres Holando" },
{ label: "Bubillas", value: "Bubillas" },
{ label: "Bubillos", value: "Bubillos" },
{ label: "Búfalos", value: "Búfalos" },
{ label: "Novillitos", value: "Novillitos" },
{ label: "Novillitos Holando", value: "Novillitos Holando" },
{ label: "Novillos", value: "Novillos" },
{ label: "Novillos Holando", value: "Novillos Holando" },
{ label: "Novillos y Vaquillonas", value: "Novillos y Vaquillonas" },
{ label: "Terneras", value: "Terneras" },
{ label: "Terneras Holando", value: "Terneras Holando" },
{ label: "Terneras a término", value: "Terneras a término" },
{ label: "Terneros", value: "Terneros" },
{ label: "Terneros Holando", value: "Terneros Holando" },
{ label: "Terneros a Término", value: "Terneros a Término" },
{ label: "Vacas de invernada", value: "Vacas de invernada" }];

export const formulary = [{
    label: 'Invernada',
    value: 'Invernada'
},{
    label: 'Faena',
    value: 'Faena'
},{
    label: 'Vientres',
    value: 'Vientres'
}];

export const faenaSubcategory = [
    { label: "Especial", value: "Especial" },
    { label: "Gordo/a", value: "Gordo/a" },
    { label: "Regular", value: "Regular" },
    { label: "Cons. Buena", value: "Cons. Buena" },
    { label: "Cons. Inferior", value: "Cons. Inferior" }
  ];

export const faenaDetention = [
    { label: "Hasta 2", value: "Hasta 2" },
    { label: "Más de 2", value: "Más de 2" },
    { label: "Hasta 4", value: "Hasta 4" },
    { label: "6", value: "6" },
    { label: "6 o más", value: "6 o más" },
    { label: "8 o más", value: "8 o más" }
  ];

export const faenaRaza = [
    { label: "Cuartinas", value: "Cuartinas" },
    { label: "Cuartinos", value: "Cuartinos" },
    { label: "Flieckvieh Simmental", value: "Flieckvieh Simmental" },
    { label: "Greyman", value: "Greyman" },
    { label: "Hampshire Down", value: "Hampshire Down" },
    { label: "Hereford", value: "Hereford" },
    { label: "Holando Argentino", value: "Holando Argentino" },
    { label: "Jersey", value: "Jersey" },
    { label: "KiwiCross", value: "KiwiCross" },
    { label: "Limangus", value: "Limangus" },
    { label: "Limflex", value: "Limflex" },
    { label: "Limousin", value: "Limousin" },
    { label: "Mediterranea", value: "Mediterranea" },
    { label: "Mestizas negras y coloradas", value: "Mestizas negras y coloradas" },
    { label: "Mestizos negros y colorados", value: "Mestizos negros y colorados" },
    { label: "Mestizos/as", value: "Mestizos/as" },
    { label: "Montbeliarde", value: "Montbeliarde" },
    { label: "Murrah", value: "Murrah" },
    { label: "Murray Grey", value: "Murray Grey" },
    { label: "Negros y Colorados", value: "Negros y Colorados" },
    { label: "Pampas Colorados", value: "Pampas Colorados" },
    { label: "Pampas negros", value: "Pampas negros" },
    { label: "Pampizada", value: "Pampizada" },
    { label: "Pardo Suizo", value: "Pardo Suizo" },
    { label: "Piemontese", value: "Piemontese" },
    { label: "Polled Hereford", value: "Polled Hereford" },
    { label: "Retinta", value: "Retinta" },
    { label: "San Ignacio", value: "San Ignacio" },
    { label: "Santa Gertrudis", value: "Santa Gertrudis" },
    { label: "Senangus", value: "Senangus" },
    { label: "Seneford", value: "Seneford" },
    { label: "Shorthorn", value: "Shorthorn" },
    { label: "Varias Razas (aclarar en obs.)", value: "Varias Razas (aclarar en obs.)" },
    { label: "West Highland y sus Cruzas", value: "West Highland y sus Cruzas" },
    { label: "Tuli", value: "Tuli" }
];

export const faenaTermination = [
    { label: "Campo", value: "Campo" },
    { label: "Campo + Ración", value: "Campo + Ración" },
    { label: "Campo + Suplemento", value: "Campo + Suplemento" },
    { label: "Feedlot", value: "Feedlot" },
    { label: "Isla", value: "Isla" },
    { label: "Otro", value: "Otro" },
    { label: "Sorgo", value: "Sorgo" },
    { label: "Pradera", value: "Pradera" }
  ];

export const faenaDestination = [
    { label: "Exp. UE. Apto HILTON", value: "Exp. UE. Apto HILTON" },
    { label: "Exp. UE. No HILTON", value: "Exp. UE. No HILTON" },
    { label: "Exp. No UE", value: "Exp. No UE" },
    { label: "Apto China", value: "Apto China" },
    { label: "No Apto China", value: "No Apto China" },
    { label: "Consumo", value: "Consumo" }
  ];

export const faenaWeighing = [
    { label: "Balanza Pública", value: "Balanza Pública" },
    { label: "En el Campo", value: "En el Campo" }
  ];

export const faenaCondicion = [
  { label: "La Carne a Levantarr", value: "La Carne a Levantar"},
  { label: "La Carne Puesto en Planta", value: "La Carne Puesto en Planta"},
  { label: "Kg vivo a Levantar", value: "Kg vivo a Levantar"},
  { label: "Kg vivo Puesto en Planta", value: "Kg vivo Puesto en Planta"}
]

export const invernadaRaza =  [
    { label: "Cuartinas", value: "Cuartinas" },
    { label: "Cuartinos", value: "Cuartinos" },
    { label: "Flieckvieh Simmental", value: "Flieckvieh Simmental" },
    { label: "Greyman", value: "Greyman" },
    { label: "Hampshire Down", value: "Hampshire Down" },
    { label: "Hereford", value: "Hereford" },
    { label: "Holando Argentino", value: "Holando Argentino" },
    { label: "Jersey", value: "Jersey" },
    { label: "KiwiCross", value: "KiwiCross" },
    { label: "Limangus", value: "Limangus" },
    { label: "Limflex", value: "Limflex" },
    { label: "Limousin", value: "Limousin" },
    { label: "Mediterranea", value: "Mediterranea" },
    { label: "Mestizas negras y coloradas", value: "Mestizas negras y coloradas" },
    { label: "Mestizos negros y colorados", value: "Mestizos negros y colorados" },
    { label: "Mestizos/as", value: "Mestizos/as" },
    { label: "Montbeliarde", value: "Montbeliarde" },
    { label: "Murrah", value: "Murrah" },
    { label: "Murray Grey", value: "Murray Grey" },
    { label: "Negros y Colorados", value: "Negros y Colorados" },
    { label: "Pampas Colorados", value: "Pampas Colorados" },
    { label: "Pampas negros", value: "Pampas negros" },
    { label: "Pampizada", value: "Pampizada" },
    { label: "Pardo Suizo", value: "Pardo Suizo" },
    { label: "Piemontese", value: "Piemontese" },
    { label: "Polled Hereford", value: "Polled Hereford" },
    { label: "Retinta", value: "Retinta" },
    { label: "San Ignacio", value: "San Ignacio" },
    { label: "Santa Gertrudis", value: "Santa Gertrudis" },
    { label: "Senangus", value: "Senangus" },
    { label: "Seneford", value: "Seneford" },
    { label: "Shorthorn", value: "Shorthorn" },
    { label: "Varias Razas (aclarar en obs.)", value: "Varias Razas (aclarar en obs.)" },
    { label: "West Highland y sus Cruzas", value: "West Highland y sus Cruzas" },
    { label: "Tuli", value: "Tuli" }
];

export const invernadaDescBatch = [
    { label: "Al pie de la madre", value: "Al pie de la madre" },
    { label: "Destetadas", value: "Destetadas" },
    { label: "Destetados", value: "Destetados" },
    { label: "Astadas", value: "Astadas" },
    { label: "Astados", value: "Astados" },
    { label: "Mochas", value: "Mochas" },
    { label: "Mochos", value: "Mochos" },
    { label: "Mochadas", value: "Mochadas" },
    { label: "Mochados", value: "Mochados" }
];

export const invernadaDetention = [
    { label: "Hasta 2", value: "Hasta 2" },
    { label: "Más de 2", value: "Más de 2" },
    { label: "Hasta 4", value: "Hasta 4" },
    { label: "6", value: "6" },
    { label: "6 o más", value: "6 o más" },
    { label: "8 o más", value: "8 o más" }
  ];
  
export const invernadaEstRepr = [
    { label: "Castrados", value: "Castrados" },
    { label: "Castrados y Enteros", value: "Castrados y Enteros" },
    { label: "Enteros", value: "Enteros" },
    { label: "-", value: "-" },
    { label: "Preñadas (eco)", value: "Preñadas (eco)" },
    { label: "Preñadas (tacto)", value: "Preñadas (tacto)" },
    { label: "Preñadas", value: "Preñadas" },
    { label: "Entoradas", value: "Entoradas" },
    { label: "Vacías (eco)", value: "Vacías (eco)" },
    { label: "Vacías (tacto)", value: "Vacías (tacto)" },
    { label: "Vacías Normal", value: "Vacías Normal" },
    { label: "Vacías", value: "Vacías" },
    { label: "Sin Servicio", value: "Sin Servicio" }
  ];

export const invernadaState = [
    { label: "Muy Bueno", value: "Muy Bueno" },
    { label: "Bueno-Muy Bueno", value: "Bueno-Muy Bueno" },
    { label: "Bueno", value: "Bueno" },
    { label: "Regular-Bueno", value: "Regular-Bueno" },
    { label: "Regular", value: "Regular" },
    { label: "Flaco", value: "Flaco" }
];

export const invernadaMioMio = [
    { label: 'SI', value: 'true'},
    { label: 'NO', value: 'false'}
]

export const invernadaGarrapata = [
    { label: "NO", value: "NO" },
    { label: "SI (SALEN SUCIA)", value: "SI (SALEN SUCIA)" },
    { label: "SI (SALEN LIMPIA)", value: "SI (SALEN LIMPIA)" }
  ];

export const invernadaSanity = [
    { label: "Ninguno", value: "Ninguno" },
    { label: "Completa", value: "Completa" },
    { label: "Completa y Libre Brucel. c/cert.", value: "Completa y Libre Brucel. c/cert." },
    { label: "Brucelosis c/Cert", value: "Brucelosis c/Cert" },
    { label: "Brucelosis S/Cert", value: "Brucelosis S/Cert" },
    { label: "Ofic. Libre (Brucelosis)", value: "Ofic. Libre (Brucelosis)" },
    { label: "Ofic. Libre (Tuberculosis)", value: "Ofic. Libre (Tuberculosis)" },
    { label: "Ofic. Libre (Brucel/Tuberc.)", value: "Ofic. Libre (Brucel/Tuberc.)" }
];

export const invernadaTratNutric = [
    { label: "Campo + ración", value: "Campo + ración" },
    { label: "Campo", value: "Campo" },
    { label: "Campo (sb. comer)", value: "Campo (sb. comer)" },
    { label: "Campo + supl.", value: "Campo + supl." },
    { label: "Feed Lot", value: "Feed Lot" },
    { label: "Ración", value: "Ración" },
    { label: "Pradera", value: "Pradera" },
    { label: "Pradera + ración", value: "Pradera + ración" },
    { label: "Pradera + supl.", value: "Pradera + supl." },
    { label: "Sorgo", value: "Sorgo" },
    { label: "Suplementados", value: "Suplementados" },
    { label: "Otro", value: "Otro" }
];

export const invernadaWeighing = [
    { label: "Balanza Pública", value: "Balanza Pública" },
    { label: "En el Campo", value: "En el Campo" }
  ];

export const invernadaDestination = [
    { label: "Invernada", value: "Invernada" },
    { label: "Inv. y Cria", value: "Inv. y Cria" },
    { label: "Cria", value: "Cria" },
    { label: "Faena", value: "Faena" }
];

export const invernadaPastoreo = [
    { label: 'SI', value: 'true'},
    { label: 'NO', value: 'false'}
]

export const vientresRaza = [
    { label: "Cuartinas", value: "Cuartinas" },
    { label: "Cuartinos", value: "Cuartinos" },
    { label: "Flieckvieh Simmental", value: "Flieckvieh Simmental" },
    { label: "Greyman", value: "Greyman" },
    { label: "Hampshire Down", value: "Hampshire Down" },
    { label: "Hereford", value: "Hereford" },
    { label: "Holando Argentino", value: "Holando Argentino" },
    { label: "Jersey", value: "Jersey" },
    { label: "KiwiCross", value: "KiwiCross" },
    { label: "Limangus", value: "Limangus" },
    { label: "Limflex", value: "Limflex" },
    { label: "Limousin", value: "Limousin" },
    { label: "Mediterranea", value: "Mediterranea" },
    { label: "Mestizas negras y coloradas", value: "Mestizas negras y coloradas" },
    { label: "Mestizos negros y colorados", value: "Mestizos negros y colorados" },
    { label: "Mestizos/as", value: "Mestizos/as" },
    { label: "Montbeliarde", value: "Montbeliarde" },
    { label: "Murrah", value: "Murrah" },
    { label: "Murray Grey", value: "Murray Grey" },
    { label: "Negros y Colorados", value: "Negros y Colorados" },
    { label: "Pampas Colorados", value: "Pampas Colorados" },
    { label: "Pampas negros", value: "Pampas negros" },
    { label: "Pampizada", value: "Pampizada" },
    { label: "Pardo Suizo", value: "Pardo Suizo" },
    { label: "Piemontese", value: "Piemontese" },
    { label: "Polled Hereford", value: "Polled Hereford" },
    { label: "Retinta", value: "Retinta" },
    { label: "San Ignacio", value: "San Ignacio" },
    { label: "Santa Gertrudis", value: "Santa Gertrudis" },
    { label: "Senangus", value: "Senangus" },
    { label: "Seneford", value: "Seneford" },
    { label: "Shorthorn", value: "Shorthorn" },
    { label: "Varias Razas (aclarar en obs.)", value: "Varias Razas (aclarar en obs.)" },
    { label: "West Highland y sus Cruzas", value: "West Highland y sus Cruzas" },
    { label: "Tuli", value: "Tuli" }
  ];

export const vientresDetention = [
    { label: "Hasta 2", value: "Hasta 2" },
    { label: "Más de 2", value: "Más de 2" },
    { label: "Hasta 4", value: "Hasta 4" },
    { label: "6", value: "6" },
    { label: "6 o más", value: "6 o más" },
    { label: "8 o más", value: "8 o más" }
];

export const vientresDescBatch = [
    { label: "Al pie de la madre", value: "Al pie de la madre" },
    { label: "Destetadas", value: "Destetadas" },
    { label: "Destetados", value: "Destetados" },
    { label: "Astadas", value: "Astadas" },
    { label: "Astados", value: "Astados" },
    { label: "Mochas", value: "Mochas" },
    { label: "Mochos", value: "Mochos" },
    { label: "Mochadas", value: "Mochadas" },
    { label: "Mochados", value: "Mochados" }
  ];

export const vientresEstRepr = [
    { label: "Castrados", value: "Castrados" },
    { label: "Castrados y Enteros", value: "Castrados y Enteros" },
    { label: "Enteros", value: "Enteros" },
    { label: "-", value: "-" },
    { label: "Preñadas (eco)", value: "Preñadas (eco)" },
    { label: "Preñadas (tacto)", value: "Preñadas (tacto)" },
    { label: "Preñadas", value: "Preñadas" },
    { label: "Entoradas", value: "Entoradas" },
    { label: "Vacías (eco)", value: "Vacías (eco)" },
    { label: "Vacías (tacto)", value: "Vacías (tacto)" },
    { label: "Vacías Normal", value: "Vacías Normal" },
    { label: "Vacías", value: "Vacías" },
    { label: "Sin Servicio", value: "Sin Servicio" }
  ];

export const vientresState = [
    { label: "Muy Bueno", value: "Muy Bueno" },
    { label: "Bueno-Muy Bueno", value: "Bueno-Muy Bueno" },
    { label: "Bueno", value: "Bueno" },
    { label: "Regular-Bueno", value: "Regular-Bueno" },
    { label: "Regular", value: "Regular" },
    { label: "Flaco", value: "Flaco" }
];

export const clase = [
    { label: "Muy Buena", value: "Muy Buena" },
    { label: "Buena-Muy Buena", value: "Buena-Muy Buena" },
    { label: "Buena", value: "Buena" },
    { label: "General-Buena", value: "General-Buena" },
    { label: "General", value: "General" }
];

export const vientresMioMio = [
    { label: 'SI', value: 'true'},
    { label: 'NO', value: 'false'}
]

export const vientresGarrapatas = [
    { label: "NO", value: "NO" },
    { label: "SI (SALEN SUCIA)", value: "SI (SALEN SUCIA)" },
    { label: "SI (SALEN LIMPIA)", value: "SI (SALEN LIMPIA)" }
  ];

export const vientresSanidad = [
    { label: "Ninguno", value: "Ninguno" },
    { label: "Completa y Libre Brucel. c/cert.", value: "Completa y Libre Brucel. c/cert." },
    { label: "Brucelosis c/Cert", value: "Brucelosis c/Cert" },
    { label: "Brucelosis S/Cert, Ofic. Libre (Brucelosis)", value: "Brucelosis S/Cert, Ofic. Libre (Brucelosis)" },
    { label: "Ofic. Libre (Tuberculosis)", value: "Ofic. Libre (Tuberculosis)" },
    { label: "Ofic. Libre (Brucel/Tuberc.)", value: "Ofic. Libre (Brucel/Tuberc.)" }
  ];


export const vientresTratNutric = [
    { label: "Campo + ración", value: "Campo + ración" },
    { label: "Campo", value: "Campo" },
    { label: "Campo (sb. comer)", value: "Campo (sb. comer)" },
    { label: "Campo + supl.", value: "Campo + supl." },
    { label: "Feed Lot", value: "Feed Lot" },
    { label: "Ración", value: "Ración" },
    { label: "Pradera", value: "Pradera" },
    { label: "Pradera + ración", value: "Pradera + ración" },
    { label: "Pradera + supl.", value: "Pradera + supl." },
    { label: "Sorgo", value: "Sorgo" },
    { label: "Suplementados", value: "Suplementados" },
    { label: "Otro", value: "Otro" }
  ];

export const vientresDestination = [
    { label: "Invernada", value: "Invernada" },
    { label: "Inv. y Cria", value: "Inv. y Cria" },
    { label: "Cria", value: "Cria" },
    { label: "Faena", value: "Faena" }
  ];

export const vientresPastoreo = [
    { label: 'SI', value: 'SI'},
    { label: 'NO', value: 'NO'}
]