import styled from "styled-components"
import { RED_COLOR } from "../../../constants/colors.constants";

interface LabelProps {
    text: string;
    color?: string;
    fontSize?: number;
    textAlign?: string;
    padding?: number;
}

const StyledLabel = styled.p<{ color: string, fontSize: number, textAlign?: string, padding?: number}>`
    color: ${(props) => props.color};
    font-size: ${(props) => props.fontSize}px;
    text-align: ${(props) => props.textAlign};
    padding-left: ${(props) => props.padding}px;
    margin: unset;
`;

export const LabelComponent = ({
    text,
    color = RED_COLOR,
    fontSize = 16,
    textAlign = 'left',
    padding = 0
}: LabelProps) => {
  return (
    <StyledLabel
        color={color}
        fontSize={fontSize}
        textAlign={textAlign}
        padding={padding}
    >
        {text}
    </StyledLabel>
  )
}
