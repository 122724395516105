import { PrefixType } from "../../../model/PrefixType";
import { fetchFileForUpload, uploadFileToS3 } from "../../../utils/UploadUtils";

export async function uploadImage(selectedImage: any, accessToken: string | null) {
    const uploadImageData = await fetchFileForUpload(selectedImage?.type, accessToken, PrefixType.IMAGE);
    const uploadImageUrl = uploadImageData.url;
    if (selectedImage) {
      await uploadFileToS3(uploadImageUrl, selectedImage, selectedImage?.type, PrefixType.IMAGE);
    }
    return uploadImageUrl;
  }
  
export async function uploadVideo(selectedVideo: any, accessToken: string | null) {
    const uploadVideoData = await fetchFileForUpload(selectedVideo?.type, accessToken, PrefixType.VIDEO);
    const uploadVideoUrl = uploadVideoData.url;
    if (selectedVideo) {
      await uploadFileToS3(uploadVideoUrl, selectedVideo, selectedVideo?.type, PrefixType.VIDEO);
    }
    return uploadVideoUrl;
  }
  
  
export const formatUrl = (url: string) => {
    return url.split('?')[0]
  }