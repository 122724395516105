import styled from "styled-components";
import { BLACK_FONT, PLACEHOLDER_FONT_COLOR, RECTANGULAR_INPUT_BACKGROUND_COLOR, RED_COLOR } from "../../../constants/colors.constants";
import { FaPlus, FaMinus } from "react-icons/fa"; // Importar íconos de react-icons

interface BorderInputProps {
    label?: string;
    value: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    placeholder?: string;
    width?: number;
    hasError?: boolean;
    marginBottom?: number;
    disabled?: boolean;
    type?: string;
    title?: string;
    onAddClick?: () => void; // Función callback para el botón "+"
    onRemoveClick?: () => void; // Función callback para el botón "-"
    isParent: boolean;
}

const InputContainer = styled.div<{marginBottom?: number, disabled?: boolean}>`
  display: flex;
  flex-direction: column;
  margin-bottom: ${(props) => props.marginBottom}px;
  pointer-events: ${(props) => props.disabled ? 'none' : 'unset'};
`;

const Label = styled.label`
  margin-bottom: 10px;
  text-align: left;
  color: ${BLACK_FONT};
  font-size: 14px;
  font-weight: bold;
  margin-left: 5px;
`;

const InputFieldWrapper = styled.div<{width?: number}>`
  display: flex;
  align-items: center;
  background-color: ${RECTANGULAR_INPUT_BACKGROUND_COLOR};
  border-radius: 19px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.4);
  width: ${(props) => props.width ? `${props.width + 30}px` : '640px'};
  
  @media (max-width: 768px) {
    width: ${(props) => props.width ? `${props.width + 30}px` : '379px'};
   }
`;

const InputField = styled.input<{width?: number, hasError?: boolean}>`
  padding: 16px;
  border: ${(props) => props.hasError ? `2px solid ${RED_COLOR}` : 'none'};
  border-radius: 19px;
  background-color: ${RECTANGULAR_INPUT_BACKGROUND_COLOR};
  width: ${(props) => props.width ? `${props.width - 60}px` : '460px'}; // Ajustar el ancho para incluir los botones
  height: 21px;
  outline: none;
  font-size: 16px;
  
  &::placeholder {
    color: ${PLACEHOLDER_FONT_COLOR};
  }
`;

const Button = styled.button`
  background: none;
  border: none;
  color: #0C354C; // Color del ícono
  font-size: 18px;
  cursor: pointer;
  padding: 8px;
  &:hover {
    color: #055A7B; // Color del ícono al hacer hover
  }
`;

export const BorderInputComponentsWithPlusAndMinus = ({
    label,
    value,
    onChange,
    width = 520,
    hasError = false,
    marginBottom = 20,
    disabled = false,
    type = "text",
    title,
    onAddClick,
    onRemoveClick,
    isParent,
    ...props
}: BorderInputProps) => {
  return (
    <InputContainer marginBottom={marginBottom} disabled={disabled}>
      <Label>{label}</Label>
      <InputFieldWrapper width={width}>
        <InputField 
          title={title}
          type={type}
          width={width}
          value={value}
          onChange={onChange}
          hasError={hasError}
          {...props} 
        />
        {isParent && <Button onClick={onAddClick}>
          <FaPlus />
        </Button>}
        {!isParent && <Button onClick={onRemoveClick}>
          <FaMinus />
        </Button>}
      </InputFieldWrapper>
    </InputContainer>
  );
};
