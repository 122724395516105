import styled from "styled-components"
import { FaArrowLeft } from 'react-icons/fa';
import { PRIMARY_COLOR } from "../../constants/colors.constants";
import { MouseEventHandler } from "react";

interface IconButtonProps {
    onClick?: MouseEventHandler<SVGElement> | undefined
    size?: number;
}

const StyledIcon = styled(FaArrowLeft)`
    color: ${PRIMARY_COLOR};
    cursor: pointer;

    @media (max-width: 768px) {
        display: none;
    }
`;

export const IconButtonComponent = ({ onClick, size = 25 }: IconButtonProps) => {
    return (
        <StyledIcon onClick={onClick} size={size} />
    )
}
