import styled from "styled-components";
import Logo from '../assets/Logo_Apaisado_Azul-removebg-preview 2.png';
import { PRIMARY_COLOR, WHITE } from "../constants/colors.constants";
import { FaAngleRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const StyledContainer = styled.div`
    background-color: ${PRIMARY_COLOR};
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
    overflow-y: scroll;
`;

const StyledHeaderContainer= styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    gap: 15px;
`;

const LogoImg = styled.img`
  max-width: 100%;
  margin-left: -60px;

  @media (max-width: 480px) {
    margin-left: unset;
    width: 304px;
    height: 72px;
  }
`;

const StyledHeaderTitle = styled.span`
  color: ${WHITE};
  font-size: 20px;
  font-weight: bold;
  font-style: italic;
`;

const StyledOptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const MenuItem = styled.div<{showBorder?: boolean}>`
padding: 2rem;
text-align: left;
cursor: pointer;
display:flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
border-bottom: ${(props) => props.showBorder && `1px solid ${WHITE}`};
margin-bottom: 30px;
&:hover {
  background: #024c66;
}
`;

const StyledSpan = styled.span`
    font-size: 24px;
    font-weight: bold;
    text-decoration: none;
    color: ${WHITE};
`;

export const ResponsiveHomePage = () => {
    const navigate = useNavigate();
    const username = localStorage.getItem('username');
  return (
    <StyledContainer>
        <StyledHeaderContainer>
            <LogoImg src={Logo} alt="Logo" />
            <StyledHeaderTitle>¡Hola, {username}!</StyledHeaderTitle>
        </StyledHeaderContainer>
        <StyledOptionsContainer>
            <MenuItem showBorder onClick={() => navigate('/next-auctions')}>
                <StyledSpan>Próximos Remates</StyledSpan>
                <FaAngleRight size={30} color={WHITE} />
            </MenuItem>
            <MenuItem showBorder onClick={() => navigate('/finished-auctions')}>
                <StyledSpan>Remates Finalizados</StyledSpan>
                <FaAngleRight size={30} color={WHITE} />
            </MenuItem>
            <MenuItem showBorder onClick={() => navigate('/control-panel')}>
                <StyledSpan>Panel de Control</StyledSpan>
                <FaAngleRight size={30} color={WHITE} />
            </MenuItem>
            <MenuItem showBorder onClick={() => navigate('/auctions/upload')}>
                <StyledSpan>Cargar Remate</StyledSpan>
                <FaAngleRight size={30} color={WHITE} />
            </MenuItem>
            <MenuItem showBorder onClick={() => navigate('/auth/change-password')}>
                <StyledSpan>Cambiar Contraseña</StyledSpan>
                <FaAngleRight size={30} color={WHITE} />
            </MenuItem>
            <MenuItem showBorder onClick={() => navigate('/logout')}>
                <StyledSpan>Cerrar Sesión</StyledSpan>
                <FaAngleRight size={30} color={WHITE} />
            </MenuItem>
        </StyledOptionsContainer>
    </StyledContainer>
  )
}
