import { useState } from "react";
import styled from "styled-components";
import { FaFileUpload } from "react-icons/fa";
import { BLACK_FONT, GRAY_COLOR, PLACEHOLDER_FONT_COLOR, PRIMARY_COLOR } from "../../constants/colors.constants";

const FileInput = styled.input`
  display: none;
  width: 359px;
  max-width: 359px;
  
`;

const FileInputLabel = styled.label<{backgroundColor?: string, color?: string, fontSize?: number }>`
  display: inline-block;
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.color};
  padding: 10px 20px;
  border-radius: 10px;
  font-size: ${(props) => props.fontSize}px;
  width: 100%;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.4);
`;

const FileNameLabel = styled.span`
  margin-left: 10px;
`;

const FileNameDisplay = styled.p`
  margin-top: 10px;
  overflow: hidden;
  white-space: nowrap;
  max-width: 30ch;
  text-overflow: ellipsis;
  color: ${BLACK_FONT};
  font-size: 14px;
`;

const generateUniqueId = () => `fileInput-${Math.random().toString(36).substr(2, 9)}`;

const FileSelectorButtonComponent = ({ label = 'Seleccionar Archivo', color = PLACEHOLDER_FONT_COLOR, fontSize = 16, backgroundColor = GRAY_COLOR ,width = 149, onFileSelect, inputId = generateUniqueId() }: any) => {
  const [selectedFile, setSelectedFile] = useState<any>(null);

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    if (onFileSelect) {
      onFileSelect(file);
    }
  };

  return (
    <div style={{ width }}>
      <FileInput type="file" id={inputId} onChange={handleFileChange} />
      <FileInputLabel htmlFor={inputId} backgroundColor={backgroundColor} color={color} fontSize={fontSize}>
        <FaFileUpload color={color} />
        <FileNameLabel>
          {label}
        </FileNameLabel>
      </FileInputLabel>
      {selectedFile && (
        <FileNameDisplay title={selectedFile.name}>{selectedFile.name}</FileNameDisplay>
      )}
    </div>
  );
};

export default FileSelectorButtonComponent;