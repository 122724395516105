import styled from "styled-components";
import { BLACK_FONT, WHITE } from "../../constants/colors.constants";
import { PrimaryButtonComponent } from "../buttons/PrimaryButtonComponent";
import { MouseEventHandler } from "react";

interface HomeCardProps {
    title: string;
    description: string;
    buttonText: string;
    onClick?: MouseEventHandler<HTMLButtonElement>;
}

const StyledCardContainer = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${WHITE};
    border-radius: 16px;
    padding: 20px;
    width: 349px;
    height: 171px;
    justify-content: space-between;
    margin: 80px;
`;

const StyledCardTitle = styled.p`
    color: ${BLACK_FONT};
    font-size: 20px;
    font-weight: 600;
    text-align: left;
    margin: unset;
`;

const StyledCardInfo = styled.p`
    color: ${BLACK_FONT};
    font-size: 16px;
    text-align: left;
`;

export const HomeCardComponent = ({
    title,
    description,
    buttonText,
    onClick
}: HomeCardProps) => {
  return (
    <StyledCardContainer>
        <StyledCardTitle>{title}</StyledCardTitle>
        <StyledCardInfo>{description}</StyledCardInfo>
        <PrimaryButtonComponent borderRadius={14} onClick={onClick}>{buttonText}</PrimaryButtonComponent>
    </StyledCardContainer>
  )
}
