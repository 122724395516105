import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState, AppThunk } from '../../app/Store';



export interface SessionState {
    accessToken: String | null
    refreshToken: String | null
}

const initialState: SessionState = {
    accessToken: null,
    refreshToken: null,
}


export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        logUserIn: (state, action: PayloadAction<{ accessToken: string; refreshToken: string }>) => {
            state.accessToken = action.payload.accessToken;
            state.refreshToken = action.payload.refreshToken;
        },
        logUserOut: (state) => {
            state.accessToken = null;
            state.refreshToken = null;
        },
        refreshTokens: (state, action: PayloadAction<{ accessToken: string; refreshToken: string }>) => {
            state.accessToken = action.payload.accessToken;
            state.refreshToken = action.payload.refreshToken;
        },
    },
})

export const { logUserIn, logUserOut, refreshTokens } = authSlice.actions;

export const selectAuth = (state: RootState) => state.auth

export const authUser = (accessToken: string, refreshToken: string): AppThunk => (dispatch) => {
    dispatch(logUserIn({ accessToken, refreshToken }));
};

export default authSlice.reducer