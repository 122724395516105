import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import CowsBackgroundImage from '../../assets/login-background.png';

import SimpleNavbar from '../../components/shared/SimpleNavBar';
import { GRAY_COLOR, GREEN_COLOR, GREEN_EXCEL, PLACEHOLDER_FONT_COLOR, PRIMARY_COLOR, WHITE } from '../../constants/colors.constants';
import { HeaderComponent } from '../../components/common/HeaderComponent';
import { BorderInputComponent } from '../../components/form/inputs/BorderInputComponent';
import BorderDropdownComponent from '../../components/form/dropdowns/BorderDropdownComponent';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { PrimaryButtonComponent } from '../../components/buttons/PrimaryButtonComponent';
import { useNavigate } from 'react-router-dom';
import { Batch } from '../../model/Batch';
import Principals, { Comitente } from '../Principals/Principals';
import { Province } from '../../model/Province';
import { Location } from "../../model/Location";
import { exportExcel, exportPdf, fetchAuctions, fetchBatchs, fetchLocations, fetchPrincipals, fetchProvinces, fetchUsers } from '../../api/ControlPanelAPI';
import { DropdownOption } from '../../model/DropdownOption';
import AvailableUsersPage from '../User/AvailableUsersPage';
import NewAccountPage from '../User/NewAccountPage';
import UploadPrincipal from '../Principals/UploadPrincipal';
import { DetailBatchModal } from '../Batchs/modal/DetailBatchModal';
import { EditBatchModal } from '../Batchs/modal/EditBatchModal';
import { SpinnerLoading } from '../../components/loadings/SpinnerLoading';
import { SpinnerForTables } from '../../components/loadings/SpinnerForTables';
import { IconButtonComponent } from '../../components/buttons/IconButtonComponent';
import { MobileNavBar } from '../../components/shared/MobileNavBar';
import { isMobile } from '../../utils/utils';
import { API_URL } from '../../constants/config.constants';
import EditAccountPage from '../User/EditAccountPage';
import EditPrincipal from '../Principals/EditPrincipal';
import { categories } from '../../constants/dropdowns/batchs/batchDataDropdown';
import { formatUrl, uploadVideo } from '../Batchs/forms/BatchFormUtils';
import FileSelectorButtonComponent from '../../components/buttons/FileSelectorButtonComponent';
import { FaFileUpload, FaPencilAlt } from 'react-icons/fa';
import { fetchWithAuth } from '../Auth/AuthUtils';


const BackgroundImage = styled.div`
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url(${CowsBackgroundImage});
    background-size: cover;
    @media (min-width: 800px) and (max-width: 1800px) {
        min-height: 110vh; 
    }
`;

const FormContainer = styled.div`
    height: 80vh;
    width: 90vw;
    background-color: ${WHITE};
    border-radius: 19px;
    padding: 20px;
    @media (max-width: 769px) {
        overflow-y: scroll;
        width: 85vw;
    }

    @media (min-width: 800px) and (max-width: 1800px) {
        min-height: 100vh; 
    }
`;

const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;
    padding-top: 20px;
`;

const ButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 60px;
    @media (max-width: 769px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
`;

const InputRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
    @media (max-width: 769px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

`;

const ButtonActionsContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 60px;

    @media (max-width: 769px) {
        display: flex;
        justify-content: center;
        align-items: center;
    }
`

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: 12px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 200px;
    height: 100px;
    cursor: pointer;
    transition: box-shadow 0.3s ease-in-out;

    &:hover {
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
    }
    @media (max-width: 769px) {
        width: 100px;
        height: 50px;
    }
`;


const IconContainer = styled.div`
    margin-bottom: 10px;
    color: #003366;
`;

const Text = styled.span`
    color: #003366;
    font-size: 18px;
    font-weight: bold;
`;

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
`;

const TableContainer = styled.div`
    width: 92%;
    padding: 20px;
    @media (max-width: 769px) {
        width: 100%;
    }
    
    @media (min-width: 770px) and (max-width: 1662px) {
        max-height: 210px;
        overflow-y: scroll;
    }
`;

const Tabs = styled.div`
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
    flex: 1;
`;

const Tab = styled.div<{ selected?: boolean }>`
    font-size: 18px;
    font-weight: bold;
    color: ${({ selected }) => (selected ? `${PRIMARY_COLOR}` : '#777')};
    border-bottom: ${({ selected }) => (selected ? `2px solid ${PRIMARY_COLOR}` : 'none')};
    cursor: pointer;
`;

const HeaderActions = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-bottom: 20px;
    flex: 2;
`;

const HeaderButton = styled.button`
    background-color: white;
    color: ${PRIMARY_COLOR};
    border: none;
    border-radius: 20px;
    padding: 10px 20px;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
    &:hover {
        background-color: #f0f0f0;
    }
`;

const HeaderButtonExcel = styled.button`
    background-color: ${GREEN_EXCEL};
    color: ${WHITE};
    border-radius: 20px;
    border: none;
    padding: 10px 20px;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);

    &:hover {
        background-color: #0F6F07;
    }
`;

const Table = styled.div`
    width: 100%;
    border-collapse: collapse;
    background-color: white;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    @media (max-width: 769px) {

    }
`;

const TableHeader = styled.div`
    display: flex;
    background-color: ${PRIMARY_COLOR};
    color: white;
    padding: 10px;
    @media (max-width: 769px) {
        overflow-x: scroll;
        gap: 15px
    }
`;

const TableHeaderItem = styled.div`
    flex: 1;
    text-align: center;
`;

const TableBody = styled.div`
    display: flex;
    flex-direction: column;
    max-height: 210px;
    overflow-y: scroll;

`;

const TableRow = styled.div`
    display: flex;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #f0f0f0;
    cursor: pointer;

    &:hover {
        background-color: ${GRAY_COLOR};
    }

    &:nth-child(even) {
        background-color: #f9f9f9;
    }
`;

const TableData = styled.div`
    flex: 1;
    text-align: center;
    padding: 10px;
`;

const ActionButton = styled.button<{ color?: string }>`
    background-color: ${({ color }) => color || '#ddd'};
    color: white;
    border: none;
    border-radius: 18px;
    padding: 10px;
    margin: 5px;
    font-size: 12px;
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: ${({ color }) => color ? darken(0.1, color) : '#ccc'};
    }
`;

const StatusBadge = styled.div<{ color?: string }>`
    background-color: ${({ color }) => color || '#ddd'};
    color: white;
    border-radius: 12px;
    padding: 5px 5px;
    display: flex;
    align-items: center;
    gap: 5px;
    justify-content: center;
`;

const TabsButtonsContainer = styled.div`
    display: flex;
    align-items: center;
    @media (max-width: 769px) {
        flex-direction: column;
    }
`;

function darken(amount: number, color: string) {
    // This function darkens the given color by the specified amount
    const f = parseInt(color.slice(1), 16);
    const t = amount < 0 ? 0 : 255;
    const p = amount < 0 ? amount * -1 : amount;
    const R = f >> 16;
    const G = f >> 8 & 0x00FF;
    const B = f & 0x0000FF;
    return '#' + (
        0x1000000 +
        (Math.round((t - R) * p) + R) * 0x10000 +
        (Math.round((t - G) * p) + G) * 0x100 +
        (Math.round((t - B) * p) + B)
    ).toString(16).slice(1).toUpperCase();
}

const ControlPanelPage: React.FC = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [selectedTab, setSelectedTab] = useState<'Cargados' | 'Publicados'>('Cargados');
    const [batchs, setBatchs] = useState<Batch[]>([]);
    const [comitentes, setComitentes] = useState<Comitente[]>([]);
    const [locations, setLocations] = useState<Location[]>([]);
    const [provinces, setProvinces] = useState<Province[]>([]);
    const emptyAuctionOption: DropdownOption = { label: "Seleccionar Remate", value: "" };
    const emptyPrincipalOption: DropdownOption = { label: "Seleccionar Comitente", value: "" };
    const emptyFormTypeOption: DropdownOption = { label: "Seleccionar Formulario", value: "" };
    const emptyUserOption: DropdownOption = { label: "Seleccionar Usuario", value: "" };
    const emptyCategoryOption: DropdownOption = { label: "Seleccionar Categoria", value: "" };
    const [auctions, setAuctions] = useState<DropdownOption[]>([emptyAuctionOption]);
    const [principalDropdown, setPrincipalsDropdown] = useState<DropdownOption[]>([emptyPrincipalOption]);
    const [selectedAuction, setSelectedAuction] = useState<string | number | undefined>(emptyAuctionOption.value);
    const [selectedFormType, setSelectedFormType] = useState<string | number | undefined>();
    const [selectedUser, setSelectedUser] = useState<string | number | undefined>();
    const [selectedPrincipal, setSelectedPrincipal] = useState<string | number | undefined>(emptyPrincipalOption.value);
    const [search, setSearch] = useState<string>("");
    const [isUsersModalOpen, setUsersModalOpen] = useState(false);
    const [isNewAccountModalOpen, setNewAccountModalOpen] = useState(false);
    const [isEditAccountModalOpen, setIsEditAccountModalOpen] = useState(false);
    const [isEditPrincipalModalOpen, setIsEditPrincipalModalOpen] = useState(false);
    const [isPrincipalsOpen, setIsPrincipalsOpen] = useState(false);
    const [isNewPrincipalModalOpen, setNewPrincipalModalOpen] = useState(false);
    const [rawAuctions, setRawAuctions] = useState([]);
    const [selectedUserForEdition, setSelectedUserForEdition] = useState<any>();
    const [users, setUsers] = useState<DropdownOption[]>([emptyUserOption]);
    const navigate = useNavigate();

    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [categoriesOptions, setCategoriesOptions] = useState<DropdownOption[]>([emptyCategoryOption]);
    const [selectedCategory, setSelectedCategory] = useState<any>(emptyCategoryOption.value);
    const [selectedVideo, setSelectedVideo] = useState<any>()
    const [isVideoSelected, setIsVideoSelected] = useState<boolean>(false)

    const handleButtonClick = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleEditButtonClick = () => {
        setIsEditModalOpen(true);
    };

    const handleEditCloseModal = () => {
        setIsEditModalOpen(false);
    };

    useEffect(() => {
        setIsLoading(true);
        const initializeData = async () => {
            const fetchedLocations = await fetchLocations();
            const fetchedProvinces = await fetchProvinces();
            setLocations(fetchedLocations);
            setProvinces(fetchedProvinces);
        };
        initializeData();
    }, []);

    useEffect(() => {
        const initializeBatchsAndPrincipals = async () => {
            const fetchedBatchs = await fetchBatchs();
            const fetchedPrincipals = await fetchPrincipals(provinces, locations);
            const fetchedAuctions = await fetchAuctions(provinces, locations);
            const fetchedUsers = await fetchUsers();

            
            setUsers([
                emptyUserOption,
                ...fetchedUsers.map((user: any) => ({
                    label: user.username,
                    value: user.id
                }))
            ])

            setCategoriesOptions([
                emptyCategoryOption,
                ...categories
            ])

            setAuctions([
                emptyAuctionOption,
                ...fetchedAuctions.map((auction: any) => ({
                    label: auction.title,
                    value: auction.id
                }))
            ]);
            setPrincipalsDropdown([
                emptyPrincipalOption,
                ...fetchedPrincipals.map((principal: any) => ({
                    label: principal.nombre,
                    value: principal.id
                }))
            ])

            setRawAuctions(fetchedAuctions);
            setBatchs(fetchedBatchs);
            setIsLoading(false);
            setComitentes(fetchedPrincipals);
            console.log(batchs)
        };
        if (locations.length > 0 && provinces.length > 0) {
            initializeBatchsAndPrincipals();
        }
    }, [locations, provinces]);

    const handleNavigate = (route: String) => {
        return () => {
            navigate(route.toString());
        };
    }

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(e.target.value);
    }

    const handleNewBatch = () => {
        if (selectedAuction && selectedAuction !== "") {
            navigate(`/batches/upload/${selectedAuction}`);
        }
    };

    const handleOpenUsersModal = () => {
        setUsersModalOpen(true);
    };


    const handleCloseUsersModal = () => {
        setUsersModalOpen(false);
    };

    const handleOpenPrincipalModal = () => {
        setIsPrincipalsOpen(true)
    };

    const handleClosePrincipalModal = () => {
        setIsPrincipalsOpen(false)
    };

    const handleOpenNewAccountModal = () => {
        setNewAccountModalOpen(true);
    };

    const handleOpenNewPrincipalModal = () => {
        setNewPrincipalModalOpen(true)
    };

    const handleCloseNewAccountModal = () => {
        setNewAccountModalOpen(false);
    };

    const handleOpenEditAccountModal = (id: any) => {
        setSelectedUserForEdition(id);
        setIsEditAccountModalOpen(true);
    };

    const handleOpenEditPrincipalModal = (id: any) => {
        setSelectedPrincipal(id);
        setIsEditPrincipalModalOpen(true);
    };

    const handleCloseEditAccountModal = () => {
        setIsEditAccountModalOpen(false);
    };

    const handleCloseEditPrincipalModal = () => {
        setIsEditPrincipalModalOpen(false);
    };

    const handleCloseNewPrincipalModal = () => {
        setNewPrincipalModalOpen(false);
    };

    const filteredBatchs = selectedTab === 'Cargados'
        ? batchs.filter(batch => !batch.publicado)
        : batchs.filter(batch => batch.publicado);

    const displayedBatchs = filteredBatchs.filter(batch => {
        const matchesFormType = selectedFormType && selectedFormType !== "" ? batch.formType === selectedFormType : true;
        const matchesAuction = selectedAuction && selectedAuction !== "" ? batch.remate === selectedAuction : true;
        const matchesPrincipal = selectedPrincipal && selectedPrincipal !== "" ? batch.comitente === selectedPrincipal : true;
        const matchesCertificate = search ? batch.id.toString().includes(search) : true;
        const matchesUser = selectedUser && selectedUser !== "" ? batch.usuario === selectedUser : true;
        const matchCategory = selectedCategory && selectedCategory !== "" ? batch.categoria.includes(selectedCategory) : true;
        return matchesAuction && matchesPrincipal && matchesCertificate && matchesFormType && matchesUser && matchCategory;
    });

    const getAuctionDate = (id: number) => {
        const rawAuction: any = rawAuctions?.filter((auction: any) => auction.id === id)[0]
        return rawAuction.fecha;
    }

    const getAuction = (id: number) => {
        return rawAuctions?.filter((auction: any) => auction.id === id)[0]
    }


    const getLocationAndProvince = (id: number) => {
        const auction: any = getAuction(id)
        const location: any = locations.filter((location): any => location.id === auction.location)[0]
        const province: any = provinces.filter((province): any => province.id === auction.province)[0]
        return `${location.nombre},\n${province.nombre}`
    }

    const accessToken = localStorage.getItem('accessToken');
    const changeBatchStatus = async (batch: any) => {
        setIsLoading(true);
        try {
            const response = await fetchWithAuth(`${API_URL}/lotes/${batch.formType}/${batch.id}/`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                },
                body: JSON.stringify({
                    ...batch,
                    publicado: !batch.publicado
                }), 
            });

            if (response.ok) {
                window.location.reload();
                setIsLoading(false);
            } else {
                console.log('error')
                setIsLoading(false);
            }

        } catch (error) {
            setIsLoading(false);
        }
    }

    const handleExportExcel = async () => {
        await exportExcel(selectedAuction);
    }
    const handleExportPDF = async () => {
        await exportPdf(selectedAuction, true, true);
    }
    const handleEditVideo = async (videoUrl: any, batchId: number, auctionId: number) => {
        const response = await fetchWithAuth(videoUrl);
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `lote-${batchId}-remate-${auctionId}-video.mp4`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
    };

    const handleUploadEditedVideo = async (batch: any) => {
        const videoUrl = await uploadVideo(selectedVideo, accessToken);
        setIsLoading(true);
        try {
            const response = await fetchWithAuth(`${API_URL}/lotes/${batch.formType}/${batch.id}/`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                },
                body: JSON.stringify({
                    ...batch,
                    video: formatUrl(videoUrl)
                }), 
            });

            if (response.ok) {
                window.location.reload();
                setIsLoading(false);
            } else {
                console.log('error')
                setIsLoading(false);
            }

        } catch (error) {
            setIsLoading(false);
        }
    }

    const handleVideoSelect = (file: any) => {
        setSelectedVideo(file);
        setIsVideoSelected(true);
      };

    return (
        <div style={{ height: '100%' }}>
            {isMobile() ? <MobileNavBar /> : <SimpleNavbar showUserMenu={true} />}
            <BackgroundImage>
                {!isUsersModalOpen && !isNewAccountModalOpen && !isNewPrincipalModalOpen && !isPrincipalsOpen && !isEditAccountModalOpen && (
                    <FormContainer>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div></div>
                            <HeaderComponent title='Panel de Control' subtitle='' />
                            <IconButtonComponent onClick={() => navigate(-1)} />
                        </div>
                        <InputContainer>
                            <InputRow>
                                <BorderDropdownComponent
                                    width={359}
                                    color={PLACEHOLDER_FONT_COLOR}
                                    hasError={false}
                                    placeholder={"Seleccionar Remate"}
                                    options={auctions}
                                    disabled={false}
                                    onChange={(value) => setSelectedAuction(value)}
                                />
                                <BorderDropdownComponent
                                    width={359}
                                    color={PLACEHOLDER_FONT_COLOR}
                                    hasError={false}
                                    placeholder={"Categorias"}
                                    options={categoriesOptions}
                                    disabled={false}
                                    onChange={(value) => {
                                        console.log(value)
                                        setSelectedCategory(value)}
                                    }
                                        
                                />
                                <BorderDropdownComponent
                                    width={359}
                                    color={PLACEHOLDER_FONT_COLOR}
                                    hasError={false}
                                    placeholder={"Comitente"}
                                    options={principalDropdown}
                                    disabled={false}
                                    onChange={(value) => setSelectedPrincipal(value)}
                                />
                            </InputRow>
                            <InputRow>
                                <BorderDropdownComponent
                                    width={359}
                                    color={PLACEHOLDER_FONT_COLOR}
                                    hasError={false}
                                    placeholder={"Formulario"}
                                    options={[emptyFormTypeOption, { label: "faena", value: "faena" }, { label: "invernada", value: "invernada" }, { label: "vientre", value: "vientre" }]}
                                    disabled={false}
                                    onChange={(value) => setSelectedFormType(value)}
                                />
                                <BorderDropdownComponent
                                    width={359}
                                    color={PLACEHOLDER_FONT_COLOR}
                                    hasError={false}
                                    placeholder={"Usuario"}
                                    options={users}
                                    disabled={false}
                                    onChange={(value) => setSelectedUser(value)}
                                />
                                <BorderInputComponent
                                    placeholder='Nro Certificado'
                                    value={search}
                                    onChange={handleSearch}
                                    width={325}
                                    hasError={false}
                                    marginBottom={30}
                                />
                            </InputRow>
                        </InputContainer>
                        <ButtonWrapper>
                            <ButtonContainer>
                                <Text>Total</Text>
                                <Text>Lotes</Text>
                                <Text>Cabezas</Text>
                            </ButtonContainer>
                            <ButtonActionsContainer>
                                <ButtonContainer onClick={handleOpenUsersModal}>
                                    <IconContainer>
                                        <AccountCircleIcon />
                                    </IconContainer>
                                    <Text>Usuarios Habilitados</Text>
                                </ButtonContainer>
                                <ButtonContainer onClick={handleOpenPrincipalModal}>
                                    <IconContainer>
                                        <AccountCircleIcon />
                                    </IconContainer>
                                    <Text>Comitentes</Text>
                                </ButtonContainer>
                            </ButtonActionsContainer>
                            <PrimaryButtonComponent
                                width='50'
                                borderRadius={12}
                                disabled={selectedAuction === "" || !selectedAuction}
                                onClick={handleNewBatch}
                            >
                                Nuevo lote
                            </PrimaryButtonComponent>
                        </ButtonWrapper>
                        <TableWrapper>
                            <TableContainer>
                                <TabsButtonsContainer>
                                    <Tabs>
                                        <Tab selected={selectedTab === 'Cargados'} onClick={() => setSelectedTab('Cargados')}>Cargados</Tab>
                                        <Tab selected={selectedTab === 'Publicados'} onClick={() => setSelectedTab('Publicados')}>Publicados</Tab>
                                    </Tabs>
                                    <HeaderActions>
                                        <HeaderButtonExcel onClick={handleExportExcel}>Exportar Excel para Edición</HeaderButtonExcel>
                                        <HeaderButton onClick={handleExportPDF}>Exportar PDF</HeaderButton>
                                    </HeaderActions>
                                </TabsButtonsContainer>
                                <Table>
                                    <TableHeader>
                                        <TableHeaderItem>Operador/Comitente</TableHeaderItem>
                                        <TableHeaderItem>Acciones</TableHeaderItem>
                                        <TableHeaderItem>Estado</TableHeaderItem>
                                        <TableHeaderItem>Categoria</TableHeaderItem>
                                        <TableHeaderItem>Cant./Peso Prom.</TableHeaderItem>
                                        <TableHeaderItem>Identificación</TableHeaderItem>
                                        <TableHeaderItem>Localidad/Provincia</TableHeaderItem>
                                        <TableHeaderItem>Fecha</TableHeaderItem>
                                    </TableHeader>
                                    <TableBody>
                                        {isLoading && <SpinnerForTables />}
                                        {!isLoading && displayedBatchs.map((batch) => {
                                            return (

                                                <div>
                                                    <TableRow key={batch.id}>
                                                        <TableData onClick={() => handleButtonClick()}>{comitentes.find((com: any) => com.id === batch.comitente)?.nombre || 'Desconocido'}</TableData>
                                                        <TableData>
                                                            <ActionButton color="#AE9304" onClick={() => handleEditButtonClick()}><FaPencilAlt /> Editar Lote</ActionButton>
                                                            <ActionButton color="#AE9304" onClick={() => handleEditVideo(batch.video, batch.id, batch.remate)}><FaPencilAlt /> Editar Video</ActionButton>
                                                            <div style={{marginTop: 15}}>
                                                            <FileSelectorButtonComponent fontSize={12} label={'Cargar Video'} width={isMobile() ? 200 : 100} onFileSelect={handleVideoSelect} inputId="fileInputVideo" />
                                                            {isVideoSelected && <ActionButton color="#B77616"  onClick={() => handleUploadEditedVideo(batch)}><FaFileUpload /> Subir Video Editado</ActionButton>}

                                                            </div>
                                                        </TableData>
                                                        <TableData>
                                                            <StatusBadge  color={batch.publicado ? "#4CAF50" : "#f44336"}>{batch.publicado ? 'Publicado' : 'Cargado'}</StatusBadge>
                                                            <ActionButton color={PRIMARY_COLOR} onClick={() => changeBatchStatus(batch)}>Cambiar Estado</ActionButton>
                                                            </TableData>
                                                        <TableData>{batch.categoria}</TableData>
                                                        <TableData>{batch.cantidad} / {batch.peso_promedio} Kg.</TableData>
                                                        <TableData>Videos Cargados</TableData>
                                                        {locations && provinces && <TableData>{getLocationAndProvince(batch.remate)}</TableData>}
                                                        <TableData>{new Date(getAuctionDate(batch.remate)).toLocaleDateString()}</TableData>
                                                    </TableRow>
                                                    {isModalOpen && <DetailBatchModal onClose={handleCloseModal} id={batch.id} category={batch.formType} />}
                                                    {isEditModalOpen && <EditBatchModal onClose={handleEditCloseModal} id={batch.id} category={batch.formType} />}
                                                </div>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </TableWrapper>
                    </FormContainer>
                )}
            </BackgroundImage>
            <AvailableUsersPage
                open={isUsersModalOpen}
                onClose={handleCloseUsersModal}
                onAddUser={handleOpenNewAccountModal}
                onEditUser={handleOpenEditAccountModal}
            />
            <NewAccountPage open={isNewAccountModalOpen} onClose={handleCloseNewAccountModal} />
            <EditAccountPage open={isEditAccountModalOpen} onClose={handleCloseEditAccountModal} id={selectedUserForEdition} />
            <Principals open={isPrincipalsOpen}
                onClose={handleClosePrincipalModal}
                onAddPrincipal={handleOpenNewPrincipalModal}
                onEditPrincipal={handleOpenEditPrincipalModal} />

            <UploadPrincipal open={isNewPrincipalModalOpen} onClose={handleCloseNewPrincipalModal} />
            <EditPrincipal open={isEditPrincipalModalOpen} onClose={handleCloseEditPrincipalModal} id={selectedPrincipal} />
        </div>
    );
};

export default ControlPanelPage;