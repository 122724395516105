import React, { useState } from 'react';
import styled from 'styled-components';
import { useLogOut } from '../../pages/Auth/AuthUtils';
import { useSelector } from 'react-redux';
import { RootState } from '../../app/Store';
import { useNavigate } from 'react-router-dom';

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  flex: 1;
  justify-content: flex-end;
`;

const ProfileIcon = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
`;

const UserName = styled.div`
  color: white;
  font-size: 1rem;
  margin-right: 10px;
`;

const DropdownMenu = styled.div<{ visible: boolean }>`
  position: absolute;
  top: 60px;
  right: 0;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: ${props => (props.visible ? 'flex' : 'none')};
  z-index: 1000;
  width: 215px;
/*   display: flex;*/
  flex-direction: column;
  align-items: center;
  justify-content: center; 
`;

const DropdownItem = styled.button`
  display: block;
  width: 100%;
  padding: 10px;
  background: none;
  border: none;
  text-align: left;
  cursor: pointer;
  font-size: 1rem;
  border-radius: 16px;;
  &:hover {
    background-color: #f0f0f0;
  }
`;

const LogoutButton = styled(DropdownItem)`
  color: #fff;
  background-color: #c00;
  border-radius: 16px;
  margin: 5px;
  width: 60%;
  text-align: center;
  &:hover {
    background-color: #a00;
  }
`;

const ChangePasswordButton = styled(DropdownItem)`
  color: #fff;
  background-color: #01354A;
  border-radius: 16px;
  margin: 5px;
  width: 80%;
  text-align: center;
  &:hover {
    background-color: #012F41;
  }
`;

const UserMenu: React.FC = () => {
  const [menuVisible, setMenuVisible] = useState(false);
  const logOut = useLogOut();
  const navigate = useNavigate();

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  const firstName = localStorage.getItem('firstName');

  return (
    <Container onClick={toggleMenu}>
      <ProfileIcon>
        <span role="img" aria-label="user">👤</span>
      </ProfileIcon>
      <UserName>¡Hola, {firstName}!</UserName>
      <DropdownMenu visible={menuVisible}>
        <LogoutButton onClick={() => logOut()}>Cerrar Sesión</LogoutButton>
        <ChangePasswordButton onClick={() => navigate('/auth/change-password')}>Cambiar Contraseña</ChangePasswordButton>
      </DropdownMenu>
    </Container>
  );
};

export default UserMenu;
