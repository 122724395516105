import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import logoIcon from '../../assets/Almoby Isotipo - Digital - Blanco_Mesa de trabajo 1 1.png';
import companyLogo from '../../assets/Logo_Apaisado_Azul-removebg-preview 2.png';
import UserMenu from './UserMenu';
import { useNavigate } from 'react-router-dom';
import { PLACEHOLDER_FONT_COLOR } from '../../constants/colors.constants';
import { isMobile } from '../../utils/utils';

interface SimpleNavBarProps {
  showUserMenu: boolean;
}

const NavbarContainer = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #01354A;
  color: white;
  padding: 0.5rem 1rem;
  height: 5rem;

  @media (max-width: 768px) {
    flex-direction: row;
    height: auto;
    padding: 1rem;
  }
`;

const LogoContainer = styled.div`
  flex: 4;
  display: flex;
  justify-content: center;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: end;
  }
`;

const LeftLogo = styled.img`
  height: 80px;
  width: auto;
  cursor: pointer;

  @media (max-width: 768px) {
    height: 50px;
    margin-left: -1rem;
  }
`;

const RightLogo = styled.img`
  height: 80px;

  @media (max-width: 768px) {
    height: 60px;
  }
`;

const ButtonContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1;

  @media (max-width: 768px) {
    margin-top: 1rem;
  }
`;

const MenuButton = styled.button`
  border: none;
  color: white;
  cursor: pointer;
  font-size: 1.5rem;

  @media (min-width: 769px) {
    display: none;
  }
`;

const slideDown = keyframes`
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
`;

const DropdownMenu = styled.div<{ isVisible: boolean }>`
  display: ${(props) => (props.isVisible ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #01354A;
  color: white;
  animation: ${slideDown} 0.3s ease-in-out;
  z-index: 999;

  @media (min-width: 769px) {
    display: none;
  }
`;

const MenuHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 1.5rem;
`;

const MenuItem = styled.div`
  padding: 1rem;
  text-align: center;
  cursor: pointer;
  border-bottom: 1px solid #024c66;

  &:hover {
    background: #024c66;
  }
`;

const LoginNavBar = () => {
  const navigate = useNavigate();
  const [menuVisible, setMenuVisible] = useState(false);

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  const closeMenu = () => {
    setMenuVisible(false);
  };

  return (
    <>
      <NavbarContainer>
        <LeftLogo src={companyLogo} alt='company-logo' onClick={() => navigate('/landing')} />
        <LogoContainer>
          <RightLogo src={logoIcon} alt='logo-icon' />
        </LogoContainer>
        {!isMobile() && <div style={{display: 'flex', flex: 1}}></div>}
      </NavbarContainer>
    </>
  );
};

export default LoginNavBar;
