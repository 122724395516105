import { ReactNode, MouseEventHandler } from "react";
import styled from 'styled-components';
import { PRIMARY_COLOR } from "../../constants/colors.constants";


interface TertiaryButtonProps {
    children: ReactNode;
    onClick?: MouseEventHandler<HTMLButtonElement>;
    borderRadius?: number;
    color?: string;
    width?: number
}

const TertiaryButton = styled.button.attrs<TertiaryButtonProps>((props) => ({
    borderRadius: props.borderRadius || 12,
    borderColor: props.color,
    width: props.width
}))<TertiaryButtonProps>`
    background-color: ${PRIMARY_COLOR};
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    font-size: 16px;
    text-align: center;
    font-weight: bold;
    max-width: 100px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
`;


const TertiaryButtonComponent = ({
    children,
    onClick,
    borderRadius = 8,
}: TertiaryButtonProps) => {
  return (
    <TertiaryButton 
    onClick={onClick}
    borderRadius={borderRadius}
  >
    {children}
  </TertiaryButton>
  )
}

export default TertiaryButtonComponent