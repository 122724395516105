import styled from "styled-components";
import RightArrowIcon from "../../assets/arrow-right.png";
import LeftArrowIcon from "../../assets/arrow-left.png";
import AboutUsTwo from '../../assets/about-us/about-us-two.png';
import AboutUsThree from '../../assets/about-us/about-us-three.png';
import AboutUsFour from '../../assets/about-us/about-us-four.png';
import AboutUsFive from '../../assets/about-us/about-us-five.png';

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { useState } from 'react';



const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;
    @media (max-width: 768px) {
      gap: 10px;
  }
`

export const Arrow = styled.img`
    height: 71px;
    width: 73px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
    &:hover {
        transform: scale(1.1);
    }

    &:active {
      transform: scale(0.7);
    }

    @media (max-width: 768px) {
      height: 30px;
      width: 30px;
    }
`;



const StyledCarousel = styled(Carousel)`
    height: 425px;
    width: 765px;
    border-radius: 39px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 1818px) {
      height: 340px;
      width: 612px;
  }
  box-shadow: 0 40px 25px rgba(0, 0, 0, 0.4);

  @media (max-width: 768px) {
    width: 272px;
    height: 180.8px;
  }
`;


const Image = styled.img`
  width: 100%;
  height: 100%;
`



const SingleImageCarousel = () => {

    const [currentSlide, setCurrentSlide] = useState(0);

    const next = () => {
      setCurrentSlide(currentSlide + 1);
    };
  
    const prev = () => {
      setCurrentSlide(currentSlide - 1);
    };
  
  
    const updateCurrentSlide = (index: number) => {
  
      if (currentSlide !== index) {
        setCurrentSlide(index)
      }
    };
    return (
        <Wrapper>
          <Arrow src={LeftArrowIcon} onClick={prev} />
            <StyledCarousel 
            selectedItem={currentSlide}
            onChange={updateCurrentSlide}
            showArrows={false}
            showStatus={false}
            showThumbs={false}
            showIndicators={false}
            autoPlay={true}
            infiniteLoop={true}
            >
              <Image src={AboutUsTwo} />
              <Image src={AboutUsThree} />
              <Image src={AboutUsFour} />
              <Image src={AboutUsFive} />

              </StyledCarousel>
          <Arrow src={RightArrowIcon} onClick={next} />
        </Wrapper>
    )
}

export default SingleImageCarousel