import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { useSpring, animated } from 'react-spring';
import styled from 'styled-components';

const ItemType = 'CATEGORY_ITEM';

const StyledCategoryItem = styled.div`
  padding: 10px;
  border: 2px solid #003049;
  border-radius: 12px;
  margin-bottom: 8px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  color: #003049;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;

  &:hover {
    background-color: #e0f7fa;
  }
`;

const DraggableCategoriesOrder = ({ item, index, moveItem, label }: any) => {
  const ref = useRef<HTMLDivElement>(null);
  const [, drop] = useDrop({
    accept: ItemType,
    hover(draggedItem: any, monitor) {
      if (!ref.current) return;
      const dragIndex = draggedItem.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) return;

      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset!.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;

      moveItem(dragIndex, hoverIndex);
      draggedItem.index = hoverIndex;
    }
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemType,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  });

  drag(drop(ref));

  const springStyle = useSpring({
    opacity: isDragging ? 0.5 : 1,
    transform: isDragging ? 'scale(1.02)' : 'scale(1)',
    boxShadow: isDragging ? '0px 4px 10px rgba(0, 0, 0, 0.2)' : '0px 1px 3px rgba(0, 0, 0, 0.1)'
  });

  return (
    <animated.div ref={ref} style={springStyle}>
      <StyledCategoryItem>
        {label}
      </StyledCategoryItem>
    </animated.div>
  );
};

export default DraggableCategoriesOrder;
