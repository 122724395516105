export const PRIMARY_COLOR = '#01354A';
export const WHITE = '#FFFFFF';
export const BLACK_FONT = '#21272A';
export const PLACEHOLDER_FONT_COLOR = '#697077';
export const RECTANGULAR_INPUT_BACKGROUND_COLOR = '#F2F4F8';
export const RECTANGULAR_INPUT_BORDER_BOTTOM_COLOR = '#C1C7CD';
export const CIRCULAR_INPUT_BACKGROUND_COLOR = '#F2F4F8';
export const RED_COLOR = '#DA1E28';
export const GREEN_COLOR = '#024A01';
export const GOLDEN_COLOR = '#B77616';
export const GRAY_COLOR = '#F2F4F8';
export const GREEN_PUBLISH_CATALOG = '#014A04';
export const GREEN_EXCEL = '#128B08';