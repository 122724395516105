import { useState, useEffect } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Card from '../cards/Card';
import styled from "styled-components";
import leftArrow from '../../assets/arrow-left.png';
import rightArrow from '../../assets/arrow-right.png';
import { useNavigate } from 'react-router-dom';
import { isMobile } from '../../utils/utils';

interface CardData {
  title: string;
  infoOne: string;
  infoTwo: string;
  button: string;
  tv: boolean;
  streaming: boolean;
  fisico: boolean;
  fecha: string;
  img: string;
  type: string;
  id: number;
}

interface CarouselComponentProps {
  items: CardData[];
  id?: string;
}

const CarouselWrapper = styled.div`
  width: 90%;
  box-sizing: border-box;
  height: 70vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 80px;
  padding-right: 80px;
  align-items: center;

  @media (min-width: 769px) and (max-width: 1280px) {
    padding-left: 0;
    padding-right: 0;
    margin-top: 40px;
  }

  @media (min-width: 1280px) and (max-width: 1400px) {
    padding-left: 0;
    padding-right: 0;
    margin-top: 40px;
  }
  

  @media (max-width: 768px) {
    flex-direction: row;
    justify-content: center;
    width: 90vw;
    padding-left: 0;
    padding-right: 0;
  }
`;

const Container = styled.div`
  display: flex;
  height: 550px;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  gap: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    height: auto;
    width: 100%;
  }
  @media (min-width: 769px) and (max-width: 1280px) {
    justify-content: unset;
    gap: 40px;
  }
`;

const CardContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    width: 100%;
    justify-content: center;
  }

  @media (min-width: 769px) and (max-width: 1280px) {
    justify-content: unset;
  }
`;

const StyledCarousel = styled(Carousel)`
  width: 100%;
  display: flex;
  justify-content: center;
  @media (max-width: 1818px) {
    width: 90%;
  }
  @media (max-width: 768px) {
    width: 90%;
  }
`;


export const Arrow = styled.img`
    height: 71px;
    width: 73px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
    
    &:hover {
        transform: scale(1.1);
    }

    &:active {
      transform: scale(0.7);
    }

    @media (max-width: 768px) {
    width: 29px;
    height: 30px;
  }
`;

const CarouselComponent = ({ items, id = 'default' }: CarouselComponentProps) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const next = () => {
    setCurrentSlide(currentSlide + 1);
  };

  const prev = () => {
    setCurrentSlide(currentSlide - 1);
  };

  const chunkItems = (items: CardData[], chunkSize: number): CardData[][] => {
    let chunks: CardData[][] = [];
    for (let i = 0; i < items.length; i += chunkSize) {
      chunks.push(items.slice(i, i + chunkSize));
    }
    return chunks;
  };

  const itemChunks = chunkItems(items, isMobile() ? 1 : 3);

  const updateCurrentSlide = (index: number) => {
    if (currentSlide !== index) {
      setCurrentSlide(index);
    }
  };

  const navigate = useNavigate();

  return (
    <CarouselWrapper id={id}>
      <Arrow src={leftArrow} onClick={prev} />

      <StyledCarousel
        selectedItem={currentSlide}
        onChange={updateCurrentSlide}
        showArrows={false}
        showStatus={false}
        showThumbs={false}
        showIndicators={false}
      >
        {itemChunks.map((chunk, index) => (
          <Container key={index}>
            {chunk.map((item, idx) => (
              <CardContainer key={idx}>
                <Card
                  onClick={() => navigate(`/batchs/${item.id}`)}
                  title={item.title}
                  infoOne={item.infoOne}
                  infoTwo={item.infoTwo}
                  button={item.button}
                  tv={item.tv}
                  streaming={item.streaming}
                  fisico={item.fisico}
                  img={item.img}
                  date={item.fecha}
                  type={item.type}
                />
              </CardContainer>
            ))}
          </Container>
        ))}
      </StyledCarousel>

      <Arrow src={rightArrow} onClick={next} />
    </CarouselWrapper>
  );
};

export default CarouselComponent;
