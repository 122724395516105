import styled from "styled-components";
import SimpleNavbar from "../../components/shared/SimpleNavBar";
import CowsBackgroundImage from '../../assets/login-background.png';
import { BLACK_FONT, GOLDEN_COLOR, PRIMARY_COLOR, RED_COLOR, WHITE } from "../../constants/colors.constants";
import { IconButtonComponent } from "../../components/buttons/IconButtonComponent";
import { AuctionItemComponent } from "../../components/auction/AuctionItemComponent";
import { SecondaryButtonWithIconComponent } from "../../components/buttons/SecondaryButtonComponentWithIcon";
import { FaFileExcel, FaPencilAlt, FaTimes } from "react-icons/fa";
import { useEffect, useState } from "react";
import { API_URL } from "../../constants/config.constants";
import { filterNextAuctions, isMobile } from "../../utils/utils";
import { SpinnerForTables } from "../../components/loadings/SpinnerForTables";
import { useNavigate } from "react-router-dom";
import { getAuctionColor, getDate, getHour } from "../../utils/AuctionUtils";
import { MobileNavBar } from "../../components/shared/MobileNavBar";
import { fetchWithAuth } from "../Auth/AuthUtils";

const BackgroundImage = styled.div`
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url(${CowsBackgroundImage});
    background-size: cover;
`;

const AuctionsContainer = styled.div`
    height: 611px;
    width: 1069px;
    background-color: ${WHITE};
    border-radius: 19px;
    padding: 40px;
    overflow-y: scroll;
    overflow-x: hidden;

    @media (max-width: 1818px) {
        height: 520px;
        padding: 30px;
        width: 80%;
    }
`;

const HeaderContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

const Title = styled.h2`
    color: ${BLACK_FONT};
    font-size: 42px;
    
    
`;

const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 40px;
    margin-left: 160px;
    @media (max-width: 768px) {
        margin-top: 2px;
        margin-left: 100px;
    }
`;

const ModalOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
`;

const ModalContainer = styled.div`
    background: ${WHITE};
    border-radius: 10px;
    padding: 20px;
    width: 400px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;

const ModalTitle = styled.h3`
    margin: 0 0 20px;
    color: ${BLACK_FONT};
`;

const ModalButtonsContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 10px;
`;

const ModalButton = styled.button<{cancel?: boolean}>`
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    color: ${WHITE};
    background-color: ${props => props.cancel ? 'grey' : RED_COLOR};

    &:hover {
        opacity: 0.8;
    }
`;

const NoItemsContainer = styled.div`
    margin-top: 40px;
    height: 300px;
    border-radius: 20px;
    box-shadow: 0 2px 10px ${PRIMARY_COLOR};
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;

const NoItemsPlaceholder = styled.p`
    color: ${BLACK_FONT};
    font-size: 30px;
    font-weight: 600;
`;

export const NextAuctionsPage = ({showButtons}: any) => {
    const [isLoading, setIsLoading] = useState(false);
    const accessToken = localStorage.getItem('accessToken');
    const [nextAuctions, setNextAuctions] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedAuction, setSelectedAuction] = useState<any>(null);
    const navigate = useNavigate();

    const fetchAuctions = async () => {
        setIsLoading(true);
        try {
            const response = await fetchWithAuth(`${API_URL}/remates/`);
            const data = await response.json();
            setNextAuctions(filterNextAuctions(data.results));
            console.log(nextAuctions)
            setIsLoading(false);
        } catch (error) {
            console.error("Error fetching remates:", error);
            setIsLoading(false);
        }
    };

    const deleteAuction = async (id: number) => {
        setIsLoading(true);
        try {
            const response = await fetchWithAuth(`${API_URL}/remates/${id}/`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                }
            });
            if (response.ok) {
                setNextAuctions((prevAuctions) => prevAuctions.filter((auction: any) => auction.id !== id));
                setIsLoading(false);
                handleClose();
            } else {
                console.error(`Failed to delete auction ${id}`);
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            console.error("Error deleting remate:", error);
        }
    };

    useEffect(() => {
        fetchAuctions();
    }, []);

    const handleDeleteClick = (auction: any) => {
        setSelectedAuction(auction);
        setShowModal(true);
    }

    const handleClose = () => {
        setShowModal(false);
        setSelectedAuction(null);
    }

    return (
        <div style={{ height: '90vh'}}>
            {isMobile() ? <MobileNavBar/> : <SimpleNavbar showUserMenu={true} />}
            <BackgroundImage>
                <AuctionsContainer>
                    <HeaderContainer>
                        <div></div>
                        <Title>Próximos Remates</Title>
                        <IconButtonComponent onClick={() => navigate(-1)} />
                    </HeaderContainer>
                    {isLoading && <SpinnerForTables/>}
                    {!isLoading && (
                        <>
                        {!nextAuctions.length && (
                        <NoItemsContainer>
                            <NoItemsPlaceholder>
                                No se encontraron Remates
                            </NoItemsPlaceholder>
                        </NoItemsContainer>
                    )}
                    {
                        nextAuctions.map((auction: any) =>
                            {
                                return <div key={auction.id}>
                                    <AuctionItemComponent
                                        marginTop="0"
                                        backgroundColor={getAuctionColor(auction.tipo)}
                                        whenDate={getDate(auction.fecha)}
                                        whenHour={`${getHour(auction.fecha)} hs`}
                                        location={auction.lugar}
                                        inPerson={!!auction.fisico}
                                        type={auction.tipo}
                                        auctionId={auction.id}
                                        videoUrl={auction.url_streaming}
                                        />
                                    {showButtons && !isMobile() && (
                                        <ButtonsContainer>
                                            <SecondaryButtonWithIconComponent margin icon={FaFileExcel} text={"Ordenar Lotes"} onClick={() => navigate(`/batches/order/${auction.id}`, { state: { auction } })} />
                                            <SecondaryButtonWithIconComponent margin icon={FaPencilAlt} text={"Editar Remate"} onClick={() => navigate(`/auctions/edit/${auction.id}`)} />
                                            <SecondaryButtonWithIconComponent margin color={RED_COLOR} icon={FaTimes} text={"Borrar Remate"} onClick={() => handleDeleteClick(auction)} />
                                        </ButtonsContainer>
                                    )}

                                    {showButtons && isMobile() && (
                                        <ButtonsContainer>
                                            <SecondaryButtonWithIconComponent  icon={FaPencilAlt} text={"Editar Remate"} onClick={() => navigate(`/auctions/edit/${auction.id}`)} />
                                            <SecondaryButtonWithIconComponent  color={RED_COLOR} icon={FaTimes} text={"Borrar Remate"} onClick={() => handleDeleteClick(auction)} />
                                        </ButtonsContainer>
                                    )}

                                </div>;
                            }
                        )
                    }
                        </>
                    )}
                </AuctionsContainer>
            </BackgroundImage>

            {showModal && (
                <ModalOverlay>
                    <ModalContainer>
                        <ModalTitle>Confirmación</ModalTitle>
                        <p>¿Estás seguro de que deseas borrar este remate?</p>
                        <ModalButtonsContainer>
                            <ModalButton cancel onClick={handleClose}>Cancelar</ModalButton>
                            <ModalButton onClick={() => deleteAuction(selectedAuction?.id)}>Aceptar</ModalButton>
                        </ModalButtonsContainer>
                    </ModalContainer>
                </ModalOverlay>
            )}
        </div>
    );
};
