// src/api/ControlPanelAPI.ts

import { API_URL } from '../constants/config.constants';
import { Batch } from '../model/Batch';
import { Comitente } from '../pages/Principals/Principals';
import { Province } from '../model/Province';
import { Location } from "../model/Location";
import { Auction } from '../model/Auction';
import { fetchWithAuth } from '../pages/Auth/AuthUtils';

export const fetchLocations = async (): Promise<Location[]> => {
  let url = `${API_URL}/localidades/?limit=10000`;
  let allLocations: Location[] = [];
  try {
    while (url) {
      const response = await fetchWithAuth(url);
      const data = await response.json();
      const locationsBatch = data.results;
      allLocations = [...allLocations, ...locationsBatch];
      url = data.next;
    }
    return allLocations;
  } catch (error) {
    console.error('Error fetching locations:', error);
    return [];
  }
};

export const fetchProvinces = async (): Promise<Province[]> => {
  try {
    const response = await fetchWithAuth(`${API_URL}/provincias/`);
    const data = await response.json();
    return data.results;
  } catch (error) {
    console.error('Error fetching provinces:', error);
    return [];
  }
};

export const fetchBatchs = async (): Promise<Batch[]> => {
  const urls = [
    {url: `${API_URL}/lotes/faena/`, formType: 'faena'},
    {url:`${API_URL}/lotes/invernada/`, formType: 'invernada'},
    {url:`${API_URL}/lotes/vientre/`, formType: 'vientre'}
  ];
  let allBatchs: Batch[] = [];

  const fetchBatchsFromUrl = async (url: string, formType: string) => {
    let currentUrl = url;
    while (currentUrl) {
      const response = await fetchWithAuth(currentUrl);
      const data = await response.json();
      const batchsBatch = data.results.map((batch: any): Batch => ({
        ...batch,
        id: batch.id,
        formType,
        peso_promedio: batch.peso_promedio,
        categoria: batch.categoria,
        denticion: batch.denticion,
        raza: batch.raza,
        establecimiento: batch.establecimiento,
        observaciones: batch.observaciones,
        plazo: batch.plazo,
        celular: batch.celular,
        video: batch.video,
        foto: batch.foto,
        cantidad: batch.cantidad,
        peso_minimo: batch.peso_minimo,
        peso_maximo: batch.peso_maximo,
        publicado: batch.publicado,
        subcategoria: batch.subcategoria,
        cantidad_masculino: batch.cantidad_masculino,
        cantidad_hembra: batch.cantidad_hembra,
        terminacion: batch.terminacion,
        destino: batch.destino,
        condicion: batch.condicion,
        desbaste: batch.desbaste,
        pesaje: batch.pesaje,
        rend_estimado: batch.rend_estimado,
        comitente: batch.comitente,
        remate: batch.remate,
        usuario: batch.usuario
      }));
      allBatchs = [...allBatchs, ...batchsBatch];
      currentUrl = data.next;
    }
  };

  try {
    await Promise.all(urls.map(url => fetchBatchsFromUrl(url.url, url.formType)));
    return allBatchs;
  } catch (error) {
    console.error("Error fetching batches:", error);
    return [];
  }
};

export const fetchPrincipals = async (provinces: Province[], locations: Location[]): Promise<Comitente[]> => {
  try {
    const response = await fetchWithAuth(`${API_URL}/comitentes/`);
    if (!response.ok) {
      console.error('Error fetching principals:', response.statusText);
      return [];
    }
    const data = await response.json();
    return data.results.map((principal: any) => {
      const provincia = provinces.find((prov: Province) => prov.id == principal.provincia);
      const localidad = locations.find((loc: Location) => loc.id == principal.localidad);
      return {
        id: principal.id,
        nombre: principal.nombre,
        provincia: provincia ? provincia.nombre : 'Desconocido',
        localidad: localidad ? localidad.nombre : 'Desconocido',
        establecimientos: principal.establecimientos || 0
      };
    });
  } catch (error) {
    console.error('Error fetching users:', error);
    return [];
  }
};


export const fetchAuctions = async (provinces: Province[], locations: Location[]) => {
    try {
      const response = await fetchWithAuth(`${API_URL}/remates/`);
      const data = await response.json();

      return data.results.map((item: Auction) => {
        const location = locations.find((loc: Location) => loc.id === item.localidad);
        const province = provinces.find((prov: Province) => prov.id === item.provincia);
        return {
            id: item.id,
            title: item.nombre,
            infoOne: location ? location.nombre : "Localidad desconocida",
            infoTwo: item.lugar,
            button: 'primary',
            img: item.url_foto,
            tv: item.tv,
            streaming: item.streaming,
            fisico: item.fisico,
            fecha: item.fecha,
            type: item.tipo,
            location: item.localidad,
            province: item.provincia,
            onClick: () => console.log(`${item.nombre} clicked`)
        };
      });

    } catch (error) {
      console.error('Error fetching auctions:', error);
    }
  };

  export const fetchUsers = async () => {
    try {
        const response = await fetchWithAuth(`${API_URL}/users/`);

        if (!response.ok) {
            console.error('Error fetching users:', response.statusText);
            return;
        }

        const data = await response.json();
        if (data && Array.isArray(data.results)) {
            return (data.results.map((user: any) => ({
                id: user.id,
                username: user.username,
                firstName: user.first_name || user.username,
                lastName: user.last_name || user.username
            })));
        } else {
            console.error('API did not return expected results:', data);
        }
    } catch (error) {
        console.error('Error fetching users:', error);
    }
};

export const exportExcel = async (auctionId: any) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const response = await fetchWithAuth(`${API_URL}/utils/create-csv-file-lotes-no-publicados/${auctionId}/`, {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      }
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const blob = await response.blob();
    const reader = new FileReader();

    reader.onload = function() {
      const csvContent = reader.result;
      const csvContentWithBom = '\uFEFF' + csvContent;
      const blobUTF8 = new Blob([csvContentWithBom], { type: 'text/csv;charset=utf-8;' });
      const url = window.URL.createObjectURL(blobUTF8);
      const a = document.createElement('a');
      a.href = url;
      a.download = `auction_${auctionId}.csv`;
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    };
    reader.readAsText(blob, 'utf-8');
  } 
  catch (e) {
    console.log(e);
  }
};



export const exportPdf = async (auctionId: any, publicado: boolean, forAdmins: boolean) => {
  try {
    const accessToken = localStorage.getItem("accessToken");
    const response = await fetchWithAuth(`${API_URL}/utils/get-pdf-for-catalog/${auctionId}/?publicado=${publicado}&for_admins=${forAdmins}`, {
      headers: {
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      }
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `catalog_${auctionId}.pdf`;
    document.body.appendChild(a);
    a.click();
    a.remove();
    window.URL.revokeObjectURL(url);
  } 
  catch (e) {
    console.log(e);
  }
}
