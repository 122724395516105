import styled from "styled-components"
import { BLACK_FONT, PRIMARY_COLOR } from "../../constants/colors.constants";

interface ForgetMyPasswordFooterProps {
    labelCheckbox: string,
    isChecked: boolean,
    toggleCheckbox: () => void,
    redirectLabel: string,
    onRedirectClick: () => void
}

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 25px;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  display: none;
`;

const CustomCheckbox = styled.div<{checked: boolean}>`
  width: 20px;
  height: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 10px;
  background-color: ${props => (props.checked ? '#007BFF' : '#FFF')};
`;

const Label = styled.label`
  font-size: 14px;
  color: ${BLACK_FONT};
`;

const StyledRedirectLabel = styled.span`
    color: ${PRIMARY_COLOR};
    cursor: pointer;
    font-weight: 600;
    font-size: 14px;
`;

export const ForgetMyPasswordFooterComponent = ({
    labelCheckbox,
    isChecked,
    toggleCheckbox,
    redirectLabel,
    onRedirectClick
}: ForgetMyPasswordFooterProps) => {
  return (
    <Container>
        <CheckboxContainer onClick={toggleCheckbox}>
            <HiddenCheckbox checked={isChecked} onChange={() => {}} />
            <CustomCheckbox checked={isChecked} />
            <Label>{labelCheckbox}</Label>
        </CheckboxContainer>

        <StyledRedirectLabel onClick={onRedirectClick}>{redirectLabel}</StyledRedirectLabel>
    </Container>
  )
}
