import { PLACEHOLDER_FONT_COLOR } from "../../constants/colors.constants";

const SeparatorComponent = () => {
  return (
    <div
      style={{
        borderLeft: `3px solid ${PLACEHOLDER_FONT_COLOR}`,
        height: '100%',
        margin: '0 20px 10px 0',
      }}
    ></div>
  );
};

export default SeparatorComponent;
