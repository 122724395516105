import { MouseEventHandler } from "react";
import styled from 'styled-components';
import TertiaryButtonComponent from "../buttons/TertiaryButtonComponent";
import CowIcon from '../../assets/silueta-de-vaca.png';
import GeoIcon from '../../assets/ubicacion.png';
import WavyDividerSvg from '../../assets/divisor.svg';
import { PRIMARY_COLOR } from "../../constants/colors.constants";
import { format, toZonedTime } from 'date-fns-tz';
import { es } from 'date-fns/locale';

interface CardProps {
  onClick: any;
  img: string;
  title: string;
  infoOne: string;
  infoTwo: string;
  button: string;
  tv: boolean;
  streaming: boolean;
  fisico: boolean;
  date: string;
  type: string;
  isLast?: boolean;
}

const DateLabel = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -65px;
  border-radius: 12px;
  left: 0px;
  background-color: #01354A;
  padding: 0 20px;
  font-size: 14px;
  color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 10;

  &::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 10%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #01354A;
    box-shadow: -2px -2px 4px rgba(0, 0, 0, 0.1);
  }
  
  @media (max-width: 768px) {
    height: 50px;
  }
`;

const CardContainer = styled.div`
  border: 1px solid #DDE1E6;
  height: 421px;
  width: 416px;
  position: relative;
  box-shadow: 0 16px 12px rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.3s ease;
  background-color: white;
  border-radius: 8px;

  @media (max-width: 1818px) {
    height: 420.9px;
    width: 310px;
  }

  @media (max-width: 1024px) {
    height: 340px;
    width: 320px;
  }

  @media (max-width: 768px) {
    height: 500px;
    width: 330px;
    padding-bottom: 10px;
  }

  @media (min-width: 800px) and (max-width: 1800px) {
    height: 450.9px;
    width: 310px;
  }


`;

const ImageContainer = styled.div<{ imgUrl: string }>`
  cursor: pointer;
  position: relative;
  height: 55%;
  width: 100%;
  background-image: url(${props => props.imgUrl});
  background-size: cover;
  background-position: center;
  border: 5px solid ${PRIMARY_COLOR};
  border-radius: 8px;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 30px;
    background-image: url(${WavyDividerSvg});
    background-size: cover;

    @media (max-width: 768px) {
      height: 20px;
    }

    @media (min-width: 769px) and (max-width: 1680px) {
      height: 22px;
    }

    @media (min-width: 800px) and (max-width: 1800px) {
      height: 20px;
    }
  }

  > ${DateLabel} {
    content: "Jueves, 9 de Mayo | 14 hs";
  }
`;

const InfoContainer = styled.div`
  display: flex;
  width: 100%;
  height: 45%;
  flex-direction: column;
  position: relative;
`;

const CardTitle = styled.h2`
  align-self: center;
  color: #21272A;
  font-weight: bold;
  font-size: 24px;
  text-align: center;

  @media (min-width: 800px) and (max-width: 1800px) {
    font-size: 20px;
  }
  
`;

const CardDescription = styled.p`
  text-align: left;
  margin-left: 2rem;
  color: #21272A;
  font-size: 16px;
`;

const ButtonRow = styled.div`
  align-self: end;
  justify-content: end;
  bottom: 15px;
  right: 15px;
  position: absolute;

  @media (max-width: 768px) {
    bottom: 2px;
    right: 20px;
  }

  @media (min-width: 800px) and (max-width: 1800px) {
    bottom: 2px;
    margin-bottom: 7px;
    right: 20px;
  }
`;

const Icon = styled.img`
  max-height: 31px;
  max-width: 31px;
`;

const SubtitleRow = styled.div`
  display: flex;
  align-items: center;
  margin-left: 2rem;
  max-height: 20px;
  padding-bottom: 12px;
`;

const LabelText = styled.p`
  padding-top: 5px;
  font-weight: bold;
  font-size: 14px;
  padding-right: 20px;
`;

const Label = styled.span<{ bgColor: string }>`
  padding: 5px 10px;
  background-color: ${(props) => props.bgColor || PRIMARY_COLOR};
  color: #fff;
  font-weight: bold;
  font-size: 12px;
`;

const LabelContainer = styled.div`
  position: absolute;
  top: 10px;
  right: 6px;
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

const Card = ({
  img,
  onClick,
  title,
  infoOne,
  infoTwo,
  tv,
  streaming,
  fisico,
  type,
  button,
  date,
  isLast=true
}: CardProps) => {

  const handleCardClick = () => {
    if (isLast) {
      window.open("https://lotes.reggiycia.com/", "_blank");
    } else {
      onClick();
    }
  };
  
  const formatDateToLocal = (isoDate: string) => {
    const time = isoDate.split('T')[1].split(':')[0];
    const minute = isoDate.split('T')[1].split(':')[1].split('.')[0];
    const timeZone = 'America/Argentina/Buenos_Aires';
    const date = new Date(isoDate);
    const zonedDate = toZonedTime(date, timeZone);
    const dateFormat = "EEEE d 'de' MMMM '|' HH 'hs'";
    const formattedDate = format(zonedDate, dateFormat, { locale: es });
    return formattedDate.split('|')[0] + '| ' + time + ':' + minute + ' hs';
  };

  function replaceSpaces(): string {
    const modifiedTile = title.replace(/ /g, "%20");
    const modifiedInfoOne = infoOne.replace(/ /g, "%20");
    return `Remate%20REGGI%20${modifiedTile}%20${modifiedInfoOne}`;
  }

  function formatDateString(plusHours: boolean): string {
    const dateParsed = new Date(date);

    const year = dateParsed.getUTCFullYear();
    const month = String(dateParsed.getUTCMonth() + 1).padStart(2, '0');
    const day = String(dateParsed.getUTCDate()).padStart(2, '0');
    const hours = plusHours ? String(dateParsed.getUTCHours() + 6).padStart(2, '0') : String(dateParsed.getUTCHours()).padStart(2, '0');
    const minutes = String(dateParsed.getUTCMinutes()).padStart(2, '0');
    const seconds = String(dateParsed.getUTCSeconds()).padStart(2, '0');

    const formattedDate = `${year}${month}${day}T${hours}${minutes}${seconds}`;
    return formattedDate;
  }

  const goToGoogleCalendar = `https://calendar.google.com/calendar/u/0/r/eventedit?text=${replaceSpaces()}&dates=${formatDateString(false)}/${formatDateString(true)}`;

  return (
    <CardContainer>
      <ImageContainer imgUrl={img} onClick={handleCardClick}>
        <DateLabel>
          <LabelText>{formatDateToLocal(date)}</LabelText>
        </DateLabel>
      </ImageContainer>
      <InfoContainer>
        <div onClick={handleCardClick} style={{ cursor: 'pointer' }}>
          <CardTitle>{title}, {infoOne}</CardTitle>
          <div style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
            <SubtitleRow>
              <Icon src={CowIcon} />
              <CardDescription>{type}</CardDescription>
            </SubtitleRow>
            <SubtitleRow>
              <Icon src={GeoIcon} />
              <CardDescription>{infoTwo}</CardDescription>
            </SubtitleRow>
          </div>
        </div>
        <ButtonRow>
          <a target="_blank" href={goToGoogleCalendar}>
            <TertiaryButtonComponent>Agendar</TertiaryButtonComponent>
          </a>
        </ButtonRow>
      </InfoContainer>
      <LabelContainer>
        {tv && <Label bgColor={PRIMARY_COLOR}>TV</Label>}
        {streaming && <Label bgColor="red">Streaming</Label>}
        {fisico && <Label bgColor="green">Físico</Label>}
      </LabelContainer>
    </CardContainer>
  );
};

export default Card;
