import styled from "styled-components";
import { PRIMARY_COLOR, WHITE } from "../../constants/colors.constants";
import RuralLogo from '../../assets/canal_rural.png';
import YoutubeLogo from '../../assets/youtube.png';
import { SecondaryButtonComponent } from "../buttons/SecondaryButtonComponent";
import { useNavigate } from "react-router-dom";

interface AuctionItemProps {
    backgroundColor?: string;
    whenDate: string;
    whenHour: string;
    location: string;
    inPerson?: boolean;
    width?: string;
    marginTop?: string;
    type: string;
    auctionId?: string;
    shouldClick?: boolean;
    videoUrl?: string;
}

const StyledComponentContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 40px;
    align-items: center;

    @media (max-width: 768px) {
        gap: 20px;
    }
`;

const StyledContainer = styled.div<{backgroundColor: string, width: string, marginTop: string}>`
    background-color: ${(props) => props.backgroundColor};
    height: 65px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 14px;
    padding-right: 14px;
    border-radius: 12px;
    margin-top: ${(props) => props.marginTop}px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.4);
    width: ${(props) => props.width};

    @media (max-width: 768px) {
        flex-direction: row;
        height: 50px;
        padding: 10px;
        width: 262px;
    }
`;

const StyledInformationContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    @media (max-width: 768px) {
        flex-direction: row;
    }
`;

const StyledInformationLabel = styled.span<{margin?: boolean}>`
    color: ${WHITE};
    font-size: 14px;
    margin-left: ${(props) => props.margin && '5px'};
    margin-right: ${(props) => props.margin && '5px'};

    @media (max-width: 768px) {
        margin-left: 0;
        margin-right: 0;
        font-size: 11px;
    }
`;

const StyledLeftContainer = styled.div`
    display: flex;
    flex-direction: column;

    @media (max-width: 768px) {
        align-items: center;
    }
`;

const StyledImagesContainer = styled.div`
    display: flex;
    flex-direction: row;

    @media (max-width: 768px) {
        margin-top: 10px;
        justify-content: center;
    }
`;

const StyledImg = styled.img`
    width: 59px;
    height: 34px;
    margin-left: 25px;
    cursor: pointer;

    @media (max-width: 768px) {
        margin-left: 10px;
        width: 28px;
        height: 16px;
    }
`;


export const AuctionItemComponent = ({
    backgroundColor = PRIMARY_COLOR,
    whenDate,
    whenHour,
    location,
    videoUrl,
    inPerson = false,
    width = '100%',
    marginTop,
    type,
    auctionId,
    shouldClick = true
}: AuctionItemProps) => {
    const navigate = useNavigate();

    const handleYoutubeOnClick = () => {
        window.open(videoUrl, "_blank")
    }

    return (
        <StyledComponentContainer>
            <SecondaryButtonComponent 
                 width={window.innerWidth <= 769 ? '100px' : '150px'}
                height="40px" 
                color={backgroundColor} 
                onClick={shouldClick ? () => navigate(`/batchs/${auctionId}`) : () => {}}
                fa={backgroundColor}
            >
                {type}
            </SecondaryButtonComponent>
            <StyledContainer 
                backgroundColor={backgroundColor} 
                width={width} 
                marginTop={marginTop || "70"}
            >
                <StyledLeftContainer>
                    <StyledInformationContainer>
                        <StyledInformationLabel>{whenDate}</StyledInformationLabel>
                        <StyledInformationLabel margin>|</StyledInformationLabel>
                        <StyledInformationLabel>{whenHour}</StyledInformationLabel>
                    </StyledInformationContainer>
                    <StyledInformationContainer>
                        <StyledInformationLabel>{location}</StyledInformationLabel>
                    </StyledInformationContainer>
                </StyledLeftContainer>

                <StyledImagesContainer>
                    {
                        inPerson
                        ? <SecondaryButtonComponent height={window.innerWidth <= 769 ? '16px' : 'unset'} width={window.innerWidth <= 769 ? '58px' : 'auto'} backgroundColor="unset" color={WHITE} children={'Físico'} />
                        : <StyledImg src={RuralLogo} alt="canal rural logo" />
                    }
                    <StyledImg src={YoutubeLogo} onClick={() => handleYoutubeOnClick()} alt="youtube logo" />
                </StyledImagesContainer>
            </StyledContainer>
        </StyledComponentContainer>
    );
};
