import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FaTimesCircle, FaRegSave  } from "react-icons/fa";
import { BLACK_FONT, GOLDEN_COLOR, PLACEHOLDER_FONT_COLOR, PRIMARY_COLOR } from '../../../constants/colors.constants';
import CowsBackgroundImage from '../../../assets/blured_cows.png';
import { SecondaryButtonComponent } from '../../../components/buttons/SecondaryButtonComponent';
import { BorderInputComponent } from '../../../components/form/inputs/BorderInputComponent';
import { PrimaryButtonWithIconComponent } from '../../../components/buttons/PrimaryButtonWithIconComponent';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { API_URL } from '../../../constants/config.constants';
import BorderDropdownComponent from '../../../components/form/dropdowns/BorderDropdownComponent';
import { invernadaEstRepr, invernadaGarrapata, invernadaMioMio, invernadaRaza, invernadaSanity, invernadaState, invernadaTratNutric } from '../../../constants/dropdowns/batchs/batchDataDropdown';
import { SpinnerLoading } from '../../../components/loadings/SpinnerLoading';
import { fetchWithAuth } from '../../Auth/AuthUtils';

const ModalContent = styled.div`
  background-color: #fefefe;
  height: 800px;
  width: 638px;
  position: relative;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  overflow-x: hidden;
  z-index: 1100;

  @media (min-width: 800px) and (max-width: 1800px) {
      height: 680px;
      width: 542.3px;
    }
`;

const CloseButton = styled.span`
  color: #aaa;
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;

  &:hover,
  &:focus {
    color: black;
  }
`;

const StyledImg = styled.img`
  margin-top: 30px;
  width: 535px;
  height: 256px;
`;

const HeaderContainer = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 535px;
  padding: 0 20px;
`;

const HeaderTitle = styled.span`
  color: ${PRIMARY_COLOR};
  font-size: 20px;
  font-weight: bold;
`;

const StyledInputsContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
`;

const InputWrapper = styled.div`
  flex: 1 1 calc(50% - 10px);
  min-width: 220px;
  display: flex;
  justify-content: center;
`;

const FooterModal = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 535px;
  align-items: center;
`;

const Label = styled.label`
  margin-bottom: 10px;
  color: ${BLACK_FONT};
  font-size: 14px;
  font-weight: bold;
  margin-left: 5px;
  width: 130px;
`;

interface DetailBatchModalProps {
  onClose: any;
  id: number;
  category: string;
};

export const EditBatchModal = ({ onClose, id, category }: DetailBatchModalProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [batchData, setBatchData] = useState<any>();

  const fetchBatchDetail = async (category: string, id: number) => {
    setIsLoading(true);
    try {
      const response = await fetchWithAuth(`${API_URL}/lotes/${category}/${id}`);
      setBatchData(await response.json());
      setIsLoading(false);
    } catch (error) {
      console.log(error)
    }
  }

  const updateBatch = async () => {
    setIsLoading(true);
    try {
      const response = await fetchWithAuth(`${API_URL}/lotes/${category}/${id}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(batchData)
    });
      setBatchData(await response.json());
      setIsLoading(false);
      window.location.reload();
    } catch (error) {
      console.log(error)
    }
  } 

  useEffect(() => {
    fetchBatchDetail(category, id);
  }, [id, category]);
  

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'fixed', zIndex: 1000, left: 0, top: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.05)' }}>
      <ModalContent>
        { isLoading && <SpinnerLoading/>}
        {
          !isLoading && (
            <>
            <CloseButton onClick={onClose}><FaTimesCircle color={PRIMARY_COLOR} /></CloseButton>
        <StyledImg src={batchData?.foto} />
        <HeaderContainer>
          <SecondaryButtonComponent color={GOLDEN_COLOR}>Exposición</SecondaryButtonComponent>
          <HeaderTitle>{batchData?.cantidad} | {batchData?.categoria}</HeaderTitle>
          <SecondaryButtonComponent color={PRIMARY_COLOR}>Lote {batchData?.id}</SecondaryButtonComponent>
        </HeaderContainer>
        <StyledInputsContainer>
        <InputWrapper style={{ flexDirection: 'column'}}>
            <Label>Raza</Label>
            <BorderDropdownComponent
                  width={225}
                  color={PLACEHOLDER_FONT_COLOR}
                  placeholder={batchData?.raza}
                  options={invernadaRaza}
                  onChange={(e) => setBatchData((prevState: any) => ({
                      ...prevState,
                      raza: e
                  }))}
              />
          </InputWrapper>
          <InputWrapper>
            <BorderInputComponent
              type='number'
              label='Cantidad'
              placeholder='Cantidad'
              value={batchData?.cantidad}
              onChange={(e) => setBatchData((prevState: any) => ({
                ...prevState,
                cantidad: e.target.value
            }))}
              width={200}
              disabled={false}
            />
          </InputWrapper>
          <InputWrapper>
            <BorderInputComponent
              type='number'
              label='Edad'
              placeholder='Edad'
              value={batchData?.edad}
              onChange={(e) => setBatchData((prevState: any) => ({
                ...prevState,
                edad: e.target.value
            }))}
              width={200}
              disabled={false}
            />
          </InputWrapper>
          <InputWrapper>
            <BorderInputComponent
              type='number'
              label='Peso'
              placeholder='Peso'
              value={`${batchData?.peso_promedio} kg`}
              onChange={(e) => setBatchData((prevState: any) => ({
                ...prevState,
                peso: e.target.value
            }))}
              width={200}
              disabled={false}
            />
          </InputWrapper>
          <InputWrapper style={{ flexDirection: 'column'}}>
            <Label>Est. Repr</Label>
            <BorderDropdownComponent
                  width={225}
                  color={PLACEHOLDER_FONT_COLOR}
                  placeholder={batchData?.estado_reproductivo}
                  options={invernadaEstRepr}
                  onChange={(e) => setBatchData((prevState: any) => ({
                      ...prevState,
                      estado_reproductivo: e
                  }))}
              />
          </InputWrapper>
          <InputWrapper>
            <BorderInputComponent
              label='Preñez'
              placeholder='Preñez'
              value={'???'}
              onChange={() => {}}
              width={200}
              disabled={false}
            />
          </InputWrapper>
          <InputWrapper>
            <BorderInputComponent
              label='Parición'
              placeholder='Parición'
              value={'???'}
              onChange={() => {}}
              width={200}
              disabled={false}
            />
          </InputWrapper>
          <InputWrapper style={{ flexDirection: 'column'}}>
            <Label>Estado</Label>
            <BorderDropdownComponent
                  width={225}
                  color={PLACEHOLDER_FONT_COLOR}
                  placeholder={batchData?.estado}
                  options={invernadaState}
                  onChange={(e) => setBatchData((prevState: any) => ({
                      ...prevState,
                      estado: e
                  }))}
              />
          </InputWrapper>
          <InputWrapper>
            <BorderInputComponent
              label='Clase'
              placeholder='Clase'
              value={batchData?.clase}
              onChange={(e) => setBatchData((prevState: any) => ({
                ...prevState,
                clase: e.target.value
            }))}
              width={200}
              disabled={false}
            />
          </InputWrapper>
          <InputWrapper style={{ flexDirection: 'column'}}>
            <Label>Mio Mio</Label>
            <BorderDropdownComponent
                  width={225}
                  color={PLACEHOLDER_FONT_COLOR}
                  placeholder={batchData?.miomio ? 'SI' : 'NO'}
                  options={invernadaMioMio}
                  onChange={(e) => setBatchData((prevState: any) => ({
                      ...prevState,
                      miomio: e
                  }))}
              />
          </InputWrapper>
          <InputWrapper style={{ flexDirection: 'column'}}>
            <Label>Garrapata</Label>
            <BorderDropdownComponent
                  width={225}
                  color={PLACEHOLDER_FONT_COLOR}
                  placeholder={batchData?.garrapata}
                  options={invernadaGarrapata}
                  onChange={(e) => setBatchData((prevState: any) => ({
                      ...prevState,
                      garrapata: e
                  }))}
              />
          </InputWrapper>
          <InputWrapper style={{ flexDirection: 'column'}}>
            <Label>Sanidad</Label>
            <BorderDropdownComponent
                  width={225}
                  color={PLACEHOLDER_FONT_COLOR}
                  placeholder={batchData?.sanidad}
                  options={invernadaSanity}
                  onChange={(e) => setBatchData((prevState: any) => ({
                      ...prevState,
                      sanidad: e
                  }))}
              />
          </InputWrapper>
          <InputWrapper style={{ flexDirection: 'column'}}>
            <Label>Trat. Nutric.</Label>
            <BorderDropdownComponent
                  width={225}
                  color={PLACEHOLDER_FONT_COLOR}
                  placeholder={batchData?.tratamiento_nutricional}
                  options={invernadaTratNutric}
                  onChange={(e) => setBatchData((prevState: any) => ({
                      ...prevState,
                      tratamiento_nutricional: e
                  }))}
              />
          </InputWrapper>
          <InputWrapper>
            <BorderInputComponent
              label='Destino'
              placeholder='Destino'
              value={batchData?.destino}
              onChange={(e) => setBatchData((prevState: any) => ({
                ...prevState,
                destino: e.target.value
            }))}
              width={200}
              disabled={false}
            />
          </InputWrapper>
          <BorderInputComponent
              label='Observaciones'
              placeholder='Observaciones'
              value={batchData?.observaciones}
              onChange={(e) => setBatchData((prevState: any) => ({
                ...prevState,
                observaciones: e.target.value
            }))}
              disabled={false}
            />

            <FooterModal>
                <BorderInputComponent
                label='Plazo'
                placeholder='Plazo'
                value={batchData?.plazo}
                onChange={(e) => setBatchData((prevState: any) => ({
                  ...prevState,
                  plazo: e.target.value
              }))}
                width={200}
                disabled={false}
                />
                <PrimaryButtonWithIconComponent icon={FaRegSave} text={'Guardar'} onClick={() => updateBatch()} width='160px' />
            </FooterModal>
        </StyledInputsContainer>
            </>
          )
        }
      </ModalContent>
      <ToastContainer />
    </div>
  );
};
