import React, { useState } from 'react';
import styled from 'styled-components';
import companyLogo from '../../assets/Logo_Apaisado_Azul-removebg-preview 2.png';
import logoResponsive from '../../assets/Logo.png';
import logoIcon from '../../assets/Almoby Isotipo - Digital - Blanco_Mesa de trabajo 1 1.png';
import { BLACK_FONT, GRAY_COLOR, PLACEHOLDER_FONT_COLOR, PRIMARY_COLOR, WHITE } from '../../constants/colors.constants';
import { SecondaryButtonComponent } from '../buttons/SecondaryButtonComponent';
import { FaBars, FaTimes, FaChevronDown } from 'react-icons/fa';

import { useNavigate } from 'react-router-dom';
import { darkenHexColor } from '../../utils/utils';

const NavbarContainer = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${PRIMARY_COLOR};
  color: white;
  padding: 0.5rem 80px;
  height: 5rem;
  @media (max-width: 768px) {
    padding: 0.5rem 20px;
    background-color: unset;
  }
`;

const Brand = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 1.5rem;
  color: #003366;
`;

const Nav = styled.ul<{ isOpen: boolean }>`
  flex: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  margin: 0;
  padding: 0;
  gap: 40px;

  @media (max-width: 768px) {
    position: absolute;
    height:  ${(props) => (props.isOpen ? '100%' : 'unset')};
    top: 1rem;
    left: 0;
    right: 0;
    background: ${PRIMARY_COLOR};
    flex-direction: column;
    align-items: flex-start;
    padding: 1rem 2rem;
    gap: 100px;
    transform: ${(props) => (props.isOpen ? 'translateY(0)' : 'translateY(-100vh)')};
    transition: transform 0.3s ease-in-out;
  }
`;

const NavItem = styled.li`
  position: relative; /* Importante para el posicionamiento del menú desplegable */
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const LoginSection = styled.div`
  display: flex;  
  align-items: center;

  @media (max-width: 768px) {
    display: none;
  }
`;

const Logo = styled.img`
  height: 70px;
  width: auto;
  margin-right: 1rem;

  @media (max-width: 768px) {
    content: url(${logoResponsive});
    height: 40px;
  }
`;

const RightLogo = styled.img`
  height: 80px;
  width: 91px;
  
  @media (max-width: 1818px) {
    margin-left: 25%;
  }
  @media (max-width: 768px) {
    padding-right: 0;
    height: 60px;
  }
`;

const StyledButton = styled.a`
  text-decoration: none;
  color: unset;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const MobileMenuToggle = styled.div`
  display: none;
  cursor: pointer;
  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    color: ${PRIMARY_COLOR};
    background-color: ${WHITE};
    padding: 8px;
    border-radius: 50px;
    border: 1px solid ${PRIMARY_COLOR};
    z-index: 9999;
  }
`;

const DropdownMenu = styled.div<{ show: boolean }>`
  display: ${props => (props.show ? 'block' : 'none')};
  position: absolute;
  top: 100%;
  width: 135px;
  border-radius: 5px;
  left: 0;
  background-color: ${GRAY_COLOR};
  border: 1px solid #ccc;
  z-index: 1000;
`;

const DropdownItem = styled.a`
  padding: 10px 20px;
  display: block;
  text-decoration: none;
  color: ${BLACK_FONT};
  font-weight: bold;
  font-size: 14px;
  border-bottom: 1px solid ${darkenHexColor(GRAY_COLOR, 20)};
  &:hover {
    background-color: #f0f0f0;
  }
`;

const Navbar: React.FC = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const [showDropdown, setShowDropdown] = useState(false);

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  return (
    <NavbarContainer>
      <Brand><Logo src={companyLogo} alt='company-logo' /></Brand>
      <MobileMenuToggle onClick={toggleMenu}>
        {isOpen ? <FaTimes /> : <FaBars />}
      </MobileMenuToggle>
      <Nav isOpen={isOpen}>
        <NavItem>
          <SecondaryButtonComponent color={PRIMARY_COLOR} bold={800}>
            <StyledButton href='#about'>Sobre Nosotros</StyledButton>
          </SecondaryButtonComponent>
        </NavItem>
        <NavItem>
          <SecondaryButtonComponent color={PRIMARY_COLOR} bold={800} onClick={toggleDropdown}>
            <StyledButton>Hacienda <FaChevronDown /></StyledButton>
          </SecondaryButtonComponent>
          <DropdownMenu show={showDropdown}>
            <DropdownItem href="#next">Próximos Remates</DropdownItem>
            <DropdownItem href="#finished">Remates Finalizados</DropdownItem>
          </DropdownMenu>
        </NavItem>
        <NavItem>
          <SecondaryButtonComponent color={PRIMARY_COLOR} bold={800}>
            <StyledButton href='#contact'>Contacto</StyledButton>
          </SecondaryButtonComponent>
        </NavItem>
      </Nav>
      <LoginSection>
        <Brand>
          <SecondaryButtonComponent color={PRIMARY_COLOR} bold={800} onClick={() => navigate('/auth/login')}>Ingresar</SecondaryButtonComponent>
          <RightLogo src={logoIcon} alt='company-logo' />
        </Brand>
      </LoginSection>
    </NavbarContainer>
  );
};

export default Navbar;
