export const auctionTypes = [
    { label: "Habitual", value: "Habitual" },
    { label: "Especial", value: "Especial" },
    { label: "Exposicion", value: "Exposicion" },
    { label: "Cabañas", value: "Cabañas" },
    { label: "Libre", value: "Libre" },
    { label: "Especial Faena", value: "Especial Faena" },
    { label: "Holando", value: "Holando" }
  ];

export const auctionDatesFilterByYear = [
    { label: '2010', value: '2010-01-01T00:00:00.000Z'},
    { label: '2011', value: '2011-01-01T00:00:00.000Z'},
    { label: '2012', value: '2012-01-01T00:00:00.000Z'},
    { label: '2013', value: '2013-01-01T00:00:00.000Z'},
    { label: '2014', value: '2014-01-01T00:00:00.000Z'},
    { label: '2015', value: '2015-01-01T00:00:00.000Z'},
    { label: '2016', value: '2016-01-01T00:00:00.000Z'},
    { label: '2017', value: '2017-01-01T00:00:00.000Z'},
    { label: '2018', value: '2018-01-01T00:00:00.000Z'},
    { label: '2019', value: '2019-01-01T00:00:00.000Z'},
    { label: '2020', value: '2020-01-01T00:00:00.000Z'},
    { label: '2021', value: '2021-01-01T00:00:00.000Z'},
    { label: '2022', value: '2022-01-01T00:00:00.000Z'},
    { label: '2023', value: '2023-01-01T00:00:00.000Z'},
    { label: '2024', value: '2024-01-01T00:00:00.000Z'},
]