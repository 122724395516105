import { GOLDEN_COLOR, PRIMARY_COLOR } from "../constants/colors.constants";

export const getAuctionColor = (tipo: string) => {
    const auctionColor: any = {
        "Cabañas": PRIMARY_COLOR,
        "Habitual": PRIMARY_COLOR,
        "Especial": PRIMARY_COLOR,
        "Exposicion": GOLDEN_COLOR,
        "Libre": PRIMARY_COLOR,
        "Especial Faena": PRIMARY_COLOR,
        "Holando": PRIMARY_COLOR
    };
    return auctionColor[tipo];
}


export const getDate = (date: any) => {
    const parsedDate = date.split('T');
    return parsedDate[0];
}

export const getHour = (date: any) => {
    const partDate = date.split('T');
    const hour = partDate.toString().split(',');
    return hour[1].split('Z')[0].substring(0, 5);
}
