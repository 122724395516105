import styled from "styled-components";
import { PRIMARY_COLOR } from "../../constants/colors.constants";
import Navbar from "../../components/shared/NavBar";
import { SecondaryButtonComponent } from "../../components/buttons/SecondaryButtonComponent";
import LogoIcon from "../../assets/Logo_Circular_Chico_SIN FONDO 1.png";
import { PrimaryButtonComponent } from "../../components/buttons/PrimaryButtonComponent";
import { useEffect, useState } from 'react';
import BackgrounImage from '../../assets/fondo-vacas-landing.png';
import { isMobile } from "../../utils/utils";
import { API_URL, S3_URL } from "../../constants/config.constants";
import { LandingMobileNavBar } from "../../components/shared/LandingMobileNavBar";
import { VideoComponent } from "./VideoComponent";


const MainContainer = styled.div`
    height: 100vh;
    background-image: url(${BackgrounImage});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
`

const HeroContainer = styled.div`
    display: flex;
    flex-direction: row;
    height: 100%;
    padding: 0 80px;
    @media (max-width: 768px) {
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding: 0 20px;
    }

    @media (min-width: 769px) and (max-width: 1280px) {
        padding: unset;
    }
    
    @media (min-width: 1282px) and (max-width: 1335px) {
        padding: unset;
      }
      
`;

export const VideoSection = styled.div`
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    @media (max-width: 768px) {
        order: 2;
        padding-bottom: 20px;
        margin-top: 200px;
    }
`

export const VideoContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;
    @media (max-width: 768px) {
        flex-direction: row;
    }
`

const BrandSection = styled.div`
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media (max-width: 768px) {
        order: 1;
        margin-bottom: 20px;
        height: 90vh;
    }
`;

export const VideoCard = styled.div`
    height: 425px;
    width: 765px;
    border: 7px solid #01354A;
    border-radius: 39px;
    overflow: hidden;
    @media (max-width: 1818px) {
        height: 340px;
        width: 612px;
    }
    @media (max-width: 768px) {
        height: 240px;
        width: 90%;
    }
`;

const ButtonContainer = styled.div`
    display: flex;
    margin-top: 5%;
    gap: 20px;
    @media (max-width: 768px) {
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 90%;
    }
`

export const Arrow = styled.img`
    height: 71px;
    width: 73px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;

    &:hover {
        transform: scale(1.7);
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    }

    &:active {
        transform: scale(0.7);
    }

    @media (max-width: 768px) {
        height: 40px;
        width: 40px;
    }
`;


const Logo = styled.img`
    height: 259px;
    width: 261px;
    margin-top: -15%;
    @media (max-width: 1818px) {
        height: 207.2px;
        width: 208.8px;
    }
    @media (max-width: 768px) {
        height: 259px;
        width: 261px;
        margin-top: -25%;
    }
`

const Title = styled.h1`
    color: #01354A;
    font-weight: bold;
    text-align: center;
    @media (max-width: 768px) {
        font-size: 16px;
        margin-top: 120px;
    }
`

export const YouTubeIframe = styled.iframe`
    width: 100%;
    height: 100%;
    border-radius: 39px;
`;

const HeroPage = () => {
    const [pastStreamings, setPastStreamings] = useState<any>([]);
    const [liveAuction, setLiveAuction] = useState<any>();

    useEffect(() => {
        const fetchLiveStream = async () => {
            try {
                const response = await fetch(`${API_URL}/live-video/?limit=1`);
                const data = await response.json();
                const { results } = data;
                if (results[0].videoId == null) {
                    const response = await fetch(`${API_URL}/remates/?limit=5`)
                    const data = await response.json();
                    const streamings = data.results.map((auction: any) => ({
                        auctionId: auction.id,
                        videoUrl: auction.url_streaming,
                        catalogPath: auction.catalogo_path
                    }));
                    setPastStreamings(streamings);
                } else {
                    const auctionResponse = await fetch(`${API_URL}/remates/${results[0].id}`)
                    const dataAuction = await auctionResponse.json()
                    setLiveAuction(dataAuction)
                }
            } catch (error) {
                console.error('Fetching live stream failed', error);
            }
        };
        fetchLiveStream();
    }, []);

    const handleExport = async () => {
        if(!pastStreamings) {
            return;
        }
        pastStreamings.forEach(async (data: any) => {
            window.open(`${S3_URL}/${data.catalogPath}`)
        });
    }

    const handleNewCatalog = async () => {
        if(!liveAuction) {
            return;
        }
        window.open(`${S3_URL}/${liveAuction.catalogPath}`)
    }

    return (
        <MainContainer>
            {isMobile() ? <LandingMobileNavBar/> : <Navbar/>}
            <HeroContainer>
                { !isMobile() && <VideoComponent />}
                <BrandSection>
                    <Logo src={LogoIcon} />
                    <Title>"Un nombre hecho con fe y ganado con esfuerzo"</Title>
                    <ButtonContainer>
                        <SecondaryButtonComponent onClick={() => handleExport()} special width={isMobile() ? "171px" : "236px"} color={PRIMARY_COLOR} backgroundColor={"rgba(0,0,0,0)"} border>Catálogos Anteriores</SecondaryButtonComponent>
                        <PrimaryButtonComponent height="56" width="161px" onClick={() => handleNewCatalog()} borderRadius={12}>Catálogo Nuevo</PrimaryButtonComponent>
                    </ButtonContainer>
                </BrandSection>
            </HeroContainer>
        </MainContainer>
    );
}

export default HeroPage;
