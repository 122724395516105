import React, { useEffect, useState } from 'react';
import {
    Dialog,
    DialogContent,
    IconButton,
    Box,
    Select,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import styled from 'styled-components';
import { HeaderComponent } from '../../components/common/HeaderComponent';
import { BorderInputComponent } from '../../components/form/inputs/BorderInputComponent';
import { API_URL } from '../../constants/config.constants';
import BorderDropdownComponent from '../../components/form/dropdowns/BorderDropdownComponent';
import { LabelComponent } from '../../components/form/basics/LabelComponent';
import { PLACEHOLDER_FONT_COLOR, RED_COLOR } from '../../constants/colors.constants';
import { PrimaryButtonComponent } from '../../components/buttons/PrimaryButtonComponent';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { RootState } from '../../app/Store';
import { fetchWithAuth, useRefreshToken } from '../Auth/AuthUtils';
import { useSelector } from 'react-redux';
import { BorderInputComponentsWithPlusAndMinus } from '../../components/form/inputs/BorderInputComponentsWithPlusAndMinus';
import { isMobile } from '../../utils/utils';

interface EditPrincipalProps {
    open: boolean;
    onClose: () => void;
    id: any;
}

interface Province {
    id: number;
    nombre: string;
}

interface Location {
    id: number;
    nombre: string;
    provincia: string;
}

interface DropdownOption {
    label: string;
    value: string | number;
}

interface Establishments {
    id: number;
    value: string;
}

const EditPrincipal: React.FC<EditPrincipalProps> = ({ open, onClose, id }) => {
    const [name, setName] = useState('');
    const [street, setStreet] = useState('');
    const [mail, setMail] = useState('');
    const [doc, setDoc] = useState('');
    const [cuit, setCuit] = useState('');
    const [provinces, setProvinces] = useState([]);
    const [selectedProvince, setSelectedProvince] = useState<string | number>('');
    const [selectedLocation, setSelectedLocation] = useState<string | number>('');
    const [selectedCatIva, setSelectedCatIva] = useState<string | number>('');
    const [locations, setLocations] = useState<DropdownOption[]>([]);
    const [categoriaIva] = useState([{ label: 'Responsable Inscripto', value: 1 }, { label: 'Monotributista', value: 2 }, { label: 'Consumidor Final', value: 3 }, { label: 'Exento', value: 4 }]);
    const [locationsForFiltering, setLocationsForFiltering] = useState<Location[]>([]);
    const [filteredLocations, setFilteredLocations] = useState<DropdownOption[]>([]);
    const [establishments, setEstablishments] = useState<Establishments[]>([{ id: 0, value: '' }]);
    const [newEstablishments, setNewEstablishments] = useState<Establishments[]>([]);
    const [nextId, setNextId] = useState(1);
    const accessToken: String | null = localStorage.getItem('accessToken');
    const refreshToken = useRefreshToken();

    useEffect(() => {
        const fetchComitente = async () => {
            try {
                const response = await fetchWithAuth(`${API_URL}/comitentes/${id}/`);
                const data = await response.json();
                console.log(data)
                setName(data.nombre)
                setMail(data.email)
                setDoc(data.documento)
                setCuit(data.numero_cuit)
                setStreet(data.direccion)
                setSelectedProvince(data.provincia)
                setSelectedLocation(data.localidad)
                const categoria = categoriaIva.find((cat) => cat.label === data.categoria_iva);
                console.log("categoria " + categoria?.label)
                setSelectedCatIva(categoria ? categoria.label : '');
                setEstablishments(data.establecimientos.map((establecimiento: any) => ({
                    id: establecimiento.id,
                    value: establecimiento.nombre
                })));
            } catch (error) {
                console.error("Error fetching provinces:", error);
            }
        };
        
        fetchComitente();
    }, [id])

    const [validationErrors, setValidationErrors] = useState({
        localidad: '',
        lugar: '',
        nombre: '',
        provincia: '',
        catIva: '',
        calle: '',
        mail: '',
        doc: '',
        cuit: ''
    });

    const handleClose = () => {
        onClose();
    };

    const resetFields = () => {
        setName('');
        setStreet('');
        setMail('');
        setDoc('');
        setSelectedProvince('');
        setSelectedLocation('');
        setCuit('');
        setSelectedCatIva('');
    }

    const handleAddEstablishment = () => {
        setEstablishments([...establishments, { id: nextId, value: '' }]);
        setNewEstablishments([...newEstablishments, { id: nextId, value: '' }]);
        setNextId(nextId + 1); // Incrementa el próximo ID
    };

    const handleRemoveEstablishment = (id: number) => {
        setEstablishments(establishments.filter(establishment => establishment.id !== id));
        setNewEstablishments(newEstablishments.filter(establishment => establishment.id !== id));
    };

    const handleEstablishmentChange = (id: number, value: string) => {
        setEstablishments(
            establishments.map(establishment =>
                establishment.id === id ? { ...establishment, value } : establishment
            )
        );
        setNewEstablishments(newEstablishments.map(establishment =>
            establishment.id === id ? { ...establishment, value } : establishment
        ));
    };

    const handleSubmit = async () => {
        const principalData = {
            nombre: name,
            provincia: selectedProvince,
            localidad: selectedLocation,
            direccion: street,
            email: mail,
            documento: doc,
            categoria_iva: selectedCatIva,
            numero_cuit: cuit
        }
        try {
            const response = await uploadPrincipal(accessToken, principalData);
            if (response.ok) {
                const principalResponse = await response.json();
                const comitenteId = principalResponse.id;
                const updatedEstablishments = subtractArrays(establishments, newEstablishments);
                await Promise.all(updatedEstablishments.map(establishment => 
                    editEstablishment(accessToken, { id: establishment.id, nombre: establishment.value, comitente: comitenteId })
                ));
                await Promise.all(newEstablishments.map(establishment => 
                    uploadEstablishment(accessToken, { id: establishment.id, nombre: establishment.value, comitente: comitenteId })
                ));

                resetFields();
                setEstablishments([{ id: 0, value: '' }]);
                setNextId(1); // Resetea el próximo ID
                toast.success('Comitente cargado con éxito', {
                    position: 'bottom-right'
                });
                window.location.reload();
            }
            else if (response.status == 401) {
                //await refreshToken();
                //handleSubmit(); // Retry after refreshing token
            }
            else {
                setValidationErrors(await response.json());
            }
        } catch (error) {
            toast.error('Algo salió mal, vuelva a intentarlo', {
                position: 'bottom-right'
            });
        }
    }

    async function uploadPrincipal(accessToken: String | null, principalData: any) {
        return await fetchWithAuth(`${API_URL}/comitentes/${id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            },
            body: JSON.stringify(principalData)
        });
    }

    async function editEstablishment(accessToken: String | null, establishmentData: any) {
        return await fetchWithAuth(`${API_URL}/establecimientos/${establishmentData.id}/`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            },
            body: JSON.stringify(establishmentData)
        });
    }

    async function uploadEstablishment(accessToken: String | null, establishmentData: any) {
        return await fetchWithAuth(`${API_URL}/establecimientos/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            },
            body: JSON.stringify(establishmentData)
        });
    }

    useEffect(() => {
        const fetchProvinces = async () => {
            try {
                const response = await fetchWithAuth(`${API_URL}/provincias/`);
                const data = await response.json();
                setProvinces(data.results.map((province: Province) => ({
                    label: province.nombre,
                    value: province.id
                })));
            } catch (error) {
                console.error("Error fetching provinces:", error);
            }
        };

        const fetchLocations = async () => {
            let url = `${API_URL}/localidades/?limit=10000`;
            let allLocations: DropdownOption[] = [];
            let locationsForFiltering: Location[] = [];
            try {
                while (url) {
                    const response = await fetchWithAuth(url);
                    const data = await response.json();
                    const locationsBatch = data.results.map((location: Location) => ({
                        label: location.nombre,
                        value: location.id
                    }));

                    const locationBatchForFiltering = data.results;

                    allLocations = [...allLocations, ...locationsBatch];
                    locationsForFiltering = [...locationsForFiltering, ...locationBatchForFiltering]
                    url = data.next;
                }

                setLocationsForFiltering(locationsForFiltering);
                setLocations(allLocations);
            } catch (error) {
                console.error("Error fetching locations:", error);
            }
        };

        fetchProvinces();
        fetchLocations();
    }, []);

    useEffect(() => {
        if (selectedProvince) {
            const filtered = locationsForFiltering.filter((location) => location.provincia === selectedProvince).map((location: Location) => ({
                label: location.nombre,
                value: location.id
            }));
            setFilteredLocations(filtered);
        } else {
            setFilteredLocations(locations);
        }
    }, [selectedProvince, locations]);


    
    const findByValue = (array: DropdownOption[], value: string | number) => {
        const result = array.find(el => el.value.toString() === value.toString() || el.label === value.toString());
        return result ? result.label : '';
    }

    function subtractArrays(array1: any[], array2: any[]): any[] {
        const idsToSubtract = new Set(array2.map(item => item.id));
        return array1.filter(item => !idsToSubtract.has(item.id));
    }

    return (
        <StyledDialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
            <StyledDialogContent>
                <Header>
                    <HeaderComponent title={"Ficha Comitentes"} subtitle="" />
                    <IconButton onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </Header>
                <FormContainer>
                    <InputContainer>
                        <BorderInputComponent
                            placeholder='Nombre'
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            width={isMobile() ? 249 : 600}
                            hasError={validationErrors.nombre !== ''}
                            marginBottom={10}
                        />
                        <LabelComponent text={validationErrors.nombre !== '' ? '*Campo Obligatorio' : ''} color={RED_COLOR} fontSize={12} padding={0} />
                    </InputContainer>

                    <InputContainer>
                        <BorderDropdownComponent marginBottom={10} width={isMobile() ? 279 : 632} color={PLACEHOLDER_FONT_COLOR} hasError={validationErrors.provincia !== ''} placeholder={findByValue(provinces, selectedProvince.toString())} options={provinces} onChange={(value) => setSelectedProvince(value)} />
                        <LabelComponent text={validationErrors.provincia !== '' ? '*Campo Obligatorio' : ''} color={RED_COLOR} fontSize={12} padding={0} />
                    </InputContainer>
                    <InputContainer>
                        <BorderDropdownComponent marginBottom={10} width={isMobile() ? 279 : 632} color={PLACEHOLDER_FONT_COLOR} hasError={validationErrors.localidad !== ''} placeholder={findByValue(locations, selectedLocation.toString())} options={filteredLocations} disabled={selectedProvince === ''} onChange={(value) => setSelectedLocation(value)} />
                        <LabelComponent text={validationErrors.localidad !== '' ? '*Campo Obligatorio' : ''} color={RED_COLOR} fontSize={12} padding={0} />
                    </InputContainer>
                    <InputContainer>
                        <BorderInputComponent
                            placeholder='Calle / N° / Piso'
                            value={street}
                            onChange={(e) => setStreet(e.target.value)}
                            width={isMobile() ? 249 : 600}
                            hasError={validationErrors.calle !== ''}
                            marginBottom={10}
                        />
                        <LabelComponent text={validationErrors.calle !== '' ? '*Campo Obligatorio' : ''} color={RED_COLOR} fontSize={12} padding={0} />
                    </InputContainer>

                    <InputContainer>
                        <BorderInputComponent
                            placeholder='Mail'
                            value={mail}
                            onChange={(e) => setMail(e.target.value)}
                            width={isMobile() ? 249 : 600}
                            hasError={validationErrors.mail !== ''}
                            marginBottom={10}
                        />
                        <LabelComponent text={validationErrors.mail !== '' ? '*Campo Obligatorio' : ''} color={RED_COLOR} fontSize={12} padding={0} />
                    </InputContainer>
                    <InputContainer>
                        <BorderInputComponent
                            placeholder='N° doc.'
                            value={doc}
                            onChange={(e) => setDoc(e.target.value)}
                            width={isMobile() ? 249 : 600}
                            hasError={validationErrors.doc !== ''}
                            marginBottom={10}
                        />
                        <LabelComponent text={validationErrors.doc !== '' ? '*Campo Obligatorio' : ''} color={RED_COLOR} fontSize={12} padding={0} />
                    </InputContainer>
                    <InputContainer>
                        <BorderDropdownComponent marginBottom={10} width={isMobile() ? 279 : 632} color={PLACEHOLDER_FONT_COLOR} hasError={validationErrors.catIva !== ''} placeholder={selectedCatIva.toString()} useLabel={true} options={categoriaIva} onChange={(value) => setSelectedCatIva(value)} />
                        <LabelComponent text={validationErrors.catIva !== '' ? '*Campo Obligatorio' : ''} color={RED_COLOR} fontSize={12} padding={0} />
                    </InputContainer>
                    <InputContainer>
                        <BorderInputComponent
                            placeholder='N° de CUIT'
                            value={cuit}
                            onChange={(e) => setCuit(e.target.value)}
                            width={isMobile() ? 249 : 600}
                            hasError={validationErrors.cuit !== ''}
                            marginBottom={10}
                        />
                        <LabelComponent text={validationErrors.cuit !== '' ? '*Campo Obligatorio' : ''} color={RED_COLOR} fontSize={12} padding={0} />
                    </InputContainer>
                    <InputContainer>
                        {establishments.map((establishment, index) => (
                            <BorderInputComponentsWithPlusAndMinus
                                key={establishment.id}
                                placeholder={`Establecimiento ${index + 1}`}
                                value={establishment.value}
                                onChange={(e) => handleEstablishmentChange(establishment.id, e.target.value)}
                                onAddClick={handleAddEstablishment}
                                onRemoveClick={() => handleRemoveEstablishment(establishment.id)}
                                width={isMobile() ? 249 : 600}
                                hasError={false}
                                marginBottom={10}
                                isParent={index === 0}
                            />
                        ))}
                        <LabelComponent text={validationErrors.cuit !== '' ? '*Campo Obligatorio' : ''} color={RED_COLOR} fontSize={12} padding={0} />
                    </InputContainer>
                </FormContainer>
                <FooterContainer>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                        <PrimaryButtonComponent width="150" borderRadius={30} onClick={handleSubmit}>Guardar</PrimaryButtonComponent>
                    </div>
                </FooterContainer>
            </StyledDialogContent>
            <ToastContainer />
        </StyledDialog>
    );
};

export default EditPrincipal;

const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    border-radius: 19px;
  }
`;

const StyledDialogContent = styled(DialogContent)`
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  overflow-x: hidden;
`;

const Header = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  .MuiTypography-h5 {
    font-size: 24px;
    font-weight: bold;
  }
`;

const FormContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const StyledSelect = styled(Select)`
  & .MuiSelect-select {
    padding: 8px;
    border: none;
    box-shadow: none;
  }
  & .MuiOutlinedInput-notchedOutline {
    border: none;
  }
  &.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: none;
  }
`;

const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 600px;
`;

const FooterContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 30px;
    margin-left: 90px;
    margin-right: 90px;
`;
