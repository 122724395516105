import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogContent,
    IconButton,
    Box,
    Select,
    MenuItem,
    SelectChangeEvent
} from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';
import DownloadIcon from '@mui/icons-material/Download';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import styled from 'styled-components';
import { HeaderComponent } from '../../components/common/HeaderComponent';
import { BorderInputComponent } from '../../components/form/inputs/BorderInputComponent';
import { PRIMARY_COLOR, WHITE } from '../../constants/colors.constants';
import { LabelComponent } from '../../components/form/basics/LabelComponent';
import TableComponent from '../../components/table/TableComponent';
import { API_URL } from '../../constants/config.constants';
import { useSelector } from 'react-redux';
import { RootState } from '../../app/Store';
import { useNavigate } from 'react-router-dom';
import { fetchWithAuth } from '../Auth/AuthUtils';

export interface User {
    id?: number;
    username: string;
    firstName: string;
    lastName: string;
    password?: string;
}



interface AvailableUsersPageProps {
    open: boolean;
    onClose: () => void;
    onAddUser: () => void;
    onEditUser: (userId: number | undefined) => void
}

const AvailableUsersPage: React.FC<AvailableUsersPageProps> = ({ open, onClose, onAddUser, onEditUser }) => {
    const [search, setSearch] = useState('');
    const [users, setUsers] = useState<User[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(4);
    const accessToken: String | null = useSelector((state: RootState) => state.auth.accessToken);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await fetchWithAuth(`${API_URL}/users/`);

                if (!response.ok) {
                    console.error('Error fetching users:', response.statusText);
                    return;
                }

                const data = await response.json();

                // Check if data.results is an array before setting it to users
                if (data && Array.isArray(data.results)) {
                    setUsers(data.results.map((user: any) => ({
                        id: user.id,
                        username: user.username,
                        firstName: user.first_name || user.username,
                        lastName: user.last_name || user.username
                    })));
                } else {
                    console.error('API did not return expected results:', data);
                }
            } catch (error) {
                console.error('Error fetching users:', error);
            }
        };

        fetchUsers();
    }, []);

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
    };

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };

    const filteredUsers = users.filter(user =>
        user.firstName.toLowerCase().includes(search.toLowerCase())
    );

    const paginatedUsers = filteredUsers.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage);

    const handleRowsPerPageChange = (event: SelectChangeEvent<unknown>) => {
        setRowsPerPage(event.target.value as number);
        setCurrentPage(1); // Reset to first page when rows per page changes
    };

    return (
        <StyledDialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <StyledDialogContent>
                <Header>
                    <HeaderComponent title={"Usuarios Habilitados"} subtitle="" />
                    <IconButton onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </Header>

                <ActionsContainer>
                    <BorderInputComponent
                        placeholder='Buscar'
                        value={search}
                        onChange={handleSearch}
                        width={250}
                        hasError={false}
                        marginBottom={10}
                    />
                    <Actions>
                        <IconButton onClick={onAddUser}>
                            <AddIcon style={{color: WHITE, backgroundColor: PRIMARY_COLOR, borderRadius: 100, padding: 5}} />
                        </IconButton>
                    </Actions>
                </ActionsContainer>
                <TableContainer>
                    <StyledTable>
                        <StyledTableHead>
                            <TableHeader> Usuarios Habilitados </TableHeader>
                        </StyledTableHead>
                        <TableBody>
                            {paginatedUsers.map((user, index) => (
                                <StyledTableRow key={index}>
                                    <EditIcon onClick={() => onEditUser(user.id)}/>
                                    <AccountCircleIcon />
                                    <TableRow>{user.firstName}</TableRow>
                                    <TableRow>{user.lastName}</TableRow>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </StyledTable>
                </TableContainer>
                <PaginationContainer>
                    <LabelComponent text={'Filas por Página'} color='#697077' />
                    <StyledSelect
                        value={rowsPerPage}
                        onChange={handleRowsPerPageChange}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Rows per page' }}
                    >
                        <MenuItem value={4}>4</MenuItem>
                        <MenuItem value={6}>6</MenuItem>
                        <MenuItem value={8}>8</MenuItem>
                    </StyledSelect>

                    <Pagination>
                        <PaginationButton onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>❮</PaginationButton>
                        {[...Array(Math.ceil(filteredUsers.length / rowsPerPage)).keys()].map(page => (
                            <PaginationButton key={page + 1} onClick={() => handlePageChange(page + 1)} active={page + 1 === currentPage}>{page + 1}</PaginationButton>
                        ))}
                        <PaginationButton onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === Math.ceil(filteredUsers.length / rowsPerPage)}>❯</PaginationButton>
                    </Pagination>
                </PaginationContainer>
            </StyledDialogContent>
        </StyledDialog>
    );
};

export default AvailableUsersPage;

const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    border-radius: 19px;
  }
`;

const StyledDialogContent = styled(DialogContent)`
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  overflow-x: hidden;
`;

const Header = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  .MuiTypography-h5 {
    font-size: 24px;
    font-weight: bold;
  }
`;

const Actions = styled(Box)`
  display: flex;
  flex: 2;
  justify-content: flex-end;
  margin-bottom: 20px;
  align-items: center;


  .MuiIconButton-root {
    color: #0f4c81;
  }
`;


const StyledTable = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;

`
const TableContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    
`
const TableBody = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 10px;
`

const StyledTableHead = styled.div`
  background-color: ${PRIMARY_COLOR};
  border-radius: 18px;
  color: white;
  font-weight: bold;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.1);
`;

const StyledTableRow = styled.div`
  border-radius: 18px;
  background-color: white;
  padding-left: 20px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  border: 1px solid rgba(33, 39, 42, 0.1);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.1);
  &:hover {
    border: 1px solid rgba(33, 39, 42, 0.2);
  }

  @media (max-width: 768px) {
    padding-left: 15px;
}

`;


const PaginationContainer = styled(Box)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
  gap: 5px;
`;

const Pagination = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const PaginationButton = styled.button<{ active?: boolean }>`
  background: none;
  border: none;
  color: ${({ active }) => (active ? PRIMARY_COLOR : '#697077')};
  font-weight: ${({ active }) => (active ? 'bold' : 'normal')};
  cursor: pointer;
  &:disabled {
    color: #ccc;
    cursor: not-allowed;
  }
`;
const ActionsContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

const TableHeader = styled.p`
    padding-left: 10px;
    color: white;
`

const TableRow = styled.p`
    padding-left: 10px;
    color: #21272A;
`

const StyledSelect = styled(Select)`
  & .MuiSelect-select {
    padding: 8px;
    border: none;
    box-shadow: none;
  }
  & .MuiOutlinedInput-notchedOutline {
    border: none;
  }
  &.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: none;
  }
`;
