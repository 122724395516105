import styled from "styled-components";
import SimpleNavbar from "../../components/shared/SimpleNavBar";
import { BLACK_FONT, PLACEHOLDER_FONT_COLOR, RED_COLOR, WHITE } from "../../constants/colors.constants";
import CowsBackgroundImage from '../../assets/login-background.png';
import { LabelComponent } from "../../components/form/basics/LabelComponent";
import BorderDropdownComponent from "../../components/form/dropdowns/BorderDropdownComponent";
import { useEffect, useState } from "react";
import { FaenaBatchForm } from "./forms/FaenaBatchForm";
import { InvernadaBatchForm } from "./forms/InvernadaBatchForm";
import { VientresBatchForm } from "./forms/VientresBatchForm";
import { categories, formulary } from "../../constants/dropdowns/batchs/batchDataDropdown";
import { API_URL } from "../../constants/config.constants";
import MultiSelectComponent from "../../components/form/dropdowns/MultiSelectComponent";
import { useNavigate, useParams } from "react-router-dom";
import { isMobile } from "../../utils/utils";
import { MobileNavBar } from "../../components/shared/MobileNavBar";
import { CrossIconButtonComponent } from "../../components/buttons/CrossIconButtonComponent";
import { fetchWithAuth } from "../Auth/AuthUtils";

const StyledContainer = styled.div`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(${CowsBackgroundImage});
  background-size: cover;
`;

const FormContainer = styled.div`
  width: 900px;
  height: 749px;
  background-color: ${WHITE};
  border-radius: 12px;
  padding-left: 40px;
  padding-right: 40px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-bottom: 40px;

  @media (max-width: 769px) {
        height: 90%;
        width: 80%;
        overflow-y: scroll;
        padding-left: 10px;
        padding-right: 0px;
    }
`;

const HeaderFormContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const StyledTitle = styled.h2`
  color: ${BLACK_FONT};
  font-size: 32px;
`;

const InputsFormContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 30px;
`;

const DividerLine = styled.div`
  border: 1.5px solid ${PLACEHOLDER_FONT_COLOR};
`;

export const UploadBatchPage = () => {
  const { auctionId }  = useParams<{ auctionId: string }>();
  const [comitentes, setComitentes] = useState([]);
  const [establecimientos, setEstablecimientos] = useState([]);
  const [filteredEstablecimientos, setFilteredEstablecimientos] = useState<any>([]);
  const [isFormularioEnabled, setIsFormularioEnabled] = useState<boolean>(false);

  const [formData, setFormData] = useState({
    formulario: '',
    comitente: '',
    establecimiento: [],
    categoria: []
  });

  const fetchComitentes = async () => {
    try {
        const response = await fetchWithAuth(`${API_URL}/comitentes/`);
        const data = await response.json();
      setComitentes(data.results);
    } catch (error) {
        console.error("Error fetching comitentes:", error);
    }
};

  const fetchEstablecimientosByComitenteId = async () => {
    try {
      const response = await fetchWithAuth(`${API_URL}/establecimientos/`);
      const data = await response.json();
      setEstablecimientos(data.results);
  } catch (error) {
      console.error("Error fetching establecimientos:", error);
  }
  }

  useEffect(() => {
    fetchComitentes().then(() => {
      fetchEstablecimientosByComitenteId();
    });
  }, []);

  useEffect(() => {
    setFilteredEstablecimientos(establecimientos.filter((establecimiento: any) => establecimiento.comitente === formData.comitente)
      .map((establecimiento: any) => ({
        label: establecimiento.nombre,
        value: establecimiento.id
      }))
    );
  }, [formData.comitente]);

  useEffect(() => {
    if (formData.categoria.length > 0 && formData.establecimiento.length > 0 && formData.comitente) {
      setIsFormularioEnabled(true);
    } else {
      setIsFormularioEnabled(false);
    }
  }, [formData])

  const navigate = useNavigate();

  return (
    <div style={{ height: '90vh' }}>
       {isMobile() ? <MobileNavBar/> : <SimpleNavbar showUserMenu={true} />}
      <StyledContainer>
        <FormContainer>
          <HeaderFormContainer>
            <StyledTitle>Nuevo Lote</StyledTitle>
            <CrossIconButtonComponent onClick={() => navigate(-1)} />
          </HeaderFormContainer>
         <InputsFormContainer>
            <InputContainer>
              <BorderDropdownComponent
                width={isMobile() ? 250 : 583}
                color={PLACEHOLDER_FONT_COLOR}
                placeholder={"Formulario *"}
                options={formulary}
                onChange={(e) => setFormData(prevState => ({
                    ...prevState,
                    formulario: e
                  }))}
                 

                marginBottom={5}
              />

            </InputContainer>
            <InputContainer>
              <BorderDropdownComponent
                width={isMobile() ? 250 : 583}
                color={PLACEHOLDER_FONT_COLOR}
                placeholder={"Comitente *"}
                options={comitentes.map((comitente: any) => ({
                  label: comitente.nombre,
                  value: comitente.id
              }))}
                onChange={(e) => setFormData(prevState => ({
                  ...prevState,
                  comitente: e
                }))}
                marginBottom={5}
              />
              <LabelComponent text={''} color={RED_COLOR} fontSize={12} padding={0} />
            </InputContainer>
            <InputContainer>
              <MultiSelectComponent
                width={isMobile() ? 250 : 583}
                color={PLACEHOLDER_FONT_COLOR}
                placeholder={"Establecimiento *"}
                options={filteredEstablecimientos}
                onChange={(e) => setFormData(prevState => ({
                  ...prevState,
                  establecimiento: e
                }))}
                marginBottom={5}
              />
              <LabelComponent text={''} color={RED_COLOR} fontSize={12} padding={0} />
            </InputContainer>
            <InputContainer>
              <MultiSelectComponent
                width={isMobile() ? 250 : 583}
                color={PLACEHOLDER_FONT_COLOR}
                placeholder={"Categoría *"}
                options={categories}
                onChange={(e) => setFormData(prevState => ({
                  ...prevState,
                  categoria: e
                }))}
                marginBottom={5}
              />
              <LabelComponent text={''} color={RED_COLOR} fontSize={12} padding={0} />
            </InputContainer>
          </InputsFormContainer>
          {
            (formData.formulario !== '' && formData.comitente !== '' && formData.establecimiento.length >= 1 && formData.categoria.length >= 1)
              && (
                <>
                  {formData.formulario !== '' && <DividerLine/>}
                  {
                    formData.formulario === 'Faena' ?
                    <FaenaBatchForm formData={formData} auctionId={auctionId} /> : (formData.formulario === 'Invernada') ?
                    <InvernadaBatchForm formData={formData} auctionId={auctionId}/> : (formData.formulario === 'Vientres') &&
                    <VientresBatchForm formData={formData}auctionId={auctionId} />

                  }
                </>
              )
          }
        </FormContainer>
      </StyledContainer>
    </div>
  )
}
