import { FaArrowLeft, FaPencilAlt, FaTimes, FaWhatsapp, FaRegSave } from 'react-icons/fa';
import { PrimaryButtonComponent } from './PrimaryButtonComponent';
import { IconType } from 'react-icons/lib';
import styled from 'styled-components';
import { PRIMARY_COLOR, WHITE } from '../../constants/colors.constants';
import { MouseEventHandler } from 'react';

interface PrimaryButtonWithIconProps {
    icon: IconType;
    text: string;
    onClick?: MouseEventHandler<HTMLButtonElement>;
    borderRadius?: number;
    backgroundColor?: string;
    textColor?: string;
    iconColor?: string;
    width?: string;
    height?: string;
    fontSize?: string;
    padding?: string;
}

const iconMap: { [key: string]: React.ElementType } = {
    FaPencilAlt: FaPencilAlt,
    FaTimes: FaTimes,
    FaArrowLeft: FaArrowLeft,
    FaWhatsapp: FaWhatsapp,
    FaRegSave: FaRegSave
};

const ContentButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const TextButton = styled.span<{ textColor?: string }>`
    color: ${(props) => props.textColor || WHITE};
    margin-left: 8px;
`;

export const PrimaryButtonWithIconComponent = ({
    icon,
    text,
    onClick,
    borderRadius = 12,
    backgroundColor = PRIMARY_COLOR,
    textColor,
    iconColor,
    width = '100%',
    height = '48px',
    fontSize = '14px',
    padding = '10px 20px;'
}: PrimaryButtonWithIconProps) => {
    const IconComponent = icon ? iconMap[icon.name] : undefined;
    
    return (
        <PrimaryButtonComponent 
            onClick={onClick}
            borderRadius={borderRadius}
            backgroundColor={backgroundColor}
            width={width}
            height={height}
            fontSize={fontSize}
            padding={padding}
        >
            <ContentButtonContainer>
                {IconComponent && <IconComponent color={iconColor} />}
                <TextButton textColor={textColor}>{text}</TextButton>
            </ContentButtonContainer>
        </PrimaryButtonComponent>
    );
};
