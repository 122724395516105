import styled from "styled-components";
import { BLACK_FONT, PLACEHOLDER_FONT_COLOR, RECTANGULAR_INPUT_BACKGROUND_COLOR, RECTANGULAR_INPUT_BORDER_BOTTOM_COLOR, RED_COLOR } from "../../../constants/colors.constants";

interface BorderInputProps {
    label?: string;
    value: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    placeholder?: string;
    width?: number;
    hasError?: boolean;
    marginBottom?: number;
    disabled?: boolean;
    type?: string;
    title?: string;
}

const InputContainer = styled.div<{marginBottom?: number, disabled?: boolean}>`
  display: flex;
  flex-direction: column;
  margin-bottom: ${(props) => props.marginBottom}px;
  pointer-events: ${(props) => props.disabled ? 'none' : 'unset'};
`;

const Label = styled.label`
  margin-bottom: 10px;
  text-align: left;
  color: ${BLACK_FONT};
  font-size: 14px;
  font-weight: bold;
  margin-left: 5px;
`;

const InputField = styled.input<{width?: number, hasError?: boolean}>`
  padding: 16px;
  border: ${(props) => props.hasError ? `2px solid ${RED_COLOR}` : 'none'};
  border-radius: 19px;
  background-color: ${RECTANGULAR_INPUT_BACKGROUND_COLOR};
  width: ${(props) => props.width}px;
  height: 21px;
  outline: none;
  font-size: 16px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.4);
  
  &::placeholder {
    color: ${PLACEHOLDER_FONT_COLOR};
  }
`;

export const BorderInputComponent = ({
    label,
    value,
    onChange,
    width = 520,
    hasError = false,
    marginBottom = 20,
    disabled = false,
    type = "text",
    title,
    ...props
}: BorderInputProps) => {
  return (
    <InputContainer marginBottom={marginBottom} disabled={disabled}>
      <Label>{label}</Label>
      <InputField 
        title={title}
        type={type}
        width={width}
        value={value}
        onChange={onChange}
        hasError={hasError}
        {...props} 
      />
    </InputContainer>
  );
};
