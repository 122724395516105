import React, { useState } from 'react';
import {
    Dialog,
    DialogContent,
    IconButton,
    Box
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import styled from 'styled-components';
import { BLACK_FONT, PRIMARY_COLOR, WHITE } from '../../constants/colors.constants';
import Logo from '../../assets/logo-pag-principal.png';
import SeparatorComponent from '../../components/basics/SeparatorComponent';
import { RectangularInputComponent } from '../../components/form/inputs/RectangularInputComponent';
import { RectangularPasswordInputComponent } from '../../components/form/inputs/RectangularPasswordInputComponent';
import { PrimaryButtonComponent } from '../../components/buttons/PrimaryButtonComponent';
import { API_URL } from '../../constants/config.constants';
import { useSelector } from 'react-redux';
import { RootState } from '../../app/Store';
import { fetchWithAuth, useRefreshToken } from '../Auth/AuthUtils';
import { isMobile } from '../../utils/utils';
import { ToastContainer, toast } from 'react-toastify';

const LogoImage = styled.img`
    width: 109px;
    height: 109px;

    @media (max-width: 768px) {
        width: 80px;
        height: 80px;
    }
`;

const FormContentContainer = styled.div`
    display: flex;
    flex-direction: row;
    height: 500px;
    justify-content: space-between;
    align-items: center;
    padding: 14px;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

const FormLogoTitleContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

const FormLabelsContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 40px;

    @media (max-width: 768px) {
        margin-left: unset;
        margin-top: 15px;
    }
`;

const FormLabel = styled.span<{ bold?: number }>`
    color: ${PRIMARY_COLOR};
    font-size: 16px;
    font-weight: ${(props) => props.bold};
    margin-bottom: 8px;

    @media (max-width: 768px) {
        text-align: center;
    }
`;

const FormInputsContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const FormNameSurnameContainer = styled.div`
    display: flex;
    flex-direction: row;
`;

const StyledSaveContainer = styled.div`
    @media (max-width: 768px) {
        display: flex;
        justify-content: center;   
        margin-bottom: 30px;
    }
`;

interface NewAccountPageProps {
    open: boolean;
    onClose: () => void;
}

const NewAccountPage: React.FC<NewAccountPageProps> = ({ open, onClose }) => {
    const [name, setName] = useState<string>('');
    const [lastname, setLastname] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [repeatedPassword, setRepeatedPassword] = useState<string>('');
    const accessToken: String | null = useSelector((state: RootState) => state.auth.accessToken);
    const refreshToken = useRefreshToken();

    const handleSubmit = async () => {
        if (password !== repeatedPassword) {
            alert("Las contraseñas no coinciden");
            return;
        }

        const newUser: any = {
            username: email,
            first_name: name,
            last_name: lastname,
            password: password
        };

        try {
            const response = await uploadAccount(accessToken, newUser);

            if (response.ok) {
                toast.success('Nueva cuenta creada con éxito', {
                    position: 'bottom-right'
                });
                window.location.reload();
            } else {
                refreshToken();
            }
        } catch (error) {
            console.error("Error:", error);
            toast.error('Error al crear nueva cuenta', {
                position: 'bottom-right'
            });
        }
    };

    async function uploadAccount(accessToken: String | null, accountData: any) {
        return await fetchWithAuth(`${API_URL}/users/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            },
            body: JSON.stringify(accountData)
        });
    }

    return (
        <StyledDialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <StyledDialogContent>
                <Header>
                    <HeaderTitle>Mi Cuenta</HeaderTitle>
                    <IconButton onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </Header>
                <FormContentContainer>
                    <FormLogoTitleContainer>
                        <LogoImage src={Logo} alt="Logo de Reggi" />
                        <FormLabelsContainer>
                            <FormLabel bold={600}>Nombre de usuario</FormLabel>
                            <FormLabel>Mail</FormLabel>
                        </FormLabelsContainer>
                    </FormLogoTitleContainer>

                    <SeparatorComponent />

                    <FormInputsContainer>
                        <FormNameSurnameContainer>
                            <RectangularInputComponent
                                label="Nombre"
                                placeholder="Nombre"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                width={isMobile() ?  131 : 161}
                            />
                            <div style={{ marginLeft: 12, marginRight: 12 }}></div>
                            <RectangularInputComponent
                                label="Apellido"
                                placeholder="Apellido"
                                value={lastname}
                                onChange={(e) => setLastname(e.target.value)}
                                width={isMobile() ?  131 : 161}
                            />
                        </FormNameSurnameContainer>

                        <RectangularInputComponent
                            label="Mail"
                            placeholder="Mail"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            width={isMobile() ? 279 : 365}
                        />

                        <RectangularPasswordInputComponent
                            label="Contraseña"
                            placeholder="Contraseña"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            width={isMobile() ? 279 : 365}
                        />
                        <RectangularPasswordInputComponent
                            label="Repetir Contraseña"
                            placeholder="Contraseña"
                            value={repeatedPassword}
                            onChange={(e) => setRepeatedPassword(e.target.value)}
                            width={isMobile() ? 279 : 365}
                        />

                        <StyledSaveContainer>
                            <PrimaryButtonComponent
                                onClick={handleSubmit}
                                width="30%"
                            >
                                Guardar
                            </PrimaryButtonComponent>
                        </StyledSaveContainer>
                    </FormInputsContainer>
                </FormContentContainer>
            </StyledDialogContent>
            <ToastContainer />
        </StyledDialog>
    );
};

export default NewAccountPage;

const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    border-radius: 19px;
  }
`;

const StyledDialogContent = styled(DialogContent)`
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  overflow-x: hidden;
`;

const Header = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  .MuiTypography-h5 {
    font-size: 24px;
    font-weight: bold;
  }
`;

const HeaderTitle = styled.h2`
    color: ${BLACK_FONT};
    font-size: 42px;
`;
