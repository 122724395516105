import styled from "styled-components";
import SimpleNavbar from "../../components/shared/SimpleNavBar";
import { PLACEHOLDER_FONT_COLOR, RED_COLOR, WHITE } from "../../constants/colors.constants";
import Logo from '../../assets/logo-pag-principal.png';
import { IconButtonComponent } from "../../components/buttons/IconButtonComponent";
import { RectangularInputComponent } from "../../components/form/inputs/RectangularInputComponent";
import { useEffect, useState } from "react";
import { RectangularPasswordInputComponent } from "../../components/form/inputs/RectangularPasswordInputComponent";
import { LabelComponent } from "../../components/form/basics/LabelComponent";
import { ForgetMyPasswordFooterComponent } from "../../components/form/ForgetMyPasswordFooterComponent";
import { PrimaryButtonComponent } from "../../components/buttons/PrimaryButtonComponent";
import CowsBackgroundImage from '../../assets/login-background.png';
import { authUser, selectAuth } from "./AuthSlice";
import { useAppDispatch, useAppSelector } from "../../app/Hooks";
import { useNavigate } from "react-router-dom";
import { fetchWithAuth, useLogIn } from "./AuthUtils";
import { API_URL } from "../../constants/config.constants";
import { isMobile } from "../../utils/utils";
import LoginNavBar from "../../components/shared/LoginNavBar";

const FormContainer = styled.div`
    height: 487px;
    width: 601px;
    background-color: ${WHITE};
    border-radius: 19px;
    padding: 20px;

    @media (max-width: 768px) {
        width: 80%;
        height: auto;
        padding: 10px;
        padding-bottom: 30px;
    }
`;

const BackgroundImage = styled.div`
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url(${CowsBackgroundImage});
    background-size: cover;
    background-position: center;

    @media (max-width: 768px) {
        background-size: cover;
    }
`;

const HeaderForm = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 20px;

    @media (max-width: 768px) {
        padding: 10px;
    }
`;

const LogoImage = styled.img`
    width: 109px;
    height: 109px;

    @media (max-width: 768px) {
        width: 80px;
        height: 80px;
    }
`;

const InputsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const LabelContainer = styled.div`
    width: 100%;
`;

export const LoginPage = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate();
    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [isChecked, setIsChecked] = useState(false);
    const [showAuthError, setShowAuthError] = useState(false);
    const logIn = useLogIn();

    const toggleRemindCheckbox = () => {
      setIsChecked(!isChecked);
    };
    
    const handleSubmit = async () => {
        setShowAuthError(false);
        const url = `${API_URL}/auth/token/`;
        const body = {
            username: username,
            password: password
        };
    
        try {
            const response = await fetchWithAuth(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            });
    
            if (!response.ok) {
                throw new Error('Network response was not ok ' + response.statusText);
            }
            const data = await response.json();

            logIn(data, username);
        } catch (error) {
            setShowAuthError(true);
            console.error('Failed to fetch:', error);
        }
        
    }

    return (
        <div style={{ height: '100%'}}>
            <LoginNavBar />
            <BackgroundImage>
                <FormContainer>
                    <HeaderForm>
                        <div><span></span></div>
                        <LogoImage src={Logo} alt="Logo de Reggi" />
                        <div></div>
                    </HeaderForm>
                    <InputsContainer>
                        <RectangularInputComponent
                            label="Nombre"
                            placeholder="Nombre"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            width={isMobile() ? 220 : undefined}
                        />
                        <RectangularPasswordInputComponent
                            label="Contraseña"
                            placeholder="Contraseña"
                            value={password}
                            onChange={(e) => setPassword(e.target.value) }
                            width={isMobile() ? 200 : 500}
                        />
                    </InputsContainer>
                    <LabelContainer>
                        <LabelComponent
                            text={showAuthError ? 'Datos incorrectos, vuelva a intentarlo.' : ''}
                            color={RED_COLOR}
                            fontSize={12}
                            padding={25}
                        />
                    </LabelContainer>
                    <ForgetMyPasswordFooterComponent
                        labelCheckbox={"Recordarme"}
                        isChecked={isChecked}
                        toggleCheckbox={toggleRemindCheckbox}
                        redirectLabel={"Olvidaste tu contraseña?"}
                        onRedirectClick={() => navigate('/auth/forget-password')}
                    />

                    <PrimaryButtonComponent
                        onClick={() => handleSubmit()}
                        width = '92%'
                        borderRadius={10}
                    >
                        Ingresar
                    </PrimaryButtonComponent>
                </FormContainer>
            </BackgroundImage>
        </div>
    )
}
