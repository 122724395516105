import { API_URL } from "../../constants/config.constants";
import { DropdownOption } from "../../model/DropdownOption";
import { fetchWithAuth } from "../Auth/AuthUtils";


export const convertListToMap = (list: any): Map<string, any[]> => {
    const map = new Map<string, any[]>();
    list.forEach((batch: any) => {
      const category = batch.categoria[0];
      if (!map.has(category)) {
        map.set(category, []);
      }
      map.get(category)?.push(batch);
    });
  
    return map;
  }

export const orderBatchesBasedOnOrderedCategories = (orderedCategories: any[], batches: any[]) => {
    const newOrderedBatches: any[] = [];
    const batchesMap = convertListToMap(batches);
    orderedCategories.forEach((category: DropdownOption) => {
        let batchesForCategory = batchesMap.get(category.label)
        if (batchesForCategory) {
            batchesForCategory.map((batch: any, i: any) => {
                newOrderedBatches.push({
                    lote_type: batch.lote_type,
                    lote_id: batch.id,
                    orden: i + 1
                });
            });
        }

        

    })
    return newOrderedBatches;
}


export const fetchProvinceName = async (provinceId: number) => {
  try {
      const response = await fetchWithAuth(`${API_URL}/provincias/${provinceId}`, {
          method: 'GET',
      });
      if (!response.ok) {
          throw new Error('Network response was not ok');
      }
      const responseData = await response.json();
      return responseData
  } catch (e) {
      console.error("Error fetching province name:", e);
  }
}

export const fetchLocalityName = async (localityId: number) => {
  try {
      const response = await fetchWithAuth(`${API_URL}/localidades/${localityId}`, {
          method: 'GET',
      });
      if (!response.ok) {
          throw new Error('Network response was not ok');
      }
      const responseData = await response.json();
      return responseData
  } catch (e) {
      console.error("Error fetching locality name:", e);
  }
}