import styled from "styled-components"
import { FaTimes  } from 'react-icons/fa';
import { PRIMARY_COLOR, WHITE } from "../../constants/colors.constants";
import { MouseEventHandler } from "react";

interface IconButtonProps {
    onClick?: MouseEventHandler<SVGElement> | undefined
    size?: number;
}

const StyledIcon = styled(FaTimes )`
    color: ${WHITE};
    background-color: ${PRIMARY_COLOR};
    border-radius: 20px;
    padding: 5px;
    cursor: pointer;

    @media (max-width: 768px) {
        display: none;
    }
`;

export const CrossIconButtonComponent = ({ onClick, size = 20 }: IconButtonProps) => {
    return (
        <StyledIcon onClick={onClick} size={size} />
    )
}

