// components/ProtectedRoute.js
import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { RootState } from '../../app/Store';




const ProtectedRoute = () => {
    let isLoggedIn: boolean = true;
    let accessToken: String | null= useSelector((state: RootState) => state.auth.accessToken);
    if (accessToken == null) {
        const localStorageAT = localStorage.getItem('accessToken');
        if (localStorageAT == null) {
            isLoggedIn = false;
        }
    }

    return isLoggedIn ? <Outlet /> : <Navigate to="/auth/login" />;
};

export default ProtectedRoute;
