import React from "react";
import styled from "styled-components"
import CowsBackgroundImage from '../../assets/login-background.png';
import { BLACK_FONT, PLACEHOLDER_FONT_COLOR, PRIMARY_COLOR, RED_COLOR, WHITE } from "../../constants/colors.constants";
import { IconButtonComponent } from "../../components/buttons/IconButtonComponent";
import { useEffect, useState } from "react";
import { BorderInputComponent } from "../../components/form/inputs/BorderInputComponent";
import BorderDropdownComponent from "../../components/form/dropdowns/BorderDropdownComponent";
import { PrimaryButtonComponent } from "../../components/buttons/PrimaryButtonComponent";
import { SeleccionableButtonComponent } from "../../components/buttons/SeleccionableButtonComponent";
import FileSelectorButtonComponent from "../../components/buttons/FileSelectorButtonComponent";
import SimpleNavbar from "../../components/shared/SimpleNavBar";
import { API_URL } from "../../constants/config.constants";
import { AuctionType } from "../../model/AuctionType";
import { useSelector } from 'react-redux';
import { RootState } from "../../app/Store";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { fetchWithAuth, useRefreshToken } from "../Auth/AuthUtils";
import { LabelComponent } from "../../components/form/basics/LabelComponent";
import 'react-datepicker/dist/react-datepicker.css'
import Calendar from "react-calendar";
import TimePicker from 'react-time-picker';
import 'react-calendar/dist/Calendar.css';
import 'react-time-picker/dist/TimePicker.css';
import { useNavigate, useParams } from 'react-router-dom';
import { MobileNavBar } from "../../components/shared/MobileNavBar";
import { isMobile } from "../../utils/utils";
import { fetchFileForUpload, uploadFileToS3 } from "../../utils/UploadUtils";
import { PrefixType } from "../../model/PrefixType";
import { formatUrl } from "../Batchs/forms/BatchFormUtils";


const BackgroundImage = styled.div`
    min-height: 100vh;  
    box-sizing: border-box;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url(${CowsBackgroundImage});
`;

const FormContainer = styled.div`
    height: 611px;
    width: 1069px;
    background-color: ${WHITE};
    border-radius: 19px;
    padding: 40px;

    @media (max-width: 1818px) {
        height: 520px;
        padding: 30px;
    }

    @media (max-width: 768px) {
        height: 100vh;
        width: 100%;
        border-radius: unset;
        padding: 30px;
        padding: unset;
    }
`;

const HeaderFormContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 768px) {
        justify-content: center;
    }
`;

const HeaderTitle = styled.h2`
    color: ${BLACK_FONT};
    font-size: 32px;
`;

const InputsFormContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
`;

const TypeAuctionButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 359px;
    justify-content: space-between;
    align-items: center;
`;

const FooterContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 30px;
    margin-left: 90px;
    margin-right: 90px;

    @media (max-width: 768px) {
        flex-direction: column;
        gap: 10px;
        margin-bottom: 20px;
    }
`;

const InputContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const StyleFooterButtonsPreSave = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;

    @media (max-width: 768px) {
        flex-direction: column;
        gap: 20px;
        width: 100%;
    }
`;

interface Province {
    id: number;
    nombre: string;
}

interface Location {
    id: number;
    nombre: string;
    provincia: string;
}

interface DropdownOption {
    label: string;
    value: string | number;
}

export const EditAuctionPage = () => {
    const [title, setTitle] = useState('');
    const [urlStreaming, setUrlStreaming] = useState('');
    const [auctionDate, setAuctionDate] = useState('');
    const [place, setPlace] = useState('');
    const [provinces, setProvinces] = useState([]);
    const [selectedProvince, setSelectedProvince] = useState<string | number>('');
    const [locations, setLocations] = useState<DropdownOption[]>([]);
    const [locationsForFiltering, setLocationsForFiltering] = useState<Location[]>([]);
    const [auctionTypes, setAuctionTypes] = useState<DropdownOption[]>([]);
    const [selectedLocation, setSelectedLocation] = useState<string | number>('');
    const [selectedAuctionType, setSelectedAuctionType] = useState<AuctionType>();
    const [isPhysical, setIsPhysical] = useState<boolean>(false);
    const [isTV, setIsTV] = useState<boolean>(false);
    const [isStreaming, setIsStreaming] = useState<boolean>(false);
    const accessToken: String | null = useSelector((state: RootState) => state.auth.accessToken);
    const [filteredLocations, setFilteredLocations] = useState<DropdownOption[]>([]);
    const [time, setTime] = useState('10:00');
    const [dateIsEdited, setDateIsEdited] = useState(false);
    const { id } = useParams<{ id: string }>();
    const [showCalendar, setShowCalendar] = useState(false);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [inputValue, setInputValue] = useState('Fecha de remate *');
    const [selectedCatalogFile, setSelectedCatalogFile] = useState<any>();
    const [catalogPath, setCatalogPath] = useState('');
    const [selectedImage, setSelectedImage] = useState<any>();

    const refreshToken = useRefreshToken();

    const handleTimeChange = (selectedTime: any) => {
        setTime(selectedTime);
    };


    const resetFields = () => {
        setTitle('');
        setUrlStreaming('');
        setAuctionDate('');
        setPlace('');
        setSelectedProvince('');
        setSelectedLocation('');
        setIsTV(false);
        setIsStreaming(false);
        setCatalogPath('');
    }

    useEffect(() => {
        const fetchProvinces = async () => {
            try {
                const response = await fetchWithAuth(`${API_URL}/provincias/`);
                const data = await response.json();
                setProvinces(data.results.map((province: Province) => ({
                    label: province.nombre,
                    value: province.id
                })));
            } catch (error) {
                console.error("Error fetching provinces:", error);
            }
        };


        const fetchLocations = async () => {
            let url = `${API_URL}/localidades/?limit=10000`;
            let allLocations: DropdownOption[] = [];
            let locationsForFiltering: Location[] = [];

            try {
                while (url) {
                    const response = await fetchWithAuth(url);
                    const data = await response.json();
                    const locationsBatch = data.results.map((location: Location) => ({
                        label: location.nombre,
                        value: location.id
                    }));

                    const locationBatchForFiltering = data.results;

                    allLocations = [...allLocations, ...locationsBatch];
                    locationsForFiltering = [...locationsForFiltering, ...locationBatchForFiltering]
                    url = data.next;
                }

                setLocationsForFiltering(locationsForFiltering)
                setLocations(allLocations);
            } catch (error) {
                console.error("Error fetching locations:", error);
            }
        };

        fetchProvinces();
        fetchLocations();
        const auctionTypeOptions: DropdownOption[] = Object.values(AuctionType).map((type) => ({
            label: type,
            value: type
        }));
        setAuctionTypes(auctionTypeOptions);
    }, []);

    useEffect(() => {
        if (selectedProvince) {
            const filtered = locationsForFiltering.filter((location) => location.provincia === selectedProvince).map((location: Location) => ({
                label: location.nombre,
                value: location.id
            }));;
            setFilteredLocations(filtered);
        } else {
            setFilteredLocations(locations);
        }
    }, [selectedProvince, locations]);

    useEffect(() => {
        const fetchAuction = async () => {
            try {
                const response = await fetchWithAuth(`${API_URL}/remates/${id}/`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                console.log(data.fecha)
                setTitle(data.nombre);
                setUrlStreaming(data.url_streaming);
                setSelectedDate(new Date(data.fecha))
                setTime(data.fecha);
                setPlace(data.lugar);
                setSelectedProvince(data.provincia);
                setSelectedLocation(data.localidad);
                setIsPhysical(data.fisico);
                setIsTV(data.tv);
                setIsStreaming(data.streaming);
                setSelectedAuctionType(data.tipo);
                setIsPhysical(true);
                setSelectedImage(data.url_foto)
    
            } catch (e) {
                console.error("Error fetching auction:", e);
            }
        }
        fetchAuction();
    }, [id]);
    

    const [validationErrors, setValidationErrors] = useState({
        localidad: '',
        lugar: '',
        nombre: '',
        provincia: '',
        tipo: ''
    });

    const handleSubmit = async () => {
        setValidationErrors({
            localidad: '',
            lugar: '',
            nombre: '',
            provincia: '',
            tipo: ''
        });


        
        let dateTime;
        if (dateIsEdited) {
            const datePart = selectedDate.toISOString().split('T')[0];
            dateTime = `${datePart}T${time}:00Z`; // Construct the new date string with time part
        } else {
            dateTime = selectedDate.toISOString(); // Use the original ISO string if not edited
        }
    


        try {
            let fileName = ''
            if (selectedCatalogFile) {
                const uploadData = await fetchFileForUpload(selectedCatalogFile?.type, accessToken, PrefixType.PDF);
                const uploadUrl = uploadData.url;
                fileName = uploadData.file_name;
                await uploadFileToS3(uploadUrl, selectedCatalogFile, selectedCatalogFile?.type, PrefixType.PDF);
    
            }
            let uploadUrlImage = '';
            if (selectedImage) {
                const uploadDataImage = await fetchFileForUpload(selectedImage?.type, accessToken, PrefixType.IMAGE);
                uploadUrlImage = uploadDataImage.url;
                await uploadFileToS3(uploadUrlImage, selectedImage, selectedImage?.type, PrefixType.IMAGE);
            }

            
            
            const auctionData = {
                auctionId: id,
                provincia: selectedProvince,
                localidad: selectedLocation,
                url_streaming: urlStreaming === '' ? 'url' : urlStreaming,
                url_foto: formatUrl(uploadUrlImage),
                fisico: isPhysical,
                tv: isTV,
                orden: [],
                streaming: isStreaming,
                nombre: title,
                fecha: dateTime,
                lugar: place,
                tipo: selectedAuctionType,
                catalogo_path: fileName === '' ? 'none' : fileName
            };
            console.log(auctionData)
            const response = await updateAuction(accessToken, auctionData);
            if (response.ok) {
                resetFields();
                toast.success('Remate editado con éxito', {
                    position: 'bottom-right'
                });
            }
            else if (response.status == 401) {
                refreshToken()
                updateAuction(accessToken, auctionData);
            }
            else {
                setValidationErrors(await response.json());
            }
        } catch (error) {
            toast.error('Algo salió mal, vuelva a intentarlo', {
                position: 'bottom-right'
            });
        }
    };

    const toggleCalendar = () => {
        setShowCalendar(!showCalendar);
    };

    const onChange = (date: any) => {
        setSelectedDate(date);
        setInputValue(date.toLocaleDateString());
        setDateIsEdited(true);
        toggleCalendar();
    };

    const findByValue = (array: DropdownOption[], value: string | number) => {
        const result = array.find(el => el.value.toString() === value.toString() || el.label === value.toString());
        return result ? result.label : '';
    }

    const navigate = useNavigate();
    return (
        <div style={{ height: '90vh' }}>
            {isMobile() ? <MobileNavBar/> : <SimpleNavbar showUserMenu={true} />}
            <BackgroundImage>
                <FormContainer>
                    <HeaderFormContainer>
                        <HeaderTitle>Editar Remate</HeaderTitle>
                        <IconButtonComponent onClick={() => navigate(-1)} />
                    </HeaderFormContainer>
                    <InputsFormContainer>
                        <InputContainer>
                            <BorderInputComponent
                                placeholder='Título *'
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                                width={327}
                                hasError={validationErrors.nombre !== ''}
                                marginBottom={10}
                            />
                            <LabelComponent text={validationErrors.nombre !== '' ? '*Campo Obligatorio' : ''} color={RED_COLOR} fontSize={12} padding={0} />
                        </InputContainer>
                        <InputContainer>
                            <BorderInputComponent
                                placeholder='URL Streaming'
                                value={urlStreaming}
                                onChange={(e) => setUrlStreaming(e.target.value)}
                                width={327}
                                hasError={false}
                                marginBottom={10}
                            />
                            <LabelComponent text={''} color={RED_COLOR} fontSize={12} padding={0} />
                        </InputContainer>

                        <div>
                            <div onClick={toggleCalendar}>
                                <BorderInputComponent
                                    placeholder='Fecha de remate *'
                                    value={selectedDate.toLocaleDateString()}
                                    width={327}
                                    onChange={() => { }}
                                />
                            </div>
                            {showCalendar && (
                                <div style={{ position: 'absolute', zIndex: 9999 }}>
                                    <Calendar
                                        onChange={onChange}
                                        value={selectedDate || new Date()}
                                    />
                                    <TimePicker
                                        onChange={handleTimeChange}
                                        value={time}
                                    />
                                </div>
                            )}
                        </div>
                        <InputContainer>
                            <BorderInputComponent
                                placeholder='Lugar'
                                value={place}
                                onChange={(e) => setPlace(e.target.value)}
                                width={327}
                                hasError={validationErrors.lugar !== ''}
                                marginBottom={10}
                            />
                            <LabelComponent text={validationErrors.lugar !== '' ? '*Campo Obligatorio' : ''} color={RED_COLOR} fontSize={12} padding={0} />
                        </InputContainer>
                        <InputContainer>
                            <BorderDropdownComponent marginBottom={10} width={359} color={PLACEHOLDER_FONT_COLOR} hasError={validationErrors.provincia !== ''} placeholder={findByValue(provinces, selectedProvince.toString())} options={provinces} onChange={(value) => setSelectedProvince(value)} />
                            <LabelComponent text={validationErrors.provincia !== '' ? '*Campo Obligatorio' : ''} color={RED_COLOR} fontSize={12} padding={0} />
                        </InputContainer>
                        <InputContainer>
                            <BorderDropdownComponent width={359} color={PLACEHOLDER_FONT_COLOR} hasError={validationErrors.localidad !== ''} placeholder={findByValue(filteredLocations, selectedLocation.toString())} options={filteredLocations} disabled={selectedProvince === ''} onChange={(value) => setSelectedLocation(value)} />
                            <LabelComponent text={validationErrors.localidad !== '' ? '*Campo Obligatorio' : ''} color={RED_COLOR} fontSize={12} padding={0} />
                        </InputContainer>
                        <InputContainer>
                            <BorderDropdownComponent marginBottom={10} width={359} color={PLACEHOLDER_FONT_COLOR} hasError={validationErrors.tipo !== ''} placeholder={findByValue(auctionTypes, selectedAuctionType?.toString() || '')} options={auctionTypes} onChange={(value) => setSelectedAuctionType(value)} />
                            <LabelComponent text={validationErrors.tipo !== '' ? '*Campo Obligatorio' : ''} color={RED_COLOR} fontSize={12} padding={0} />
                        </InputContainer>

                        <TypeAuctionButtonsContainer>
                            <SeleccionableButtonComponent
                                width='86'
                                borderRadius={40}
                                onClick={() => setIsPhysical(!isPhysical)}
                                isSelected={isPhysical}
                            >
                                Físico
                            </SeleccionableButtonComponent>
                            <SeleccionableButtonComponent
                                width='86'
                                borderRadius={40}
                                onClick={() => setIsTV(!isTV)}
                                isSelected={isTV}
                            >
                                TV
                            </SeleccionableButtonComponent>
                            <SeleccionableButtonComponent
                                width='86'
                                borderRadius={40}
                                onClick={() => setIsStreaming(!isStreaming)}
                                isSelected={isStreaming}
                            >
                                Streaming
                            </SeleccionableButtonComponent>
                        </TypeAuctionButtonsContainer>
                    </InputsFormContainer>
                    <FooterContainer>
                        <StyleFooterButtonsPreSave>
                        <FileSelectorButtonComponent onFileSelect={(file: any) => setSelectedImage(file)} />
                        <FileSelectorButtonComponent label={'Cargar Catálogo'} color={WHITE} backgroundColor={PRIMARY_COLOR} onFileSelect={(file: any) => setSelectedCatalogFile(file)}
                        />
                        </StyleFooterButtonsPreSave>
                        <div style={{ width: isMobile() ? '100%': 359 }}>
                            <PrimaryButtonComponent width={isMobile() ? "150px" : "117"} borderRadius={10} onClick={handleSubmit}>Guardar</PrimaryButtonComponent>
                        </div>
                    </FooterContainer>
                </FormContainer>
            </BackgroundImage>
            <ToastContainer />
        </div>
    )
}
async function updateAuction(accessToken: String | null, auctionData: { auctionId: any; provincia: string | number; localidad: string | number; url_streaming: string; url_foto: string; fisico: boolean; tv: boolean; streaming: boolean; nombre: string; fecha: string; lugar: string; tipo: AuctionType | undefined; }) {
    return await fetchWithAuth(`${API_URL}/remates/${auctionData.auctionId}/`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        },
        body: JSON.stringify(auctionData)
    });
}