import styled from "styled-components";
import SimpleNavbar from "../../components/shared/SimpleNavBar";
import { PRIMARY_COLOR, WHITE } from "../../constants/colors.constants";
import Logo from '../../assets/logo-pag-principal.png';
import GreenCheck from '../../assets/green_check.png';
import RedCheck from '../../assets/forbiden.png';
import { IconButtonComponent } from "../../components/buttons/IconButtonComponent";
import { RectangularInputComponent } from "../../components/form/inputs/RectangularInputComponent";
import { useState } from "react";
import { PrimaryButtonComponent } from "../../components/buttons/PrimaryButtonComponent";
import CowsBackgroundImage from '../../assets/login-background.png';
import { isMobile } from "../../utils/utils";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../../constants/config.constants";
import { SpinnerForTables } from "../../components/loadings/SpinnerForTables";
import { fetchWithAuth } from "./AuthUtils";


const FormContainer = styled.div`
    height: 487px;
    width: 601px;
    background-color: ${WHITE};
    border-radius: 19px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    @media (max-width: 768px) {
        width: 80%;
        height: auto;
        padding: 10px;
    }
`;

const BackgroundImage = styled.div`
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url(${CowsBackgroundImage});
    background-size: cover;
    background-position: center;

    @media (max-width: 768px) {
        background-size: cover;
    }
`;

const HeaderForm = styled.div`
    display: flex;
    justify-content: space-between;

    padding: 20px;

    @media (max-width: 768px) {
        padding: 10px;
    }
`;

const LogoImage = styled.img`
    width: 109px;
    height: 109px;

    @media (max-width: 768px) {
        width: 80px;
        height: 80px;
    }
`;

const InputsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const StyledImg = styled.img`
    width: 150px;
    height: 150px;
`;

const StyledSpan = styled.span`
    font-weight: bold;
    color: ${PRIMARY_COLOR};
    font-size: 24px;
`;

export const ForgetPassword = () => {
    const [mail, setMail] = useState('');
    const navigate = useNavigate();
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleSendEmail = async () => {
        setIsLoading(true)
        setShowErrorMessage(false);
        setShowSuccessMessage(false);
        try {

            const response = await fetch(`${API_URL}/users/send-new-password/?email=${mail}`, {
                method: 'GET',
            });
            if (response.status === 200) {
                setIsLoading(false)
                setShowSuccessMessage(true);
            } else {
                setIsLoading(false)
                setShowErrorMessage(true);
            }
          } catch (error) {
            setIsLoading(false)
            console.error('Error :', error);
          }
    }

    return (
        <div style={{ height: '100%'}}>
            <SimpleNavbar showUserMenu={false}/>
            <BackgroundImage>
                <FormContainer>
                    {isLoading && <SpinnerForTables/>}
                    {
                        (!showSuccessMessage && !showErrorMessage) ? (
                            <>
                                <HeaderForm>
                                    <div><span></span></div>
                                    <LogoImage src={Logo} alt="Logo de Reggi" />
                                    <IconButtonComponent onClick={() => navigate(-1)} />
                                </HeaderForm>
                                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                                    <InputsContainer>
                                        <RectangularInputComponent
                                            label="Ingresa tu mail para recuperar la contraseña"
                                            placeholder="Mail"
                                            value={mail}
                                            onChange={(e) => setMail(e.target.value)}
                                            width={isMobile() ? 220 : undefined}
                                        />
                                    </InputsContainer>
                                    <PrimaryButtonComponent
                                        onClick={() => handleSendEmail()}
                                        width = '92%'
                                        borderRadius={10}
                                    >
                                        Enviar
                                    </PrimaryButtonComponent>
                                </div>
                            </>
                        ) : (showSuccessMessage) ? (
                            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 40}}>
                                <StyledImg src={GreenCheck}/>
                                <StyledSpan>
                                    ¡Se ha enviado un mail a tu correo electrónico para tu nueva contraseña!
                                </StyledSpan>
                            </div>
                        ) : (showErrorMessage) && (
                            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 40}}>
                                <StyledImg src={RedCheck}/>
                                <StyledSpan>
                                    ¡No se ha podido enviar el mail!
                                </StyledSpan>
                            </div>
                        )
                    }
                </FormContainer>
            </BackgroundImage>
        </div>
    )
}
