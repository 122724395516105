import styled from 'styled-components';
import { FaInstagram, FaTwitter, FaYoutube } from 'react-icons/fa';
import Logo from '../../assets/Logo_Apaisado_Azul-removebg-preview 2.png';
import { PRIMARY_COLOR } from '../../constants/colors.constants';
import { isMobile } from '../../utils/utils';

const FooterContainer = styled.footer`
  background: ${PRIMARY_COLOR};
  color: #FFFFFF;
  display: flex;
  padding: 20px 80px;
  flex-direction: column;
  @media (max-width: 768px) {
    padding: 20px 40px;
  }
  @media (max-width: 480px) {
    padding: 20px;
  }
`;

const TopContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  @media (max-width: 480px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

const TopLeftContainer = styled.div`
  display: flex;
  align-items: start;
  justify-content: start;
  flex: 2;
  @media (max-width: 480px) {
    justify-content: center;
    margin-bottom: 10px;
  }
`;

const TopRightContainer = styled.div`
  display: flex;
  justify-content: end;
  flex: 1;
  gap: 20px;
  @media (max-width: 480px) {
    justify-content: center;
    width: 100%;
  }
`;

const DownContainer = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 480px) {
    flex-direction: column;
  }
`;

const LeftDownContainer = styled.div`
  display: flex;
  flex: 2;
  gap: 20px;
  @media (max-width: 480px) {
    flex-direction: column;
    gap: 10px;
  }
`;

const RightDownContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: end;
  gap: 20px;
  @media (max-width: 480px) {
    justify-content: flex-start;
    width: 100%;
    margin-top: 10px;
    gap: 10px;
  }
`;

const FooterText = styled.a`
  text-decoration: none;
  color: unset;
  font-size: 14px;
  margin-bottom: 4px;
  @media (max-width: 480px) {
    font-size: 12px;
  }
`;

const LineSeparator = styled.hr`
  border: none;
  height: 1px;
  background-color: #CCCCCC;
  margin: 20px 0;
  width: 100%;
  @media (max-width: 480px) {
    margin: 10px 0 10px;
    width: 57%;
    align-self: center;
  }
`;

const LogoImg = styled.img`
  max-width: 100%;
  margin-left: -60px;

  @media (max-width: 480px) {
    margin-left: unset;
    width: 304px;
    height: 72px;
  }
`;

const StyledUrl = styled.a`
  text-decoration: none;
  color: unset;
`;

const Footer = () => {
  return (
    <FooterContainer>
      <TopContainer>
        <TopLeftContainer>
          <LogoImg src={Logo} alt="Logo" />
        </TopLeftContainer>
        <TopRightContainer>
          <StyledUrl href='https://www.instagram.com/reggiyciasrl/?hl=es' target='_blank'><FaInstagram size={isMobile() ? 20 : 40} /></StyledUrl>
          <StyledUrl href='https://x.com/reggiyciasrl' target='_blank'><FaTwitter size={isMobile() ? 20 : 40} /></StyledUrl>
          <StyledUrl href='https://www.youtube.com/@ReggiyCiaSRL' target='_blank'><FaYoutube size={isMobile() ? 20 : 40} /></StyledUrl>
        </TopRightContainer>
      </TopContainer>
      <LineSeparator />
      <DownContainer>
        <LeftDownContainer>
          {
            !isMobile() ? <FooterText>Reggi & CIA S.R.L. Consignatarios 2024 © Almoby. Todos los derechos reservados.</FooterText> : <div style={{display: 'flex', flexDirection: 'column', margin: 10}}>
              <FooterText>Reggi & CIA S.R.L. Consignatarios 2024 © Almoby.</FooterText>
              <FooterText>Todos los derechos reservados.</FooterText>
            </div>
          }
          {!isMobile() && <FooterText href='https://www.google.com.ar/maps/place/Pueyrred%C3%B3n+743,+W3460+Curuz%C3%BA+Cuati%C3%A1,+Corrientes/@-29.7897028,-58.06023,17z/data=!3m1!4b1!4m6!3m5!1s0x9452925968002b57:0x5943f5bf06a0d75b!8m2!3d-29.7897075!4d-58.0576551!16s%2Fg%2F11h51b5fw0?entry=ttu' target='_blank'>Pueyrredón 743, Curuzú Cuatiá, Argentina</FooterText>}
        </LeftDownContainer>
        {
          !isMobile() && <RightDownContainer>
          <FooterText href='https://mail.google.com/mail/u/0/?tab=rm&ogbl#inbox?compose=CllgCJvlqQzhxmNTDqpMlzbjCXKbkSnGKKBDQNCsTPCvsqxpffWFLFFbmGGZkGHBWlLlRxBBfcL' target='_blank'>info@reggiycia-srl.com.ar</FooterText>
          <FooterText>(3774) 424010 / 424011</FooterText>
        </RightDownContainer>
        }
      </DownContainer>
    </FooterContainer>
  );
};

export default Footer;
