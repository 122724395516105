import { ReactNode, MouseEventHandler } from "react";
import styled from "styled-components";
import { BLACK_FONT, PRIMARY_COLOR, WHITE } from "../../constants/colors.constants";
import { darkenHexColor } from "../../utils/utils";

interface SecondaryButtonProps {
    children: ReactNode;
    onClick?: MouseEventHandler<HTMLButtonElement>;
    borderRadius?: number;
    color?: string;
    backgroundColor?: string;
    gradient?: boolean;
    bold?: number;
    border?: boolean;
    width?: string;
    isBold?: boolean;
    height?: string;
    padding?: string;
    special?: boolean;
    fa?: string;
}

const SecondaryButton = styled.button.attrs<SecondaryButtonProps>((props) => ({
    borderRadius: props.borderRadius || 12,
    borderColor: props.color || PRIMARY_COLOR,
    backgroundColor : props.backgroundColor || WHITE,
    color: props.color,
    bold: props.bold,
    border: props.border,
    height: props.height,
    padding: props.padding,
    special: props.special,
    fa: props.fa
}))<SecondaryButtonProps>`
    padding: ${(props) => props.padding};
    border: ${({ border, backgroundColor }) => border ? `2px solid ${darkenHexColor(backgroundColor!, 10)} !important` : `2px solid ${backgroundColor} !important`};
    border: ${({fa}) => fa && `1px solid ${fa}`} !important;
    border: ${({special}) => special && `2px solid ${PRIMARY_COLOR}`} !important;
    cursor: pointer;
    font-weight: ${(props) => props.bold};
    font-size: 14px;
    border: 1px solid ${(props) => props.color};
    color:  ${(props) => props.color};
    border-radius: ${(props) => props.borderRadius}px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
    background: ${({ gradient, backgroundColor }) => gradient ? `linear-gradient(0deg, #FFFFFF -80%, ${backgroundColor} 100%)` : backgroundColor};
    width: ${(props) => props.width};
    height: ${(props) => props.height};
    &:hover {
        background-color: ${(props) => !props.disabled && `${WHITE}cc`}; // Cambia la opacidad del color
    }

    @media (max-width: 768px) {
      padding: unset;
  }
`;

export const SecondaryButtonComponent = ({
    children,
    onClick,
    borderRadius = 12,
    backgroundColor,
    gradient = false,
    color = BLACK_FONT,
    bold = 500,
    border = false,
    width = 'auto',
    height = '40px',
    padding = '10px 20px',
    special = false,
    fa
}: SecondaryButtonProps) => {
  return (
    <SecondaryButton 
      onClick={onClick}
      borderRadius={borderRadius}
      backgroundColor={backgroundColor}
      gradient={gradient}
      color={color}
      bold={bold}
      border={border}
      width={width}
      height={height}
      padding={padding}
      special={special}
      fa={fa}
    >
      {children}
    </SecondaryButton>
  );
};
