import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import SimpleNavbar from '../../components/shared/SimpleNavBar';
import { PrimaryButtonComponent } from '../../components/buttons/PrimaryButtonComponent';
import { BLACK_FONT, GREEN_PUBLISH_CATALOG, PLACEHOLDER_FONT_COLOR, PRIMARY_COLOR, RED_COLOR, WHITE } from '../../constants/colors.constants';
import { IconButtonComponent } from '../../components/buttons/IconButtonComponent';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { AuctionItemComponent } from '../../components/auction/AuctionItemComponent';
import { getAuctionColor, getDate, getHour } from '../../utils/AuctionUtils';
import { categories } from '../../constants/dropdowns/batchs/batchDataDropdown';
import DraggableBatchOrderItem from './DraggableBatchOrderItem';
import { API_URL } from '../../constants/config.constants';
import { SpinnerForTables } from '../../components/loadings/SpinnerForTables';
import { EditBatchModal } from './modal/EditBatchModal';
import { MobileNavBar } from '../../components/shared/MobileNavBar';
import { isMobile } from '../../utils/utils';
import { ToastContainer, toast } from 'react-toastify';
import BorderDropdownComponent from '../../components/form/dropdowns/BorderDropdownComponent';
import DraggableCategoriesOrder from './DraggableCategoriesOrder';
import { convertListToMap, orderBatchesBasedOnOrderedCategories } from './BatchUtils';
import { exportPdf } from '../../api/ControlPanelAPI';
import { fetchWithAuth } from '../Auth/AuthUtils';

const Container = styled.div`
    padding: 0 100px 0 100px;

    @media (max-width: 769px) {
        padding: 0 20px 0 20px;
    }
`;

const ButtonsContainer = styled.div`
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
    margin-bottom: 30px;

    @media (max-width: 769px) {
        justify-content: center;
        gap: 5px;
    }
`;

const StyledTitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: self-start;
`;

const StyledTitle = styled.h2`
    color: ${PRIMARY_COLOR};
    font-size: 47px;
    font-weight: bold;
    margin-bottom: unset;

    @media (max-width: 769px) {
        font-size: 24px;
    }
`;

const StyledSubtitle = styled.span`
    color: ${PRIMARY_COLOR};    
    font-size: 24px;
    font-weight: 300;

    @media (max-width: 769px) {
        font-size: 16px;
    }
`;

const ModalOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
`;

const ModalContent = styled.div`
    background: ${WHITE};
    border-radius: 10px;
    padding: 40px;
    width: 600px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;

const ModalOrderCategoriesContent = styled.div`
    background: ${WHITE};
    border-radius: 10px;
    padding: 40px;
    width: 600px;
    height: 400px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    overflow-y: scroll;
`;

const StyledItemsContainer = styled.div`
    margin-top: 50px;
    padding-bottom: 50px;
    display: flex;
    flex-direction: column;
    gap: 25px;
`;

const NoItemsContainer = styled.div`
    margin-top: 40px;
    height: 300px;
    border-radius: 20px;
    box-shadow: 0 2px 10px ${PRIMARY_COLOR};
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;

const NoItemsPlaceholder = styled.p`
    color: ${PLACEHOLDER_FONT_COLOR};
    font-size: 30px;
    font-weight: 600;
`;

const ModalContainer = styled.div`
    background: ${WHITE};
    border-radius: 10px;
    padding: 20px;
    width: 400px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;

const ModalTitle = styled.h3`
    margin: 0 0 20px;
    color: ${BLACK_FONT};
`;

const ModalButtonsContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 10px;
`;

const ModalButton = styled.button<{ cancel?: boolean }>`
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    color: ${WHITE};
    background-color: ${props => props.cancel ? 'grey' : RED_COLOR};

    &:hover {
        opacity: 0.8;
    }
`;

export const OrderBatchesPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const auction = location.state?.auction;
    const [batches, setBatches] = useState<any>([]);
    const [showNoResults, setShowNoResults] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showOrderCategoriesModal, setShowOrderCategoriesModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingDelete, setIsLoadingDelete] = useState(false);
    const [selectedCategoryFilter, setSelectedCategoryFilter] = useState<any>();
    const [orderedCategories, setOrderedCategories] = useState(categories)
    const { id } = useParams<{ id: string }>();
    const modalRef = useRef<HTMLDivElement>(null);
    const modalOrderCategoriesRef = useRef<HTMLDivElement>(null);
    const [isDraggingEnabled, setIsDraggingEnabled] = useState(false);
    const [totalCabezas, setTotalCabezas] = useState(0);


    const fetchBatchByTypeAndId = async (type: string, id: number) => {
        try {
            const response = await fetchWithAuth(`${API_URL}/lotes/${type}/${id}/`);
            if (response.status === 404) {
                throw new Error('Batch not found');
            }
            const data = await response.json();
            return data;
        } catch (error) {
            console.error("Error fetching batch:", error);
            throw error;
        }
    };

    const fetchPublishedBatches = async () => {
        try {
            setIsLoading(true);
            const response = await fetchWithAuth(`${API_URL}/lotes/all/${auction.id}/`);
            const data = await response.json();
            setBatches(data.unordered_lotes)
            setIsLoading(false);
            return data;
        } catch (error) {
            console.error("Error fetching batch:", error);
            setIsLoading(false);
            throw error;
        }
    }

    const getBatches = async () => {
        setIsLoading(true);
        const orderedAuctions = auction.orden.sort((a: any, b: any) => a.orden - b.orden);
        const batchPromises = orderedAuctions.map((batch: any) =>
            fetchBatchByTypeAndId(batch.lote_type.toLowerCase(), batch.lote_id)
        );

        try {
            const batchResults = await Promise.all(batchPromises);
            setBatches(batchResults);
            console.log(batches)
        } catch (error: any) {
            if (error.message === 'Batch not found') {
                setShowNoResults(true);
            }
            console.error("Error fetching batches:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const toggleModalCategorias = () => {
        setShowModal(!showModal);
    }

    const toggleModalOrderCategorias = () => {
        setShowOrderCategoriesModal(!showOrderCategoriesModal);
    }

    const moveItem = (fromIndex: any, toIndex: any) => {
        const updatedItems = Array.from(batches);
        const [movedItem] = updatedItems.splice(fromIndex, 1);
        updatedItems.splice(toIndex, 0, movedItem);
        setBatches(updatedItems);
    };

    const moveCategories = (fromIndex: any, toIndex: any) => {
        const updatedCategories = Array.from(orderedCategories);
        const [movedCategory] = updatedCategories.splice(fromIndex, 1);
        updatedCategories.splice(toIndex, 0, movedCategory);
        setOrderedCategories(updatedCategories);
    };

    useEffect(() => {
        console.log(auction)
        if (!auction.orden || auction.orden.length === 0) {
            fetchPublishedBatches()
        } else {
            getBatches();
        }
    }, []);

    // useEffect(() => {
    //     console.log("Batches length:", batches.length);
    //     if (batches.length === 0 && !isLoading) {
    //         setShowNoResults(true);
    //     } else {
    //         setShowNoResults(false);
    //     }
    // }, [batches, isLoading]);

    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [selectedBatchId, setSelectedBatchId] = useState<any>(null);
    const [selectedCategory, setSelectedCategory] = useState<any>('');

    const handleEditClick = (batchId: number, lote_type: string) => {
        setSelectedBatchId(batchId);
        setSelectedCategory(lote_type.toLowerCase());
        setIsEditModalOpen(true);
    };

    const handleEditCloseModal = () => {
        setIsEditModalOpen(false);
    };

    const handleDeleteClick = (batchId: number, lote_type: string) => {
        setSelectedBatchId(batchId);
        setSelectedCategory(lote_type.toLowerCase());
        setIsDeleteModalOpen(true);
    };

    const handleDeleteCloseModal = () => {
        setIsDeleteModalOpen(false);
    };

    const accessToken = localStorage.getItem('accessToken');

    const handleDeleteBatch = async () => {
        setIsLoadingDelete(true);
        try {
            const response = await fetchWithAuth(`${API_URL}/lotes/${selectedCategory}/${selectedBatchId}/`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                }
            });
            if (response.ok) {
                window.location.reload();
            } else {
                console.error(`Failed to delete lote ${selectedBatchId}`);
                setIsLoadingDelete(false);
            }
        } catch (error) {
            setIsLoadingDelete(false);
            console.error("Error deleting lote:", error);
        }
    }

    const handleUpdateOrder = async () => {
        let newOrder: any = orderBatchesBasedOnOrderedCategories(orderedCategories, batches)
        console.log(newOrder)
        try {
            const response = await fetchWithAuth(`${API_URL}/remates/${id}/`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                },
                body: JSON.stringify({
                    orden: [...newOrder]
                }),
            });

            if (response.ok) {
                toast.success('Lotes ordenados con éxito', {
                    position: 'bottom-right'
                });
            } else {
                toast.error('No se pudo ordenar los lotes', {
                    position: 'bottom-right'
                });
            }

        } catch (error) {
            toast.error('No se pudo ordenar los lotes', {
                position: 'bottom-right'
            });
        }
    }



    const displayedBatchs = batches.filter((batch: any) => {
        const matchCategory = selectedCategoryFilter && selectedCategoryFilter !== "" ? batch.categoria.includes(selectedCategoryFilter) : true;
        return matchCategory;
    });

    const handleOverlayClick = (e: React.MouseEvent<HTMLDivElement>) => {
        if (modalRef.current && !modalRef.current.contains(e.target as Node)) {
            setShowModal(false);
        }
        if (modalOrderCategoriesRef.current && !modalOrderCategoriesRef.current.contains(e.target as Node)) {
            setShowOrderCategoriesModal(false);
        }
    };

    const handlePublishCatalogo = async (publicado: boolean, forAdmins: boolean) => {
        await exportPdf(auction.id, publicado, forAdmins)
    }   

    useEffect(() => {
        const totalCabezasCantidad = displayedBatchs.reduce((total: any, batch: any) => total + (batch.cantidad || 0), 0);
        setTotalCabezas(totalCabezasCantidad);
    }, [displayedBatchs]);


    return (
        <div style={{ height: '90vh' }}>
            {isMobile() ? <MobileNavBar /> : <SimpleNavbar showUserMenu={true} />}
            <Container>
                <ButtonsContainer>
                <PrimaryButtonComponent borderRadius={12} width="246px" onClick={() => handlePublishCatalogo(true, true)}>Previsualización de Lotes</PrimaryButtonComponent>
                    <PrimaryButtonComponent borderRadius={12} width="164px" onClick={() => setIsDraggingEnabled(!isDraggingEnabled)}>{isDraggingEnabled ? '...' : 'Ordenar Lotes' }</PrimaryButtonComponent>
                    <PrimaryButtonComponent borderRadius={12} width="246px" onClick={() => setShowOrderCategoriesModal(true)}>Ordenar Categorías</PrimaryButtonComponent>
                    <PrimaryButtonComponent borderRadius={12} width="128px" onClick={toggleModalCategorias}>Filtros</PrimaryButtonComponent>
                    <PrimaryButtonComponent borderRadius={12} width="198px" backgroundColor={GREEN_PUBLISH_CATALOG} onClick={() => handlePublishCatalogo(true, false)}>Publicar Catálogo</PrimaryButtonComponent>
                    <IconButtonComponent onClick={() => navigate(-1)} />
                </ButtonsContainer>
                {auction && (
                    <>
                        <AuctionItemComponent
                            type={auction.tipo}
                            marginTop="0"
                            backgroundColor={getAuctionColor(auction.tipo)}
                            whenDate={getDate(auction.fecha)}
                            whenHour={`${getHour(auction.fecha)} hs`}
                            location={auction.lugar}
                            inPerson={!!auction.fisico}
                            videoUrl={auction.url_streaming}
                        />
                        <StyledTitleContainer>
                            <StyledTitle>Ordenar Lotes</StyledTitle>
                            <StyledSubtitle>{totalCabezas} Cabezas</StyledSubtitle>
                        </StyledTitleContainer>
                    </>
                )}

                {isLoading && <SpinnerForTables />}
                {
                    showNoResults
                        ? (
                            <NoItemsContainer>
                                <NoItemsPlaceholder>
                                    No se encontraron Lotes
                                </NoItemsPlaceholder>
                            </NoItemsContainer>
                        )
                        : (!isLoading) && (
                            <DndProvider backend={HTML5Backend}>
                                <StyledItemsContainer>
                                    {}
                                    {displayedBatchs.map((item: any, index: any) => (
                                        <DraggableBatchOrderItem
                                            key={item.id}
                                            item={item}
                                            index={index}
                                            auction={auction}
                                            moveItem={isDraggingEnabled ? moveItem : () => {}}
                                            onEdit={() => handleEditClick(item.id, item.lote_type)}
                                            onDelete={() => handleDeleteClick(item.id, item.lote_type)}
                                        />
                                    ))}
                                </StyledItemsContainer>
                            </DndProvider>
                        )
                }

                {showModal &&
                    <ModalOverlay onClick={handleOverlayClick}>
                        <ModalContent ref={modalRef}>
                            <BorderDropdownComponent
                                width={583}
                                color={PLACEHOLDER_FONT_COLOR}
                                placeholder={"Categorías"}
                                options={categories}
                                onChange={(e) => { setSelectedCategoryFilter(e) }}
                                marginBottom={25}
                            />
                            <PrimaryButtonComponent onClick={() => {
                                setSelectedCategoryFilter('');
                                toggleModalCategorias();
                            }} borderRadius={20}>Cancelar</PrimaryButtonComponent>
                        </ModalContent>
                    </ModalOverlay>
                }

                {showOrderCategoriesModal &&
                    <ModalOverlay onClick={handleOverlayClick}>
                        <ModalOrderCategoriesContent ref={modalOrderCategoriesRef}>
                            <DndProvider backend={HTML5Backend}>
                                {orderedCategories.map((category: any, index: any) => (
                                    <DraggableCategoriesOrder
                                        key={category.label}
                                        item={category}
                                        index={index}
                                        moveItem={moveCategories}
                                        label={category.label}
                                    />
                                ))}
                            </DndProvider>
                            {/* <PrimaryButtonComponent onClick={toggleModalOrderCategorias} borderRadius={20}>Guardar</PrimaryButtonComponent> */}
                        </ModalOrderCategoriesContent>
                    </ModalOverlay>
                }

                {isEditModalOpen && (
                    <EditBatchModal
                        onClose={handleEditCloseModal}
                        id={selectedBatchId}
                        category={selectedCategory}
                    />
                )}

                {isDeleteModalOpen && (
                    <ModalOverlay>
                        <ModalContainer>
                            {
                                isLoadingDelete ? (
                                    <SpinnerForTables />
                                ) : (
                                    <div>
                                        <ModalTitle>Confirmación</ModalTitle>
                                        <p>¿Estás seguro de que deseas borrar este Lote?</p>
                                        <ModalButtonsContainer>
                                            <ModalButton cancel onClick={handleDeleteCloseModal}>Cancelar</ModalButton>
                                            <ModalButton onClick={() => handleDeleteBatch()}>Aceptar</ModalButton>
                                        </ModalButtonsContainer>
                                    </div>
                                )
                            }
                        </ModalContainer>
                    </ModalOverlay>
                )}
                <div style={{width: '100%', display:'flex', alignItems:'flex-end', justifyContent: 'flex-end'}}>
                    <PrimaryButtonComponent borderRadius={12} width="164px" onClick={() => handleUpdateOrder()}>Guardar</PrimaryButtonComponent>

                </div>

                <ToastContainer />
            </Container>
        </div>
    )
}
