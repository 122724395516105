import { ReactNode, useEffect, useState } from "react";
import styled from "styled-components";
import { GRAY_COLOR, PLACEHOLDER_FONT_COLOR, PRIMARY_COLOR, WHITE } from "../../constants/colors.constants";

interface SeleccionableButtonProps {
    children: ReactNode;
    onClick?: () => void;
    borderRadius?: number;
    width?: string;
    height?: string;
    isSelected?: boolean;
    backgroundColor?: string;
}

const SeleccionableButton = styled.button<SeleccionableButtonProps>`
    padding: 10px;
    color: ${(props) => props.color};
    border: none;
    cursor: pointer;
    font-weight: 600;
    font-size: 14px;
    margin-right: 16px;
    border-radius: ${(props) => props.borderRadius}px;
    background-color: ${(props) => props.backgroundColor};
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease, background-color 0.3s ease, color 0.3s ease;
    width: ${(props) => props.width}px;

    &:hover {
        box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
    }

    &:active {
        transform: translateY(1px);
    }
`;

export const SeleccionableButtonComponent = ({
    children,
    onClick,
    borderRadius = 0,
    width = '100%',
    height = '48px',
    isSelected = false // Valor por defecto es falso
}: SeleccionableButtonProps) => {
    const [clicked, setClicked] = useState<boolean>(isSelected);

    useEffect(() => {
        setClicked(isSelected);
    }, [isSelected]);

    const handleClick = () => {
        setClicked(!clicked);
        if (onClick) onClick();
    };

    return (
        <SeleccionableButton
            onClick={handleClick}
            borderRadius={borderRadius}
            backgroundColor={clicked ? PRIMARY_COLOR : GRAY_COLOR}
            color={clicked ? WHITE : PLACEHOLDER_FONT_COLOR}
            width={width}
            height={height}
        >
            {children}
        </SeleccionableButton>
    );
};
