import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FaTimesCircle, FaWhatsapp } from "react-icons/fa";
import { GOLDEN_COLOR, PRIMARY_COLOR } from '../../../constants/colors.constants';
import CowsBackgroundImage from '../../../assets/blured_cows.png';
import { SecondaryButtonComponent } from '../../../components/buttons/SecondaryButtonComponent';
import { BorderInputComponent } from '../../../components/form/inputs/BorderInputComponent';
import { PrimaryButtonWithIconComponent } from '../../../components/buttons/PrimaryButtonWithIconComponent';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { API_URL } from '../../../constants/config.constants';
import { SpinnerLoading } from '../../../components/loadings/SpinnerLoading';
import { isMobile } from '../../../utils/utils';
import { fetchWithAuth } from '../../Auth/AuthUtils';

const ModalContent = styled.div`
  background-color: #fefefe;
  height: 800px;
  width: 638px;
  position: relative;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  overflow-x: hidden;

  @media (max-width: 768px) {
    width: 100%;
    height: 100%;
    margin-top: 30px;
  }

  @media (min-width: 800px) and (max-width: 1800px) {
    height: 640px;
    width: 510.4px;
  }


`;

const CloseButton = styled.span`
  color: #aaa;
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;

  &:hover,
  &:focus {
    color: black;
  }
`;

const StyledImg = styled.div`
  margin-top: 30px;
  width: 535px;
  height: 256px;
`;

const VideoFrame = styled.iframe`
  width: 100%;
  height: 100%;
  border-radius: 39px;
`;

const HeaderContainer = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 535px;
  padding: 0 20px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const HeaderTitle = styled.span`
  color: ${PRIMARY_COLOR};
  font-size: 20px;
  font-weight: bold;
`;

const StyledInputsContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const InputWrapper = styled.div`
  flex: 1 1 calc(50% - 10px);
  min-width: 220px;
  display: flex;
  justify-content: center;

  @media (max-width: 768px) {
    flex: 1;
    min-width: unset;
  }
`;

const FooterModal = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 535px;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    margin-bottom: 40px;
  }
`;

const StyledVideo = styled.video`
  margin-top: 30px;
  width: 100%;
  height: auto;
  border-radius: 10px;
`;

const SyledObservaciones = styled.div`
  @media (max-width: 768px) {
    display: flex;
    justify-content: center;
  }
`;

interface DetailBatchModalProps {
  onClose: any;
  id: number;
  category: string;
}

export const DetailBatchModal = ({ onClose, id, category }: DetailBatchModalProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [batchData, setBatchData] = useState<any>();

  const fetchBatchDetail = async (category: string, id: number) => {
    setIsLoading(true);
    try {
      const response = await fetchWithAuth(`${API_URL}/lotes/${category}/${id}`);
      setBatchData(await response.json());
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchBatchDetail(category, id);
  }, [id, category]);

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'fixed', zIndex: 1000, left: 0, top: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.05)' }}>
      <ModalContent>
        {isLoading && <SpinnerLoading />}
        {!isLoading && (
          <>
            <CloseButton onClick={onClose}><FaTimesCircle color={PRIMARY_COLOR} /></CloseButton>
            <StyledVideo controls>
              <source src={batchData?.video} type="video/mp4" />
              Your browser does not support the video tag.
            </StyledVideo>
            <HeaderContainer>
              <SecondaryButtonComponent color={GOLDEN_COLOR}>Exposición</SecondaryButtonComponent>
              <HeaderTitle> {batchData?.cantidad} | {batchData?.categoria}</HeaderTitle>
              <SecondaryButtonComponent width={isMobile() ? '71px' : 'unset'} color={PRIMARY_COLOR}>Lote {batchData?.id}</SecondaryButtonComponent>
            </HeaderContainer>
            <StyledInputsContainer>
              <InputWrapper>
                <BorderInputComponent
                  label='Raza'
                  placeholder='Raza'
                  value={batchData?.raza}
                  title={batchData?.raza}
                  onChange={() => { }}
                  width={200}
                  disabled
                />
              </InputWrapper>
              <InputWrapper>
                <BorderInputComponent
                  label='Cantidad'
                  placeholder='Cantidad'
                  value={batchData?.cantidad}
                  onChange={() => { }}
                  width={200}
                  disabled
                />
              </InputWrapper>
              <InputWrapper>
                <BorderInputComponent
                  label='Edad'
                  placeholder='Edad'
                  value={batchData?.edad}
                  onChange={() => { }}
                  width={200}
                  disabled
                />
              </InputWrapper>
              <InputWrapper>
                <BorderInputComponent
                  label='Peso'
                  placeholder='Peso'
                  value={`${batchData?.peso_promedio} kg`}
                  onChange={() => { }}
                  width={200}
                  disabled
                />
              </InputWrapper>
              <InputWrapper>
                <BorderInputComponent
                  label='Est. Repr.'
                  placeholder='Est. Repr.'
                  value={batchData?.estado_reproductivo}
                  title={batchData?.estado_reproductivo}
                  onChange={() => { }}
                  width={200}
                  disabled
                />
              </InputWrapper>
              <InputWrapper>
                <BorderInputComponent
                  label='Preñez'
                  placeholder='Preñez'
                  value={id.toString()}
                  onChange={() => { }}
                  width={200}
                  disabled
                />
              </InputWrapper>
              <InputWrapper>
                <BorderInputComponent
                  label='Parición'
                  placeholder='Parición'
                  value={'???'}
                  onChange={() => { }}
                  width={200}
                  disabled
                />
              </InputWrapper>
              <InputWrapper>
                <BorderInputComponent
                  label='Estado'
                  placeholder='Estado'
                  value={batchData?.estado}
                  title={batchData?.estado}
                  onChange={() => { }}
                  width={200}
                  disabled
                />
              </InputWrapper>
              <InputWrapper>
                <BorderInputComponent
                  label='Clase'
                  placeholder='Clase'
                  value={batchData?.clase}
                  onChange={() => { }}
                  width={200}
                  disabled
                />
              </InputWrapper>
              <InputWrapper>
                <BorderInputComponent
                  label='Mio Mio'
                  placeholder='Mio Mio'
                  value={batchData?.miomio ? 'SI' : 'NO'}
                  onChange={() => { }}
                  width={200}
                  disabled
                />
              </InputWrapper>
              <InputWrapper>
                <BorderInputComponent
                  label='Garrapata'
                  placeholder='Garrapata'
                  value={batchData?.garrapata}
                  onChange={() => { }}
                  width={200}
                  disabled
                />
              </InputWrapper>
              <InputWrapper>
                <BorderInputComponent
                  label='Sanidad'
                  placeholder='Sanidad'
                  value={batchData?.sanidad}
                  onChange={() => { }}
                  width={200}
                  disabled
                />
              </InputWrapper>
              <InputWrapper>
                <BorderInputComponent
                  label='Trat. Nutric.'
                  placeholder='Trat. Nutric.'
                  value={batchData?.tratamiento_nutricional}
                  onChange={() => { }}
                  width={200}
                  disabled
                />
              </InputWrapper>
              <InputWrapper>
                <BorderInputComponent
                  label='Destino'
                  placeholder='Destino'
                  value={batchData?.destino}
                  onChange={() => { }}
                  width={200}
                  disabled
                />
              </InputWrapper>
              <SyledObservaciones>
                <BorderInputComponent
                  label='Observaciones'
                  placeholder='Observaciones'
                  value={batchData?.observaciones}
                  onChange={() => { }}
                  disabled
                  width={isMobile() ? 200 : 520}
                />
              </SyledObservaciones>

              <FooterModal>
                <BorderInputComponent
                  label='Plazo'
                  placeholder='Plazo'
                  value={batchData?.plazo}
                  onChange={() => { }}
                  width={200}
                  disabled
                />
                <a href={`https://wa.me/${batchData?.celular}`} target='_blank'>
                  <PrimaryButtonWithIconComponent icon={FaWhatsapp} text={'Contactanos'} onClick={() => {}} width='160px' />
                </a>
              </FooterModal>
            </StyledInputsContainer>
          </>
        )}
      </ModalContent>
      <ToastContainer />
    </div>
  );
};
