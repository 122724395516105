import styled from "styled-components";
import { BLACK_FONT, PLACEHOLDER_FONT_COLOR, RECTANGULAR_INPUT_BACKGROUND_COLOR, RECTANGULAR_INPUT_BORDER_BOTTOM_COLOR } from "../../../constants/colors.constants";

interface RectangularInputProps {
    label?: string;
    value: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    placeholder?: string;
    width?: number;
}

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const Label = styled.label`
  margin-bottom: 10px;
  text-align: left;
  color: ${BLACK_FONT};
  font-size: 14px;
`;

const InputField = styled.input<{width?: number}>`
  padding: 10px;
  border: none;
  border-bottom: 1px solid ${RECTANGULAR_INPUT_BORDER_BOTTOM_COLOR};
  border-radius: 4px;
  background-color: ${RECTANGULAR_INPUT_BACKGROUND_COLOR};
  width: ${(props) => props.width}px;
  height: 28px;
  outline: none;
  font-size: 16px;
  
  &::placeholder {
    color: ${PLACEHOLDER_FONT_COLOR};
  }
`;

export const RectangularInputComponent = ({
    label,
    value,
    onChange,
    width = 520,
    ...props
}: RectangularInputProps) => {
  return (
    <InputContainer>
      <Label>{label}</Label>
      <InputField 
        width={width}
        value={value}
        onChange={onChange}
        {...props} 
      />
    </InputContainer>
  );
};
