import { ReactNode, MouseEventHandler } from "react";
import styled from "styled-components";
import { PRIMARY_COLOR, WHITE, GRAY_COLOR } from "../../constants/colors.constants"; // Asegúrate de definir DISABLED_COLOR en tus constantes

interface PrimaryButtonProps {
    children: ReactNode;
    onClick?: MouseEventHandler<HTMLButtonElement>;
    borderRadius?: number;
    backgroundColor?: string;
    width?: string;
    height?: string;
    disabled?: boolean;
    fontSize?: string;
    padding?: string;
}

const PrimaryButton = styled.button.attrs<PrimaryButtonProps>((props) => ({
    borderRadius: props.borderRadius,
    backgroundColor: props.disabled ? GRAY_COLOR : props.backgroundColor || PRIMARY_COLOR,
    width: props.width,
    height: props.height,
    disabled: props.disabled,
    fontSize: props.fontSize,
    padding: props.padding
}))<PrimaryButtonProps>`
    padding: ${(props) => (props.padding)};
    color: ${WHITE};
    border: none;
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
    font-weight: 600;
    font-size: ${(props) => (props.fontSize)};
    border-radius: ${(props) => props.borderRadius}px;
    background-color: ${(props) => props.backgroundColor};
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
    width: ${(props) => props.width};
    height: ${(props) => props.height};
    &:hover {
        background-color: ${(props) => !props.disabled && `${PRIMARY_COLOR}cc`}; // Cambia la opacidad del color
    }
    &:disabled {
        cursor: not-allowed;
    }
`;

export const PrimaryButtonComponent = ({
    children,
    onClick,
    borderRadius = 0,
    backgroundColor = PRIMARY_COLOR,
    width = '100%',
    height = '48px',
    disabled = false,
    fontSize = '14px',
    padding = '10px 20px'
}: PrimaryButtonProps) => {
  return (
    <PrimaryButton 
      onClick={onClick}
      borderRadius={borderRadius}
      backgroundColor={backgroundColor}
      width={width}
      height={height}
      disabled={disabled}
      fontSize={fontSize}
      padding={padding}
    >
      {children}
    </PrimaryButton>
  );
};
