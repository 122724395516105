import React, { useState } from "react";
import styled from "styled-components";
import { BLACK_FONT, PLACEHOLDER_FONT_COLOR, RECTANGULAR_INPUT_BACKGROUND_COLOR, RECTANGULAR_INPUT_BORDER_BOTTOM_COLOR } from "../../../constants/colors.constants";
import { FaEye, FaEyeSlash } from "react-icons/fa";

interface RectangularPasswordInputProps {
    label?: string;
    value: string;
    width?: number;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    placeholder?: string;
}

const InputContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const Label = styled.label`
  margin-bottom: 10px;
  text-align: left;
  color: ${BLACK_FONT};
  font-size: 14px;
`;

const InputField = styled.input<{width?: number}>`
  padding: 10px 35px 10px 10px;
  border: none;
  border-bottom: 1px solid ${RECTANGULAR_INPUT_BORDER_BOTTOM_COLOR};
  border-radius: 4px;
  background-color: ${RECTANGULAR_INPUT_BACKGROUND_COLOR};
  height: 28px;
  width: ${(props) => props.width}px;
  outline: none;
  font-size: 16px;
  
  &::placeholder {
    color: ${PLACEHOLDER_FONT_COLOR};
  }
`;

const EyeIcon = styled.span`
  position: absolute;
  right: 10px;
  top: 70%;
  transform: translateY(-50%);
  cursor: pointer;
`;

export const RectangularPasswordInputComponent: React.FC<RectangularPasswordInputProps> = ({
    label,
    value,
    width,
    onChange,
    ...props
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <InputContainer>
      <Label>{label}</Label>
      <InputField 
        type={showPassword ? "text" : "password"}
        value={value}
        onChange={onChange}
        width={width}
        {...props} 
      />
      <EyeIcon onClick={toggleShowPassword}>
        {showPassword ? <FaEyeSlash /> : <FaEye />}
      </EyeIcon>
    </InputContainer>
  );
};
