import styled from "styled-components";
import RightArrowIcon from "../../assets/arrow-right.png";
import LeftArrowIcon from "../../assets/arrow-left.png";
import { SecondaryButtonComponent } from "../../components/buttons/SecondaryButtonComponent";
import { GREEN_COLOR, GRAY_COLOR, WHITE, BLACK_FONT, GOLDEN_COLOR } from "../../constants/colors.constants";
import { isMobile } from "../../utils/utils";
import { useEffect, useState } from "react";
import { API_URL } from "../../constants/config.constants";
import PlaceholderImage from "../../assets/placeholder-live.png"; // Asegúrate de tener una imagen placeholder

const VideoSection = styled.div`
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    @media (max-width: 768px) {
        order: 2;
        padding-bottom: 20px;
        margin-top: 200px;
    }
`

const VideoContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;
    @media (max-width: 768px) {
        flex-direction: row;
    }
`

export const Arrow = styled.img`
    height: 71px;
    width: 73px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;

    &:hover {
        transform: scale(1.1);
    }

    &:active {
        transform: scale(0.7);
    }

    @media (max-width: 768px) {
        height: 40px;
        width: 40px;
    }
`;

const VideoCard = styled.div`
    height: 425px;
    width: 765px;
    border: 7px solid #01354A;
    border-radius: 39px;
    overflow: hidden;
    @media (max-width: 1818px) {
        height: 340px;
        width: 612px;
    }
    @media (max-width: 768px) {
        height: 240px;
        width: 90%;
    }
`;

const YouTubeIframe = styled.iframe`
    width: 100%;
    height: 100%;
    border: none;
`;

const Placeholder = styled.img`
    width: 100%;
    height: 100%;
    background-color: black; // Esto se asegura de que el fondo sea negro en caso de que la imagen no se cargue
`;

const ButtonContainer = styled.div`
    display: flex;
    margin-top: 7%;
    gap: 20px;
    align-items: center;
    @media (max-width: 768px) {
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 90%;
    }
`

export const VideoComponent = () => {
    const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
    const [pastStreamings, setPastStreamings] = useState<any>([]);
    const [videoUrl, setVideoUrl] = useState('');
    const [isLive, setIsLive] = useState(false);
    const [isValidUrl, setIsValidUrl] = useState(true);

    const handleDecreaseIndex = () => {
        setCurrentVideoIndex((prevIndex) => (prevIndex === 0 ? pastStreamings.length - 1 : prevIndex - 1));
    }

    const handleIncreaseIndex = () => {
        setCurrentVideoIndex((prevIndex) => (prevIndex === pastStreamings.length - 1 ? 0 : prevIndex + 1));
    }

    useEffect(() => {
        const fetchLiveStream = async () => {
            try {
                const response = await fetch(`${API_URL}/live-video/?limit=1`);
                const data = await response.json();
                const { results } = data;
                if (!results[0]?.videoId) {
                    setIsLive(false);
                    const response = await fetch(`${API_URL}/remates/?limit=5`);
                    const data = await response.json();
                    const streamings = data.results.map((auction: any) => ({
                        auctionId: auction.id,
                        videoUrl: auction.url_streaming
                    }));
                    setPastStreamings(streamings);
                    if (streamings.length > 0) {
                        setVideoUrl(getEmbedUrl(streamings[0].videoUrl));
                        setIsValidUrl(true)
                    }
                } else {
                    setIsLive(true);
                    setIsValidUrl(true)
                    setVideoUrl(`https://www.youtube.com/embed/${results[0].videoId}`);
                }
            } catch (error) {
                console.error('Fetching live stream failed', error);
                setIsValidUrl(false);
            }
        };
        fetchLiveStream();
    }, []);

    useEffect(() => {
        if (pastStreamings.length > 0) {
            const videoUrl = pastStreamings[currentVideoIndex].videoUrl;
            setIsValidUrl(true)
            setVideoUrl(getEmbedUrl(videoUrl));
        }
    }, [currentVideoIndex, pastStreamings]);

    const getEmbedUrl = (url: string) => {
        try {
            const urlObj = new URL(url);
            const videoId = urlObj.searchParams.get('v');
            if (videoId) {
                return `https://www.youtube.com/embed/${videoId}`;
            } else if (url.includes('youtu.be')) {
                const videoId = url.split('/').pop();
                return `https://www.youtube.com/embed/${videoId}`;
            }
            return url;
        } catch (error) {
            console.error('Invalid URL', error);
            setIsValidUrl(false);
            return '';
        }
    };

    return (
        <VideoSection>
            <VideoContainer>
                <Arrow src={LeftArrowIcon} onClick={handleDecreaseIndex}/>
                <VideoCard>
                    {isValidUrl ? (
                        <YouTubeIframe
                            src={videoUrl}
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        />
                    ) : (
                        <Placeholder src={PlaceholderImage} alt="Placeholder" />
                    )}
                </VideoCard>
                <Arrow src={RightArrowIcon} onClick={handleIncreaseIndex}/>
            </VideoContainer>
            <ButtonContainer>
                <SecondaryButtonComponent
                    onClick={() => {}}
                    backgroundColor={isLive ? GREEN_COLOR : GRAY_COLOR}
                    color={isLive ? WHITE : BLACK_FONT}
                    gradient={false}
                    bold={600}
                    border={false}
                    borderRadius={25}
                    width={isMobile() ? '120px': 'auto'}
                    height={isLive ? '50px' : '37px'}
                >
                    En vivo
                </SecondaryButtonComponent>
                <SecondaryButtonComponent
                    onClick={() => {}}
                    backgroundColor={GOLDEN_COLOR}
                    color={WHITE}
                    gradient={false}
                    bold={600}
                    border={false}
                    borderRadius={25}
                    width={isMobile() ? '120px': 'auto'}
                    height={!isLive ? '50px' : '37px'}
                >
                    Ya transmitido
                </SecondaryButtonComponent>
            </ButtonContainer>
        </VideoSection>
    )
}
