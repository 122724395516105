import styled from "styled-components";
import CowsBackgroundImage from '../assets/login-background.png';
import { HomeCardComponent } from "../components/cards/HomeCardComponent";
import { useNavigate } from "react-router-dom";
import SimpleNavbar from "../components/shared/SimpleNavBar";
import { MobileNavBar } from "../components/shared/MobileNavBar";
import { isMobile } from "../utils/utils";
import { ResponsiveHomePage } from "./ResponsiveHomePage";

const BackgroundImage = styled.div`
    position: relative;
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url(${CowsBackgroundImage});
        background-size: cover;
        background-position: center;
        z-index: -1;
    }
`;

const CardsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;

const SecondCardsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;

export const HomePage = () => {
  const navigate = useNavigate();

  const handleNavigate = (route: any) => {
    return () => {
      navigate(route.toString())
    };
  }

  return (
    <div style={{ height: '100vh' }}>
      {
        isMobile() ? <ResponsiveHomePage/> : 
        <>
         {isMobile() ? <MobileNavBar/> : <SimpleNavbar showUserMenu={true} />}
          <BackgroundImage>
            <CardsContainer>
              <HomeCardComponent onClick={handleNavigate('/next-auctions')}  title={"Próximos Remates"} description={"Visualiza los remates próximos a realizarse."} buttonText={"Próximos Remates"}  />
              <HomeCardComponent onClick={handleNavigate('/finished-auctions')} title={"Remates Realizados"} description={"Accede a la información detallada de los remates ya transmitidos para revisar los resultados."} buttonText={"Remates Realizados"} />
            </CardsContainer>
            <SecondCardsContainer>
                <HomeCardComponent title={"Panel De Control"} description={"Carga los lotes disponibles y lleva un control preciso de la actividad en curso para una gestión eficiente."} buttonText={"Panel de Control"} onClick={handleNavigate('/control-panel')}/>
                <HomeCardComponent title={"Cargar Remate"} description={"Arma un nuevo remate con facilidad, especificando los detalles para su realización y planificación."} buttonText={"Cargar Remate"} onClick={handleNavigate('/auctions/upload')}/>
            </SecondCardsContainer>
          </BackgroundImage>
        </>
      }
    </div>
  )
}
