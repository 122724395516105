import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import './App.css';
import { HomePage } from './pages/HomePage';
import { PlaygroundPage } from './pages/PlaygroundPage';
import { LoginPage } from './pages/Auth/LoginPage';
import React from 'react'
import LandingPage from './pages/Landing/LandingPage';
import { UploadAuctionPage } from './pages/Auctions/UploadAuctionPage';
import { Provider } from 'react-redux';
import {store} from '../src/app/Store';
import ProtectedRoute from './components/protectedRoute/ProtectedRoute';
import { UploadBatchPage } from './pages/Batchs/UploadBatchPage';
import ControlPanelPage from './pages/ControlPanel/ControlPanelPage';
import { NextAuctionsPage } from './pages/Auctions/NextAuctionsPage';
import BatchListPage from './pages/Batchs/BatchListPage';
import { EditAuctionPage } from './pages/Auctions/EditAuctionPage';
import { FinishedAuctionsPage } from './pages/Auctions/FinishedAuctionsPage';
import { OrderBatchesPage } from './pages/Batchs/OrderBatchesPage';
import { ChangePassword } from './pages/Auth/ChangePassword';
import { ForgetPassword } from './pages/Auth/ForgetPassword';

function App() {
  return (
    <React.StrictMode>
    <Provider store={store}>
    <div className="App">
      <Router>
        <Routes>
          <Route path='/' element={<LandingPage/>} />

          {/* Auth pages */}
          <Route path='/auth/login' element={<LoginPage/>} />
          <Route path='/auth/forget-password' element={<ForgetPassword/>} />
          <Route path='/auth/change-password' element={<ChangePassword/>} />

          <Route path='/playground' element={<PlaygroundPage/>} />
          
          <Route path='/next-auctions' element={<NextAuctionsPage showButtons={true} />} />
          <Route path='/finished-auctions' element={<FinishedAuctionsPage/>} />
          <Route path='/batchs/:id' element={<BatchListPage/>} />

          <Route element={<ProtectedRoute />}>
            <Route path='/home' element={<HomePage/>} />
            <Route path='/auctions/upload' element={<UploadAuctionPage/>} />
            <Route path='/auctions/edit/:id' element={<EditAuctionPage/>} />
            <Route path='/batches/upload/:auctionId' element={<UploadBatchPage/>} />
            <Route path='/control-panel' element={<ControlPanelPage/>} />
            <Route path='/batches/order/:id' element={<OrderBatchesPage/>} />

          </Route>
          
          {/* User pages */}
          


          {/* Redirect case if the user search a wrong path */}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Router>
    </div>
    </Provider>
    </React.StrictMode>
  );
}

export default App;